import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Button,
    Spinner,
    Alert,
    Badge,
} from "react-bootstrap";
import axios from "axios";
import { useUser } from "../../Context/UserContext";
import { Toaster, toast } from "react-hot-toast";
import { Helmet } from "react-helmet";
import UyeMenu from "../../Component/UyeMenu";

const Uyekuponlar = () => {
    const { user } = useUser();
    const [genelKuponlar, setGenelKuponlar] = useState([]);
    const [ozelKuponlar, setOzelKuponlar] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchKuponlar();
        // eslint-disable-next-line
    }, []);

    const fetchKuponlar = async () => {
        if (!user || !user.id) return;

        try {
            const response = await axios.post(
                "https://panel.pahaliyaalma.com/api/uye/kuponlar",
                { uye_id: user.id }
            );
            setGenelKuponlar(response.data.genel_kuponlar);
            setOzelKuponlar(response.data.ozel_kuponlar);
        } catch (err) {
            setError("Kuponlar yüklenirken bir hata oluştu.");
        } finally {
            setLoading(false);
        }
    };

    const handleKopyala = (kuponKodu) => {
        navigator.clipboard.writeText(kuponKodu);
        toast.success("Kupon kodu kopyalandı!");
    };

    /**
     * Kupon kartını düzenli göstermeyi sağlayan yardımcı fonksiyon.
     * @param {object} kupon Kupon verisi
     * @param {string} variant Bootstrap rengi (ör: 'primary', 'warning')
     * @param {boolean} isOzel "Sana Özel" mi?
     */
    const renderKuponCard = (kupon, variant = "primary", isOzel = false) => {
        const indirimText = kupon.indirim_orani
            ? `%${(kupon.indirim_orani * 100).toFixed(0)} İndirim`
            : `${kupon.indirim_miktar}₺ İndirim`;

        return (
            <div className="position-relative mb-4" key={kupon.id}>
                {/* Eğer özel kupon ise sağ üst köşede "Sana Özel" Badge'i */}
                {isOzel && (
                    <Badge
                        bg="warning"
                        text="dark"
                        style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            fontSize: "0.9rem",
                        }}
                    >
                        Sana Özel
                    </Badge>
                )}
                <Card className={`shadow-sm text-center border`}>
                    <Card.Body>
                        <h5 className="mb-3 fw-bold">{kupon.icerik}</h5>
                        {/* İndirim Miktarı/Oranı */}
                        <Badge bg={variant} className="mb-2" style={{ fontSize: "1rem" }}>
                            {indirimText}
                        </Badge>

                        {/* Min. sepet tutarı varsa göster */}
                        {kupon.min_sepet_tutari > 0 && (
                            <p className="text-muted m-0">
                                <strong>Min. Sepet Tutarı:</strong> {kupon.min_sepet_tutari}₺
                            </p>
                        )}

                        {/* Kategori bilgisi varsa göster */}
                        {kupon.kategori && (
                            <p className="text-muted m-0">
                                <strong>Kategori:</strong> {kupon.kategori.baslik}
                            </p>
                        )}

                        {/* Marka bilgisi varsa göster */}
                        {kupon.marka && (
                            <p className="text-muted m-0">
                                <strong>Marka:</strong> {kupon.marka.baslik}
                            </p>
                        )}

                        <p className="text-muted mt-2 mb-1">
                            Son Kullanım:{" "}
                            {new Date(kupon.bitis_tarihi).toLocaleDateString("tr-TR")}
                        </p>

                        {/* Kupon Kodu */}
                        <div className="d-flex justify-content-center align-items-center">
              <span
                  className="fw-bold px-3 py-2 border rounded"
                  style={{ background: "#f8f9fa", cursor: "pointer" }}
                  onClick={() => handleKopyala(kupon.kupon_sifre)}
              >
                {kupon.kupon_sifre}
              </span>
                        </div>

                        {/* Kopyala Butonu */}
                        <Button
                            variant={variant}
                            className="mt-3"
                            onClick={() => handleKopyala(kupon.kupon_sifre)}
                        >
                            Kopyala
                        </Button>
                    </Card.Body>
                </Card>
            </div>
        );
    };

    return (
        <Container className="mt-5">
            <Helmet>
                <title>Hediye Kuponları</title>
            </Helmet>
            <Row>
                {/* SOL MENÜ */}
                <Col md={3} className="mb-4">
                    <UyeMenu />
                </Col>

                {/* SAĞ ANA İÇERİK */}
                <Col md={9}>
                    <div style={{borderRadius:'5px'}} className="border p-3 mb-4">
                        <h2 style={{color:'#FA8C17'}} className="mb-1">İndirim Kuponlarım</h2>
                        <p className="mb-1">Kullanmak istediğiniz indirim kuponunu sepet sayfasında seçebilirsiniz.
                        </p>
                    </div>

                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border" />
                            <p>Yükleniyor...</p>
                        </div>
                    ) : error ? (
                        <Alert variant="danger" className="text-center">
                            {error}
                        </Alert>
                    ) : (
                        <Container>

                            {/* Tek satır, iki sütun: Solda GENEL, Sağda ÖZEL */}
                            <Row style={{borderRadius:'5px'}} className="border p-3 mb-4">
                                <h4 className="mb-2">İndirim Kuponlarınız</h4>

                                <Col md={6}>
                                    {genelKuponlar.length > 0 ? (
                                        genelKuponlar.map((kupon) =>
                                            renderKuponCard(kupon, "primary", false)
                                        )
                                    ) : (
                                        <Alert variant="info">Genel kupon bulunamadı.</Alert>
                                    )}
                                </Col>

                                <Col md={6}>
                                    {ozelKuponlar.length > 0 ? (
                                        ozelKuponlar.map((kupon) =>
                                            renderKuponCard(kupon, "warning", true)
                                        )
                                    ) : (
                                        <Alert variant="info">Sana özel kupon bulunamadı.</Alert>
                                    )}
                                </Col>
                            </Row>

                            {/* Hiç Kupon Yoksa: Hem Genel Hem Özel Yok */}
                            {genelKuponlar.length === 0 && ozelKuponlar.length === 0 && (
                                <Alert variant="info" className="text-center mt-4">
                                    Henüz kuponunuz bulunmamaktadır.
                                </Alert>
                            )}
                        </Container>
                    )}
                </Col>
            </Row>

            {/* Toast bildirimi */}
            <Toaster position="top-right" reverseOrder={false} />
        </Container>
    );
};

export default Uyekuponlar;
