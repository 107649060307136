import React from "react";
import DeliveryIcon from "../assets/icons/box-1-svgrepo-com.svg";
import PaymentIcon from "../assets/icons/security-verified-svgrepo-com.svg";
import GuaranteeIcon from "../assets/icons/badge-svgrepo-com.svg";
import WarrantyIcon from "../assets/icons/like-shapes-svgrepo-com.svg";

const InfoSection = () => {
    // Bilgilendirme içerikleri
    const infoItems = [
        {
            icon: DeliveryIcon, // SVG ikon dosyasını içe aktardık
            title: "Hızlı Teslimat",
            description: "Türkiye'nin her yerine hızlı teslimat seçeneği",
        },
        {
            icon: PaymentIcon,
            title: "Güvenli Ödeme Sistemi",
            description: "Kredi kartınız ile güvenle alışveriş yapın!",
        },
        {
            icon: GuaranteeIcon,
            title: "Orijinal Ürün Garantisi",
            description: "Tüm ürünlerimiz faturalı gönderilmektedir.",
        },
        {
            icon: WarrantyIcon,
            title: "Garanti ve Teslimat",
            description: "Ürünlerimiz teslimat garantisi kapsamındadır.",
        },
    ];

    return (
        <div className="container mt-5">
            <div className="row">
                {infoItems.map((item, index) => (
                    <div
                        className="col-md-3 col-12 mb-3 p-0 m-0"
                        key={index}
                    >
                        <div style={{
                            border: "1px solid #ddd",
                            borderRadius: "2px",
                            display: "flex",
                            alignItems: "center",
                            padding: "10px",
                            margin: '4px 4px',
                            backgroundColor: "#fff"
                        }}>
                            <img
                                src={item.icon}
                                alt={item.title}
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    marginRight: "10px",
                                }}
                            />
                            {/* Başlık ve Açıklama */}
                            <div>
                                <h6 style={{
                                    fontWeight: "bold",
                                    margin: "0 0 5px 0",
                                    fontSize: "14px"
                                }}>
                                    {item.title}
                                </h6>
                                <p style={{
                                    fontSize: "13px",
                                    color: "#0c0c0c",
                                    margin: 0
                                }}>
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default InfoSection;
