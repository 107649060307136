import React, { useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Form,
    Button,
    Alert
} from "react-bootstrap";
import { useUser } from "../../Context/UserContext";
import UyeMenu from "../../Component/UyeMenu";
import { Helmet } from "react-helmet";
import axios from "axios";

const UyeEdit = () => {
    const { user, login } = useUser();

    // Bilgi formu state
    const [infoData, setInfoData] = useState({
        user_id: user?.id,
        name: user?.name || "",
        telefon: user?.telefon || "",
        membershipType: user?.membership_type || "bireysel", // "bireysel" / "tuzel"
        vergiDairesi: user?.vergi_dairesi || "",
        vergiNumarasi: user?.vergi_numarasi || "",
        firmaAdi: user?.firma_adi || "",
    });

    // Şifre formu state
    const [passwordData, setPasswordData] = useState({
        password: "",
        confirmPassword: "",
    });

    // Mesajlar
    const [infoSuccessMsg, setInfoSuccessMsg] = useState("");
    const [infoErrorMsg, setInfoErrorMsg] = useState("");
    const [passSuccessMsg, setPassSuccessMsg] = useState("");
    const [passErrorMsg, setPassErrorMsg] = useState("");

    // Bilgiler değişince
    const handleInfoChange = (e) => {
        const { name, value } = e.target;
        setInfoData({ ...infoData, [name]: value });
    };

    // Bilgiler kaydet
    const handleInfoSubmit = async (e) => {
        e.preventDefault();
        try {
            // DİKKAT: membership_type olarak gönderiyoruz (bayimi değil)
            const response = await axios.post("https://panel.pahaliyaalma.com/api/uye/update", {
                user_id:infoData.user_id,
                name: infoData.name,
                telefon: infoData.telefon,
                membership_type: infoData.membershipType, // Önemli!
                vergi_dairesi: infoData.vergiDairesi,
                vergi_numarasi: infoData.vergiNumarasi,
                firma_adi: infoData.firmaAdi,
            });

            if (response.data.status === 200) {
                setInfoSuccessMsg("Bilgiler başarıyla güncellendi.");
                setInfoErrorMsg("");

                // Context güncellemek isterseniz
                const updatedUser = {
                    ...user,
                    name: infoData.name,
                    telefon: infoData.telefon,
                    membership_type: infoData.membershipType,
                    vergi_dairesi: infoData.vergiDairesi,
                    vergi_numarasi: infoData.vergiNumarasi,
                    firma_adi: infoData.firmaAdi,
                };
                login(updatedUser);

            } else {
                // 200 dışında ama status:200 değilse
                setInfoErrorMsg("Bilinmeyen bir hata oluştu!");
                setInfoSuccessMsg("");
            }
        } catch (error) {
            console.error(error);

            // Validasyon hatası (422)
            if (error.response && error.response.status === 422) {
                const errs = error.response.data.errors;
                // errs, örn: {"membership_type":["Üyelik türü geçersiz"], "name":["Ad alanı zorunludur"]}

                // Birden fazla hata olabilir; basitçe ilkini göstermek isterseniz:
                const firstField = Object.keys(errs)[0];
                setInfoErrorMsg(errs[firstField][0]);
            } else {
                // Başka bir hata (500 vs.)
                setInfoErrorMsg("Bilgiler güncellenirken hata oluştu!");
            }
            setInfoSuccessMsg("");
        }
    };

    // Şifre değişince
    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswordData({ ...passwordData, [name]: value });
    };

    // Şifre Kaydet
    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        // eşleşme kontrolü
        if (passwordData.password !== passwordData.confirmPassword) {
            setPassErrorMsg("Şifreler eşleşmiyor!");
            setPassSuccessMsg("");
            return;
        }

        try {
            const response = await axios.post(
                "https://panel.pahaliyaalma.com/api/uye/updatePassword",
                {
                    user_id: user?.id,
                    new_password: passwordData.password,
                }
            );
            if (response.data.status === 200) {
                setPassSuccessMsg("Şifre başarıyla güncellendi.");
                setPassErrorMsg("");
            } else {
                setPassErrorMsg("Şifre güncellemesinde hata oluştu!");
                setPassSuccessMsg("");
            }
        } catch (error) {
            console.error(error);
            setPassErrorMsg("Şifre güncellenirken hata oluştu.");
            setPassSuccessMsg("");
        }
    };

    return (
        <Container className="mt-5">
            <Helmet>
                <title>Üye Bilgilerim</title>
            </Helmet>

            <Row>
                {/* Sol Menü */}
                <Col md={3}>
                    <UyeMenu />
                </Col>

                {/* Orta Panel - Kişisel/Tüzel Bilgiler */}
                <Col md={5}>
                    <Card className="shadow-sm">
                        <Card.Body>
                            <h5 className="mb-4 text-center">Üye Bilgileriniz</h5>



                            <Form onSubmit={handleInfoSubmit}>
                                {/* Ad Soyad */}
                                <Form.Group controlId="name" className="mb-3">
                                    <Form.Label>Ad Soyad</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={infoData.name}
                                        onChange={handleInfoChange}
                                    />
                                </Form.Group>

                                {/* E-posta (değiştirilemez) */}
                                <Form.Group controlId="email" className="mb-3">
                                    <Form.Label>E-Posta</Form.Label>
                                    <Form.Control
                                        type="email"
                                        disabled
                                        value={user?.email || ""}
                                    />
                                </Form.Group>

                                {/* Telefon */}
                                <Form.Group controlId="telefon" className="mb-3">
                                    <Form.Label>Telefon</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="telefon"
                                        value={infoData.telefon}
                                        onChange={handleInfoChange}
                                    />
                                </Form.Group>

                                {/* Üyelik Türü */}
                                <Form.Group controlId="membershipType" className="mb-3">
                                    <Form.Label>Üyelik Türü</Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        value={infoData.membershipType === "tuzel" ? "Tüzel" : "Bireysel"}
                                    />
                                </Form.Group>

                                {/* Tüzel ise ek alanlar */}
                                {infoData.membershipType === "tuzel" && (
                                    <>
                                        <Form.Group controlId="firmaAdi" className="mb-3">
                                            <Form.Label>Firma Adı</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="firmaAdi"
                                                value={infoData.firmaAdi}
                                                onChange={handleInfoChange}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="vergiDairesi" className="mb-3">
                                            <Form.Label>Vergi Dairesi</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="vergiDairesi"
                                                value={infoData.vergiDairesi}
                                                onChange={handleInfoChange}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="vergiNumarasi" className="mb-3">
                                            <Form.Label>Vergi Numarası</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="vergiNumarasi"
                                                value={infoData.vergiNumarasi}
                                                onChange={handleInfoChange}
                                            />
                                        </Form.Group>
                                    </>
                                )}

                                {infoSuccessMsg && (
                                    <Alert
                                        variant="success"
                                        dismissible
                                        onClose={() => setInfoSuccessMsg("")}
                                    >
                                        {infoSuccessMsg}
                                    </Alert>
                                )}
                                {infoErrorMsg && (
                                    <Alert
                                        variant="danger"
                                        dismissible
                                        onClose={() => setInfoErrorMsg("")}
                                    >
                                        {infoErrorMsg}
                                    </Alert>
                                )}  

                                <Button variant="primary" type="submit" className="w-100">
                                    Bilgileri Güncelle
                                </Button>

                            </Form>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Sağ Panel - Şifre Güncelleme */}
                <Col md={4}>
                    <Card className="shadow-sm">
                        <Card.Body>
                            <h5 className="mb-4 text-center">Şifre Güncelleme</h5>

                            {passSuccessMsg && (
                                <Alert
                                    variant="success"
                                    dismissible
                                    onClose={() => setPassSuccessMsg("")}
                                >
                                    {passSuccessMsg}
                                </Alert>
                            )}
                            {passErrorMsg && (
                                <Alert
                                    variant="danger"
                                    dismissible
                                    onClose={() => setPassErrorMsg("")}
                                >
                                    {passErrorMsg}
                                </Alert>
                            )}

                            <Form onSubmit={handlePasswordSubmit}>
                                {/* Yeni Şifre */}
                                <Form.Group controlId="password" className="mb-3">
                                    <Form.Label>Yeni Şifre</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Yeni Şifre"
                                        name="password"
                                        value={passwordData.password}
                                        onChange={handlePasswordChange}
                                    />
                                </Form.Group>

                                {/* Şifre Tekrar */}
                                <Form.Group controlId="confirmPassword" className="mb-3">
                                    <Form.Label>Şifre Tekrar</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Şifre Tekrar"
                                        name="confirmPassword"
                                        value={passwordData.confirmPassword}
                                        onChange={handlePasswordChange}
                                    />
                                </Form.Group>

                                <Button variant="primary" type="submit" className="w-100">
                                    Şifreyi Güncelle
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default UyeEdit;
