import React, { useState } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const MobilAppNavbar = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (!searchQuery.trim()) return;
        navigate(`/arama?query=${encodeURIComponent(searchQuery)}`);
    };

    return (
        <div style={{
            backgroundColor: "#FA8C17",
            borderBottom: "1px solid #cccccc",
            padding: "8px 0",

        }}>
            <Container>
                <Row>
                    <Col xs={12}>
                        <Form onSubmit={handleSearchSubmit} className="d-flex">
                            <Form.Control
                                type="text"
                                placeholder="Marka, kategori, ürün arayın"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{
                                    borderRadius: "5px 0 0 5px",
                                    border: "1px solid #ccc",
                                    padding: "10px",
                                    fontSize: "14px",
                                }}
                            />
                            <Button
                                type="submit"
                                style={{
                                    borderRadius: "0 1px 5px 0",
                                    backgroundColor: "#ffffff",
                                    border: "none",
                                    padding: "0 15px",
                                    color:'black'
                                }}
                            >
                                <FaSearch />
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default MobilAppNavbar;
