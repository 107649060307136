import React from "react";
import { Container, Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const OdemeHata = () => {
    return (
        <Container className="mt-5 text-center">
            <Alert variant="danger">
                <h2>❌ Ödeme Başarısız</h2>
                <p>Bir sorun oluştu. Lütfen tekrar deneyin veya farklı bir ödeme yöntemi seçin.</p>
                <Link to="/sepet">
                    <Button variant="danger">Sepete Geri Dön</Button>
                </Link>
            </Alert>
        </Container>
    );
};

export default OdemeHata;
