import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Button, Card, ListGroup, Form, Alert } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom"; // 1) useSearchParams eklendi
import { Helmet } from "react-helmet-async";
import { FaHeart } from "react-icons/fa";
import useToasterMessage from "../../hooks/useToasterMessage";
import { useUser } from "../../Context/UserContext";
import "../../style/urundetay.css";

// Sayfa açıldığında en üste kaydırmak için
import ScrollToTopOnMount from "../../Component/ScrollToTopOnMount";

// Lightbox (Yet Another React Lightbox) + Eklentiler
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import { FaStar } from "react-icons/fa";

import toast, { Toaster } from "react-hot-toast";

// React Slick import
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// Özel ok bileşenleri
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block",
                width: "40px",
                height: "40px",
                right: "-15px",
                zIndex: 2,
            }}
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block",
                width: "40px",
                height: "40px",
                left: "-15px",
                zIndex: 2,
            }}
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
        />
    );
}

const UrunDetay = ({ onAddToCart }) => {
    const { id } = useParams();
    const { user } = useUser();
    useToasterMessage();
    const navigate = useNavigate();

    // 2) URL'deki arama parametresinden "yorum" var mı diye bakıyoruz
    const [searchParams] = useSearchParams();
    const yorumModu = searchParams.get("yorum") === "1";
    const [commentText, setCommentText] = useState("");
    const [rating, setRating] = useState(5);
    // Mevcut isDraggingRef (sürükleniyor mu)
    const isDraggingRef = useRef(false);

    // [EKLENDİ] Başlangıç X-Y koordinatlarını tutacak ref'ler
    const startXRef = useRef(0);
    const startYRef = useRef(0);
    const dragThreshold = 10; // 10px üstünde hareket olursa sürükleme kabul

    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [quantity, setQuantity] = useState(1);

    // Lightbox
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const [userAlreadyCommented, setUserAlreadyCommented] = useState(false);
    const [comments, setComments] = useState([]);

    // Ana resim
    const [activeImage, setActiveImage] = useState("");

    // Kurumsal sipariş formu
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        company: "",
        city: "",
        quantity: 1,
        message: "",
        product_id: id,
        tipi: "Kurumsal Sipariş",
    });

    const iller = [
        "Adana",
        "Adıyaman",
        "Afyonkarahisar",
        // ...
        "Düzce",
    ];
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    // Benzer ürünler
    const [similarProducts, setSimilarProducts] = useState([]);

    // 3) Yorum state'i

    // Ürün verisi
    useEffect(() => {
        axios
            .get(`https://panel.pahaliyaalma.com/api/data/urun/${id}`)
            .then((response) => {
                const urun = response.data.data;
                setProduct(urun);
                setActiveImage(`https://panel.pahaliyaalma.com/${urun.resimyol}`);
                setLoading(false);
            })
            .catch((error) => {
                console.error("API Error:", error);
                setLoading(false);
            });
    }, [id]);

    // Sayfa yenileme efekti (reloadPage)
    useEffect(() => {
        reloadPage();
    }, [id]);

    const reloadPage = () => {
        setLoading(true);
        axios
            .get(`https://panel.pahaliyaalma.com/api/data/urun/${id}`)
            .then((response) => {
                setProduct(response.data.data);
                setActiveImage(`https://panel.pahaliyaalma.com/${response.data.data.resimyol}`);
            })
            .catch((error) => console.error("API Error:", error))
            .finally(() => setLoading(false));
    };

    // Benzer ürünler
    useEffect(() => {
        axios
            .get(`https://panel.pahaliyaalma.com/api/data/benzer-urunler/${id}`)
            .then((res) => {
                setSimilarProducts(res.data.data || []);
            })
            .catch((err) => {
                console.error("Benzer Ürünler API Hatası:", err);
            });
    }, [id]);

    // Loading
    if (loading) {
        return <p>Ürün bilgisi yükleniyor...</p>;
    }
    if (!product) {
        return <p>Ürün bilgisi bulunamadı.</p>;
    }

    // Thumbnail
    const thumbnailImages = [
        product.resimyol,
        ...(product.urundetay_resimler || []).map((img) => img.resimyol),
    ];

    // Lightbox slides
    const slides = thumbnailImages.map((imgPath) => ({
        src: `https://panel.pahaliyaalma.com/${imgPath}`,
    }));

    // Lightbox view
    const handleView = (currentIndex) => {
        if (currentIndex !== photoIndex) {
            setPhotoIndex(currentIndex);
        }
    };

    // İndirim
    const discountPercentage =
        product.eski_fiyat && product.fiyat
            ? Math.round(((product.eski_fiyat - product.fiyat) / product.eski_fiyat) * 100)
            : null;

    // Adet arttır/azalt
    const handleQuantityChange = (type) => {
        setQuantity((prev) => {
            if (type === "increase") return prev + 1;
            if (type === "decrease" && prev > 1) return prev - 1;
            return prev;
        });
    };

    // Favorilere ekle
    const handleAddToFavorites = async () => {
        try {
            const uye_id = user?.id;
            if (!uye_id) {
                toast.error("Favorilere eklemek için giriş yapmalısınız!");
                return;
            }

            const response = await axios.post(
                "https://panel.pahaliyaalma.com/api/uye/favoriekle",
                {
                    urun_id: product.id,
                    uye_id: uye_id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            toast.success(response.data.message || "Ürün favorilere eklendi!");
        } catch (error) {
            if (error.response && error.response.status === 409) {
                toast.error("Bu ürün zaten favorilere eklenmiş!");
            } else {
                toast.error("Favorilere eklenirken bir hata oluştu!");
            }
        }
    };

    // Kurumsal Sipariş Formu input değişimi
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Kurumsal Sipariş Formu submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage("");
        setErrorMessage("");
        setLoading(true);

        try {
            const response = await axios.post("https://panel.pahaliyaalma.com/api/message/contact", formData);

            if (response.data.success) {
                setSuccessMessage("Mesajınız başarıyla gönderildi.");
                setFormData({
                    name: "",
                    email: "",
                    phone: "",
                    company: "",
                    city: "",
                    quantity: 1,
                    message: "",
                    product_id: id,
                    tipi: "Kurumsal Sipariş",
                });
            } else {
                setErrorMessage(response.data.message || "Mesaj gönderilirken bir hata oluştu.");
            }
        } catch (error) {
            console.error("Hata:", error);
            if (error.response) {
                const { data } = error.response;
                if (data.errors) {
                    const errorMessages = Object.values(data.errors)
                        .flat()
                        .join(" ");
                    setErrorMessage(errorMessages);
                } else {
                    setErrorMessage(data.message || "Bilinmeyen bir hata oluştu.");
                }
            } else {
                setErrorMessage("Bağlantı hatası: Lütfen tekrar deneyin.");
            }
        } finally {
            setLoading(false);
        }
    };

    // 4) Önceki yorumları listeleyen bileşen
    const PreviousComments = ({ urunId }) => {
        const [comments, setComments] = useState([]);

        useEffect(() => {
            axios
                .get(`https://panel.pahaliyaalma.com/api/data/urunyorumlar/${id}`)
                .then((res) => {
                    const allComments = res.data || [];
                    setComments(allComments);

                    // Kullanıcı giriş yaptıysa ve daha önce yorum yaptıysa kontrol et
                    if (user) {
                        const found = allComments.find(
                            (yorum) => yorum.uye_id === user.id
                        );
                        setUserAlreadyCommented(!!found);
                    }
                })
                .catch(() => {});
        }, [id, user]);

        if (comments.length === 0) return <p className="text-muted">Henüz yorum yapılmamış.</p>;

        return (
            <ListGroup style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {comments.map((comment, idx) => (
                    <ListGroup.Item
                        key={idx}
                        style={{ padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}
                    >
                        <strong>{comment.uye_adi}</strong>{" "}
                        <span className="text-muted">| {comment.tarih}</span>
                        <p className="mb-0">{comment.yorum}</p>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        );
    };

    // 5) Yorum Gönderme Fonksiyonu
    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        if (!user) {
            toast.error("Yorum yapmak için giriş yapmalısınız.");
            return;
        }

        try {
            await axios.post(
                "https://panel.pahaliyaalma.com/api/urun/yorumekle",
                {
                    urun_id: product.id,
                    uye_id: user.id,
                    yorum: commentText,
                    puan: rating,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            toast.success("Yorum eklendi!");
            setCommentText("");
            setRating(5);
            reloadPage(); // Yorumları yenile
        } catch (error) {
            toast.error("Yorum eklenemedi.");
        }
    };

    return (
        <Container style={{ marginTop: "40px" }}>
            <ScrollToTopOnMount />
            <Helmet>
                <title>{product.baslik}</title>
            </Helmet>

            {/* Lightbox Bileşeni */}
            <Lightbox
                open={lightboxOpen}
                close={() => setLightboxOpen(false)}
                slides={slides}
                on={{
                    view: handleView,
                }}
                plugins={[Zoom, Slideshow]}
                zoom={{
                    maxZoomPixelRatio: 2,
                    zoomInMultiplier: 1.2,
                    mouseDownMove: true,
                }}
                slideshow={{
                    autoplay: false,
                    delay: 3000,
                }}
            />

            <Row>
                {/* Sol Kısım: Ürün Görselleri ve Bilgileri */}
                <Col md={9}>
                    <Row className="align-items-stretch">
                        <Col md={5} className="d-flex flex-column" style={{ background: "#fff" }}>
                            <div className="d-flex">
                                {/* Thumbnail küçük resimler */}
                                <div className="d-flex flex-column me-2">
                                    {thumbnailImages.map((thumbSrc, index) => (
                                        <LazyLoadImage
                                            key={index}
                                            src={`https://panel.pahaliyaalma.com/${thumbSrc}`}
                                            alt={product.baslik}
                                            effect="blur"
                                            onClick={() => {
                                                setActiveImage(`https://panel.pahaliyaalma.com/${thumbSrc}`);
                                                setPhotoIndex(index);
                                            }}
                                            style={{
                                                width: "60px",
                                                height: "60px",
                                                marginBottom: "5px",
                                                objectFit: "cover",
                                                borderRadius: "5px",
                                                border: "1px solid #ddd",
                                                cursor: "pointer",
                                            }}
                                        />
                                    ))}
                                </div>

                                {/* Büyük resim */}
                                <Card
                                    style={{
                                        border: "1px solid #ddd",
                                        minHeight: "350px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "20px",
                                    }}
                                >
                                    <LazyLoadImage
                                        src={activeImage}
                                        alt={product.baslik}
                                        effect="blur"
                                        onClick={() => {
                                            const foundIndex = slides.findIndex(
                                                (slide) => slide.src === activeImage
                                            );
                                            setPhotoIndex(foundIndex >= 0 ? foundIndex : 0);
                                            setLightboxOpen(true);
                                        }}
                                        style={{
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            objectFit: "contain",
                                            borderRadius: "10px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </Card>
                            </div>
                        </Col>

                        {/* Ürün Başlık, Fiyat, Sepet vb. */}
                        <Col md={7} style={{ padding: "20px" }}>
                            <h6 style={{ fontWeight: "500", color: "#000000", marginBottom: "10px" }}>
                                Bosch Professional
                            </h6>
                            <h4 style={{ fontWeight: "600", marginBottom: "15px" }}>
                                {product.baslik}
                            </h4>
                            <p style={{ color: "#171616", marginBottom: "15px", fontSize: "14px" }}>
                                {product.usticerik}
                            </p>
                            <p
                                style={{
                                    fontSize: "15px",
                                    color: "#1c1c1c",
                                    marginBottom: "30px",
                                    marginTop: "30px",
                                }}
                            >
                                Ürün Kodu: {product.stok_kodu || "Belirtilmemiş"}
                            </p>

                            {/* Fiyat - İndirim */}
                            <div className="d-flex align-items-start mb-3">
                                {discountPercentage > 0 && (
                                    <div
                                        style={{
                                            background: "#28a745",
                                            color: "white",
                                            fontWeight: "bold",
                                            borderRadius: "5px",
                                            padding: "8px 10px",
                                            fontSize: "18px",
                                            textAlign: "center",
                                            marginRight: "10px",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            minWidth: "60px",
                                            minHeight: "50px",
                                        }}
                                    >
                                        %{discountPercentage}
                                        <span style={{ fontSize: "12px", marginTop: "2px" }}>İndirim</span>
                                    </div>
                                )}

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    {product.eski_fiyat && (
                                        <p
                                            style={{
                                                fontSize: "18px",
                                                fontWeight: "500",
                                                color: "#888",
                                                textDecoration: "line-through",
                                                margin: "0",
                                            }}
                                        >
                                            {product.eski_fiyat}₺
                                        </p>
                                    )}
                                    <p
                                        style={{
                                            fontSize: "28px",
                                            fontWeight: "500",
                                            color: "#000",
                                            margin: "0",
                                        }}
                                    >
                                        {product.fiyat}₺
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex align-items-center mb-4">
                                <Button
                                    variant="light"
                                    style={{
                                        border: "1px solid #ddd",
                                        width: "40px",
                                        textAlign: "center",
                                        marginRight: "5px",
                                    }}
                                    onClick={() => handleQuantityChange("decrease")}
                                >
                                    −
                                </Button>
                                <span style={{ fontSize: "16px", margin: "0 10px" }}>{quantity}</span>
                                <Button
                                    variant="light"
                                    style={{
                                        border: "1px solid #ddd",
                                        width: "40px",
                                        textAlign: "center",
                                        marginLeft: "5px",
                                    }}
                                    onClick={() => handleQuantityChange("increase")}
                                >
                                    +
                                </Button>
                            </div>

                            {/* Sepete Ekle & Favoriler */}
                            <div style={{ display: "flex", gap: "8px" }}>
                                <Button
                                    variant="primary"
                                    style={{
                                        flex: "1",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        padding: "10px",
                                        background: "#6c63ff",
                                        border: "none",
                                    }}
                                    onClick={() => onAddToCart(product, quantity)}
                                >
                                    Sepeti Onayla
                                </Button>
                                <Button
                                    variant="light"
                                    style={{
                                        width: "50px",
                                        border: "1px solid #ddd",
                                    }}
                                    onClick={handleAddToFavorites}
                                >
                                    <FaHeart style={{ color: "rgba(205,9,38,0.87)", fontSize: "20px" }} />
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    {/* Ürün Detayı */}
                    <Row style={{ marginTop: "50px" }}>
                        <Col md={12}>
                            <h4>Ürün Detayı</h4>
                            <p
                                style={{ color: "#555", lineHeight: "1.6" }}
                                dangerouslySetInnerHTML={{
                                    __html: product.icerik || "Ürün detay bilgisi mevcut değil.",
                                }}
                            />
                        </Col>
                    </Row>

                    {/* Yorumlar (HER ZAMAN gösterilir) */}
                    <Row className="mt-5" id="yorumlar">
                        <Col md={12}>
                            <Card className="shadow-sm">
                                <Card.Header style={{background:'#FA8C17'}} className="text-white">
                                    <h5 className="mb-0">Ürün Yorumları</h5>
                                </Card.Header>
                                <Card.Body>

                                    {/* Önceki Yorumları Her Zaman Listele */}
                                    <PreviousComments urunId={product.id} />


                                    {yorumModu && (
                                        user ? (
                                            userAlreadyCommented ? (
                                                <Alert variant="info" className="mt-3">
                                                    Bu ürüne daha önce yorum yaptınız. Birden fazla yorum yapamazsınız.
                                                </Alert>
                                            ) : (
                                                <Form onSubmit={handleCommentSubmit} className="mt-4">
                                                    {/* form elemanları buraya */}
                                                </Form>
                                            )
                                        ) : (
                                            <Form onSubmit={handleCommentSubmit} className="mt-4">
                                                <h5 style={{color:'#FA8C17'}} className="mb-3">Siparişinizi Değerlendirin</h5>

                                                {/* Kullanıcı adı göster */}
                                                <Form.Group className="mb-3">
                                                    <Form.Label><strong>Adınız</strong></Form.Label>
                                                    <Form.Control type="text" value={user?.name} disabled />
                                                </Form.Group>

                                                {/* Yorum alanı */}
                                                <Form.Group className="mb-3">
                                                    <Form.Label><strong>Yorumunuz</strong></Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={4}
                                                        value={commentText}
                                                        onChange={(e) => setCommentText(e.target.value)}
                                                        placeholder="Ürün hakkındaki düşüncenizi yazın..."
                                                        required
                                                    />
                                                </Form.Group>

                                                {/* Yıldız Puanlama */}
                                                <Form.Group className="mb-3">
                                                    <Form.Label><strong>Puanınız</strong></Form.Label>
                                                    <div>
                                                        {[1, 2, 3, 4, 5].map((star) => (
                                                            <FaStar
                                                                key={star}
                                                                size={24}
                                                                color={star <= rating ? "#ffc107" : "#e4e5e9"}
                                                                onClick={() => setRating(star)}
                                                                style={{ cursor: "pointer", marginRight: 5 }}
                                                            />
                                                        ))}
                                                    </div>
                                                </Form.Group>

                                                <Button type="submit" variant="success">
                                                    Yorumu Gönder
                                                </Button>
                                            </Form>

                                        )
                                    )}


                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>


                </Col>

                {/* Sağ Kısım: Kampanyalar + Kurumsal Sipariş Formu */}
                <Col
                    md={3}
                    style={{
                        padding: "20px",
                        borderRadius: "10px",
                        background: "#fff",
                    }}
                >
                    {/* Kampanyalar */}
                    <div style={{ marginBottom: "30px" }}>
                        <h5 style={{ fontWeight: "bold", marginBottom: "10px" }}>Kampanyalar</h5>
                        <div
                            style={{
                                background: "#ecefff",
                                padding: "10px",
                                borderRadius: "5px",
                                marginBottom: "10px",
                            }}
                        >
                            📦 <strong>800,00₺ üzeri alışverişlerinizde kargo ücreti bedava!</strong>
                        </div>
                        <div
                            style={{
                                background: "#ecefff",
                                padding: "10px",
                                borderRadius: "5px",
                                marginBottom: "10px",
                            }}
                        >
                            🎁 <strong>Yeni üyelere özel ürünlerde 100,00₺ indirim!</strong>
                        </div>
                        <ListGroup variant="flush">
                            <ListGroup.Item style={{ border: "none", padding: "8px 0" }}>
                                ✅ %100 orijinal ürün
                            </ListGroup.Item>
                            <ListGroup.Item style={{ border: "none", padding: "8px 0" }}>
                                📦 Aynı gün stoktan teslimat
                            </ListGroup.Item>
                            <ListGroup.Item style={{ border: "none", padding: "8px 0" }}>
                                🇹🇷 Türkiye yetkili satıcısından gönderim
                            </ListGroup.Item>
                        </ListGroup>
                    </div>

                    {/* Kurumsal Sipariş Formu */}
                    <div
                        style={{
                            border: "1px solid #ddd",
                            padding: "20px",
                            borderRadius: "10px",
                        }}
                    >
                        <h4 style={{ fontWeight: "bold", marginBottom: "15px" }}>Kurumsal Sipariş</h4>
                        <p style={{ fontSize: "14px", color: "#777", marginBottom: "20px" }}>
                            Kurumsal siparişleriniz için aşağıdaki formu eksiksiz doldurun.
                            Satış Mühendislerimiz size en kısa sürede geri dönüş yapacaktır.
                        </p>

                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formName" className="mb-3">
                                <Form.Label>
                                    <strong>Adınız Soyadınız</strong>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Adınız Soyadınız"
                                    required
                                />
                            </Form.Group>

                            {/* E-posta */}
                            <Form.Group controlId="formEmail" className="mb-3">
                                <Form.Label>
                                    <strong>E-posta Adresiniz</strong>
                                </Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="E-posta adresiniz"
                                    required
                                />
                            </Form.Group>

                            {/* Telefon */}
                            <Form.Group controlId="formPhone" className="mb-3">
                                <Form.Label>
                                    <strong>Telefon Numaranız</strong>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    placeholder="Telefon numaranız"
                                    required
                                />
                            </Form.Group>

                            {/* Firma Adı */}
                            <Form.Group controlId="formCompany" className="mb-3">
                                <Form.Label>
                                    <strong>Firma Adı</strong>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="company"
                                    value={formData.company}
                                    onChange={handleChange}
                                    placeholder="Firma Adı"
                                />
                            </Form.Group>

                            {/* Şehir */}
                            <Form.Group controlId="formCity" className="mb-3">
                                <Form.Label>
                                    <strong>Şehir</strong>
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                >
                                    <option value="">Şehir Seçiniz</option>
                                    {iller.map((il, index) => (
                                        <option key={index} value={il}>
                                            {il}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            {/* Kaç Adet Satın Alınacak */}
                            <Form.Group controlId="formQuantity" className="mb-3">
                                <Form.Label>
                                    <strong>Satın Almak İstediğiniz Adet</strong>
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="quantity"
                                    value={formData.quantity}
                                    onChange={handleChange}
                                    min="1"
                                    placeholder="Adet"
                                    required
                                />
                            </Form.Group>

                            {/* Mesaj */}
                            <Form.Group controlId="formMessage" className="mb-3">
                                <Form.Label>
                                    <strong>Mesajınız</strong>
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="message"
                                    rows={4}
                                    value={formData.message}
                                    onChange={handleChange}
                                    placeholder="Mesajınızı yazın"
                                    required
                                />
                            </Form.Group>

                            {successMessage && <Alert variant="success">{successMessage}</Alert>}
                            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

                            <Button
                                type="submit"
                                style={{
                                    width: "100%",
                                    background: "#6c63ff",
                                    border: "none",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    padding: "10px",
                                }}
                            >
                                Formu Gönder
                            </Button>
                        </Form>
                    </div>
                </Col>
            </Row>

            {/* Benzer Ürünler Slider */}
            <Row style={{ marginTop: "50px" }}>
                <Col md={12}>
                    <h3 style={{ marginBottom: "20px" }}>Benzer Ürünler</h3>
                    <Slider
                        dots={false}
                        infinite={true}
                        speed={300}
                        slidesToShow={5}
                        slidesToScroll={1}
                        arrows={true}
                        nextArrow={<div className="slick-next" />}
                        prevArrow={<div className="slick-prev" />}
                        draggable={false}
                        swipe={true}
                        // [EKLENDİ] Gelişmiş Fare Basma/Hareket/Bırakma
                        onMouseDown={(e) => {
                            isDraggingRef.current = false;
                            startXRef.current = e.clientX;
                            startYRef.current = e.clientY;
                        }}
                        onMouseMove={(e) => {
                            const diffX = Math.abs(e.clientX - startXRef.current);
                            const diffY = Math.abs(e.clientY - startYRef.current);
                            if (diffX > dragThreshold || diffY > dragThreshold) {
                                isDraggingRef.current = true;
                            }
                        }}
                        onMouseUp={() => {
                            // 50 ms sonra reset, yine de caution
                            setTimeout(() => (isDraggingRef.current = false), 50);
                        }}
                        responsive={[
                            {
                                breakpoint: 992,
                                settings: { slidesToShow: 2 },
                            },
                            {
                                breakpoint: 576,
                                settings: { slidesToShow: 1 },
                            },
                        ]}
                    >
                        {similarProducts.map((product, index) => (
                            <div key={index} style={{ padding: "0 10px" }}>
                                <Card
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        // Eğer sürükleme yapılmadıysa, karta tıklamaya izin ver
                                        if (!isDraggingRef.current) {
                                            navigate(`/urun/${product.id}`);
                                        }
                                    }}
                                    className="text-center shadow-sm p-1 m-1"
                                >
                                    <LazyLoadImage
                                        src={`https://panel.pahaliyaalma.com/${product.resimyol}`}
                                        alt={product.baslik}
                                        effect="blur"
                                        style={{
                                            width: "100%",
                                            height: "170px",
                                            objectFit: "scale-down",
                                            marginBottom: "10px",
                                            borderRadius: "5px",
                                        }}
                                    />
                                    <Card.Body>
                                        <Card.Title
                                            style={{
                                                fontSize: "0.95rem",
                                                fontWeight: "500",
                                                textAlign: "left",
                                                lineHeight: "1.2",
                                                height: "4.8em",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "-webkit-box",
                                                WebkitLineClamp: 4,
                                                WebkitBoxOrient: "vertical",
                                            }}
                                        >
                                            {product.baslik}
                                        </Card.Title>

                                        {product.eski_fiyat && product.fiyat && (
                                            <div className="discount-badge">
                                                - %
                                                {Math.round(
                                                    ((product.eski_fiyat - product.fiyat) /
                                                        product.eski_fiyat) *
                                                    100
                                                )}
                                            </div>
                                        )}

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                marginTop: "10px",
                                            }}
                                        >
                                            {product.eski_fiyat && (
                                                <Card.Text
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: "500",
                                                        color: "rgb(32,29,29)",
                                                        textDecoration: "line-through",
                                                    }}
                                                >
                                                    {product.eski_fiyat}₺
                                                </Card.Text>
                                            )}

                                            <Card.Text
                                                style={{
                                                    fontSize: "18px",
                                                    fontWeight: "500",
                                                    color: "rgb(32,29,29)",
                                                }}
                                            >
                                                {product.fiyat}₺
                                            </Card.Text>
                                        </div>

                                        <Button
                                            className="sepete-ekle-button"
                                            onClick={(e) => {
                                                e.stopPropagation(); // Kart tıklamasını engelle
                                                onAddToCart(product, 1);
                                            }}
                                        >
                                            Sepete Ekle
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </div>
                        ))}
                    </Slider>
                </Col>
            </Row>

            {/* React-hot-toast mesajları */}
            <Toaster position="bottom-right" reverseOrder={false} />
        </Container>
    );
};

export default UrunDetay;
