import React, { useEffect, useState,useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import { CacheContext } from "../Context/CacheContext"; // CacheContext import


const UstFooter = () => {
    const [datacat, setDatacat] = useState([]); // API'den gelen veriler
    const { cacheResetFlag } = useContext(CacheContext); // Cache sıfırlama sinyali
    const CACHE_KEY = "ustfooter_kategoriler_cache";

    useEffect(() => {
        const cachedData = localStorage.getItem(CACHE_KEY);

        if (cachedData && !cacheResetFlag) {
            const parsedData = JSON.parse(cachedData);
            const filtered = parsedData.filter(category => category.alt_kategori_id === null);
            setDatacat(filtered);
        } else {
            axios
                .get("https://panel.pahaliyaalma.com/api/data/kategoriler")
                .then((response) => {
                    // Cache yazmadan önce hepsini yaz, filtre sonra
                    localStorage.setItem(CACHE_KEY, JSON.stringify(response.data));
                    const filteredData = response.data.filter(category => category.alt_kategori_id === null);
                    setDatacat(filteredData);
                })
                .catch((error) => {
                    console.error("Error fetching data: ", error);
                });
        }
    }, [cacheResetFlag]);

    // Kategorileri eşit parçalara bölme fonksiyonu
    const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    // Kategorileri 4 eşit parçaya böl (4 sütun için)
    const chunkedCategories = chunkArray(datacat, Math.ceil(datacat.length / 4));

    // Ortak stiller
    const styles = {
        heading: {
            fontWeight: 'bold',
            marginBottom: '15px',
            color: '#000',
        },
        text: {
            fontSize: '15px',
            color: '#000',
            lineHeight: '1.6',
        },
        link: {
            color: '#000',
            textDecoration: 'none',
            fontSize: '17px',
            lineHeight: '25px',
        },
        borderTop: {
            borderTop: '1px solid #ddd',
        },
        socialIcon: {
            color: '#000',
            fontSize: '1.5em',
        },
    };

    return (
        <footer style={{ backgroundColor: '#f6f6f6', padding: '60px 0 40px 0', ...styles.borderTop }}>
            <Container>
                <Row className="mb-4">
                    <h5 style={styles.heading}>ALIŞVERİŞ</h5>
                    {/* Dinamik olarak sütunlar oluştur */}
                    {chunkedCategories.map((chunk, index) => (
                        <Col md={3} key={index}>

                            <ul className="list-unstyled">
                                {chunk.map((category) => (
                                    <li key={category.id}>
                                        <Link to={`/kategori/${category.slug}`} style={styles.link}>
                                            {category.baslik}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    ))}
                </Row>
            </Container>
        </footer>
    );
};

export default UstFooter;