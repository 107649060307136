import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Table, Button, Spinner, Badge } from "react-bootstrap";
import axios from "axios";
import UyeMenu from "../../Component/UyeMenu";
import { useUser } from "../../Context/UserContext";
import toast, { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate } from "react-router-dom";

const UyeSiparisler = () => {
    const { user } = useUser(); // Kullanıcı bilgilerini bağlamdan al
    const [orders, setOrders] = useState([]); // Siparişleri saklamak için state
    const [loading, setLoading] = useState(true); // Yükleme durumu için state
    const [error, setError] = useState(null); // Hata durumu için state

    const location = useLocation();
    const navigate = useNavigate();
    const token = localStorage.getItem("api_token");



    React.useEffect(() => {
        const storedMessage = sessionStorage.getItem("toastMessage");
        if (storedMessage) {
            const { message, type } = JSON.parse(storedMessage);
            toast.dismiss();
            if (type === "success") {
                toast.success(message);
            } else if (type === "error") {
                toast.error(message);
            }
            sessionStorage.removeItem("toastMessage"); // Mesajı sil
        }
    }, []);

    useEffect(() => {
        const fetchOrders = async () => {
            if (!user || !user.id) {
                setError("Kullanıcı giriş yapmamış.");
                setLoading(false);
                return;
            }

            try {
                // API'ye kullanıcı kimliğiyle isteği gönder
                const response = await axios.post("https://panel.pahaliyaalma.com/api/uye/siparisler", {
                    user_id: user.id, // Kullanıcı kimliği gönderiliyor
                },{
                    headers: {
                        Authorization: `${token}`, // **"Bearer " ekledik!**
                    },
                });

                setOrders(response.data.orders); // API'den gelen siparişleri kaydet
                setLoading(false);
            } catch (err) {
                setError("Siparişler yüklenirken bir hata oluştu.");
                setLoading(false);
            }
        };

        fetchOrders();
    }, [user]); // Kullanıcı değiştiğinde yeniden çalışır

    const getBadge = (durum) => {
        switch (durum) {
            case "0":
                return <Badge bg="warning">Bekliyor</Badge>;
            case "1":
                return <Badge bg="success">Sipariş Hazırlanıyor</Badge>;
            case "2":
                return <Badge bg="info">Kargoya Verildi</Badge>;
            case "3":
                return <Badge bg="danger">İptal Edildi</Badge>;
            case "4":
                return <Badge bg="primary">İade Talebi</Badge>;
            case "5":
                return <Badge bg="primary">İade Onay</Badge>;
            case "6":
                return <Badge bg="warning">Ödeme Bekliyor</Badge>;
            case "7":
                return <Badge bg="success">Ödeme Başarılı</Badge>;
            case "8":
                return <Badge bg="danger">Ödeme Başarısız</Badge>;
            case "9":
                return <Badge bg="success">Tamamlandı</Badge>;
            default:
                return <Badge bg="secondary">Bilinmiyor</Badge>;
        }
    };

    const getodeme = (yontem) => {
        switch (yontem) {
            case "0":
                return <Badge bg="warning">Tanımsız</Badge>;
            case "1":
                return <Badge bg="success">Banka Havalesi</Badge>;
            case "2":
                return <Badge bg="primary">Kredi Kartı</Badge>;
            default:
                return <Badge bg="secondary">Bilinmiyor</Badge>;
        }
    };

    return (
        <Container className="mt-5">
            <Helmet>
                <title>Siparişlerim</title>
            </Helmet>
            <Row>
                {/* Sol Menü */}
                <Col md={3}>
                    <UyeMenu />
                </Col>

                {/* Sipariş Tablosu */}
                <Col md={9}>
                    <Card className="shadow-sm">
                        <Card.Body>
                            <h5 className="mb-4 text-center">Siparişlerim</h5>
                            {loading ? (
                                <div className="text-center">
                                    <Spinner animation="border" role="status" />
                                    <p>Yükleniyor...</p>
                                </div>
                            ) : error ? (
                                <p className="text-center text-danger">{error}</p>
                            ) : orders && orders.length > 0 ? (
                                <Table striped bordered hover responsive>
                                    <thead>
                                    <tr>
                                        <th>Sipariş ID</th>
                                        <th>Genel Toplam</th>
                                        <th>Yöntem</th>

                                        <th>Durum</th>
                                        <th>Detay</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {orders.map((order, index) => (
                                        <tr key={index}>
                                            <td>{order.id}</td>
                                            <td>{order.toplam_fiyat}₺</td>
                                            <td>{getodeme(order.yontem)}</td>

                                            <td>{getBadge(order.durum)}</td>
                                            <td>
                                                <Link
                                                    to={`/uye/siparis/${order.id}`}
                                                    state={{ showToast: false }}
                                                    className="btn btn-primary btn-sm"
                                                >
                                                    Detay
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p className="text-center text-muted">Henüz siparişiniz bulunmamaktadır.</p>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Toaster position="top-right" reverseOrder={false} />
        </Container>
    );
};

export default UyeSiparisler;
