import React, { useState } from "react";
import { Container, Button, Table, Form, Image, Row, Col, Alert } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axios from "axios";

import ScrollToTopOnMount from "../../Component/ScrollToTopOnMount";
import InfoSection from "../../Component/InfoSection";

// 🔥 Eğer user bilgisini context'ten alıyorsak:
import { useUser } from "../../Context/UserContext";

const SepetPage = ({ cartItems, onRemoveItem, onUpdateQuantity }) => {
    const [kuponKodu, setKuponKodu] = useState("");
    const [kuponBilgi, setKuponBilgi] = useState(null);
    const [kuponHata, setKuponHata] = useState("");

    // 🔥 user bilgisi (kullanıcı ID için)
    const { user } = useUser();

    // Sepet toplamı hesaplama
    const calculateSubtotal = () =>
        cartItems.reduce((total, item) => total + Number(item.fiyat) * item.quantity, 0);

    // Örnek olarak %20 KDV
    const calculateKDV = () => calculateSubtotal() * 0.2;

    // Kupon uygulandıktan sonraki toplam
    const calculateDiscountedTotal = () => {
        const subtotal = calculateSubtotal();
        let discount = 0;

        if (kuponBilgi) {
            // Yüzdelik indirim
            if (kuponBilgi.indirim_orani) {
                discount = subtotal * kuponBilgi.indirim_orani;
            }
            // Sabit miktar indirimi
            else if (kuponBilgi.indirim_miktar) {
                discount = kuponBilgi.indirim_miktar;
            }
        }
        const totalAfterDiscount = subtotal - discount;
        return totalAfterDiscount > 0 ? totalAfterDiscount : 0;
    };

    // Para formatlama fonksiyonu
    const formatCurrency = (value) => {
        return (
            new Intl.NumberFormat("tr-TR", {
                style: "currency",
                currency: "TRY",
                minimumFractionDigits: 2,
            })
                .format(value)
                .replace("₺", "") + "₺"
        );
    };

    // 📌 Kupon kodu uygulama (Yeni Sisteme Göre Güncellenmiş)
    const handleApplyCoupon = async () => {
        if (!kuponKodu) {
            setKuponHata("Lütfen bir kupon kodu girin.");
            return;
        }

        // 🔥 Sepet ürün ID listesi
        const sepetUrunIdListesi = cartItems.map((item) => item.id);

        try {
            const response = await axios.post(
                "https://panel.pahaliyaalma.com/api/uye/kuponkontrol",
                {
                    kupon_kodu: kuponKodu,
                    uye_id: user ? user.id : null, // Kullanıcı ID
                    sepet_toplami: calculateSubtotal(), // Minimum sepet tutarı kontrolü
                    sepet_urunleri: sepetUrunIdListesi, // Kategori/Marka kontrolü
                }
            );

            if (response.data.success) {
                setKuponBilgi(response.data.kupon);
                setKuponHata("");
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setKuponHata(error.response.data.message);
            } else {
                setKuponHata("Kupon kontrol edilirken bir hata oluştu.");
            }
            setKuponBilgi(null);
        }
    };

    return (
        <Container className="mt-5">
            <Helmet>
                <title>Sepet</title>
            </Helmet>

            <ScrollToTopOnMount />

            <Row>
                <Col md={8}>
                    <h4 className="mb-4 fw-bold">SEPET DETAYI</h4>
                    {cartItems.length === 0 ? (
                        <p className="text-center">Sepetiniz boş.</p>
                    ) : (
                        <div className="table-wrapper">
                            <Table responsive className="custom-table">
                                <tbody>
                                {cartItems.map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-center align-middle">
                                            <Image
                                                src={`https://panel.pahaliyaalma.com/${item.resimyol}`}
                                                alt={item.baslik}
                                                rounded
                                                className="product-image"
                                            />
                                        </td>
                                        <td className="align-middle">{item.baslik}</td>
                                        <td className="text-center align-middle">
                                            <div className="quantity-group">
                                                <button
                                                    className="quantity-btn"
                                                    onClick={() =>
                                                        onUpdateQuantity(item.id, Math.max(1, item.quantity - 1))
                                                    }
                                                >
                                                    −
                                                </button>
                                                <input
                                                    type="text"
                                                    value={item.quantity || 1}
                                                    readOnly
                                                    className="quantity-input"
                                                />
                                                <button
                                                    className="quantity-btn"
                                                    onClick={() => onUpdateQuantity(item.id, item.quantity + 1)}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        </td>
                                        <td className="text-center align-middle">
                                            {formatCurrency(Number(item.fiyat))}
                                        </td>
                                        <td className="text-center align-middle">
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() => onRemoveItem(item.id)}
                                            >
                                                Sil
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    )}

                    {/* Kupon Uygulama Alanı */}
                    <Form className="mt-3 d-flex">
                        <Form.Control
                            type="text"
                            placeholder="Kupon Kodunuz var mı?"
                            value={kuponKodu}
                            onChange={(e) => setKuponKodu(e.target.value)}
                        />
                        <Button variant="success" className="ms-2" onClick={handleApplyCoupon}>
                            Uygula
                        </Button>
                    </Form>

                    {kuponHata && (
                        <Alert variant="danger" className="mt-2">
                            {kuponHata}
                        </Alert>
                    )}
                    {kuponBilgi && (
                        <Alert variant="success" className="mt-2">
                            {kuponBilgi.indirim_orani
                                ? `%${kuponBilgi.indirim_orani * 100} indirim uygulandı!`
                                : `${kuponBilgi.indirim_miktar}₺ indirim uygulandı!`}
                        </Alert>
                    )}
                </Col>

                {/* Sepet Özeti */}
                <Col md={4}>
                    <h4 className="mb-4 fw-bold">SEPET ÖZETİ</h4>
                    <div className="summary-box">
                        <div
                            style={{ fontWeight: "500" }}
                            className="d-flex justify-content-between mb-2"
                        >
                            <span>Ara Toplam</span>
                            <span>{formatCurrency(calculateSubtotal())}</span>
                        </div>
                        <div
                            style={{ fontWeight: "500" }}
                            className="d-flex justify-content-between mb-2"
                        >
                            <span>KDV %20</span>
                            <span>{formatCurrency(calculateKDV())}</span>
                        </div>
                        {kuponBilgi && (
                            <div
                                style={{ fontWeight: "500" }}
                                className="d-flex justify-content-between mb-2"
                            >
                                <span>Kupon İndirimi</span>
                                <span>
                  -{formatCurrency(calculateSubtotal() - calculateDiscountedTotal())}
                </span>
                            </div>
                        )}
                        <div className="summary-divider"></div>
                        <div
                            style={{ fontWeight: "500" }}
                            className="d-flex justify-content-between mt-3"
                        >
                            <strong>KDV Dahil</strong>
                            <strong>
                                {formatCurrency(calculateDiscountedTotal() + calculateKDV())}
                            </strong>
                        </div>

                        {/* React Router v6 ile state taşıma */}
                        <Link to="/satinal" state={{ kuponBilgi }} style={{ textDecoration: "none" }}>
                            <Button variant="primary" className="w-100 mt-3 fw-bold">
                                Sepeti Onayla
                            </Button>
                        </Link>
                    </div>
                </Col>
            </Row>

            <div style={{ marginTop: "100px" }}>
                <InfoSection />
            </div>

            {/* Özel CSS */}
            <style>
                {`
          .table-wrapper {
            border-radius: 8px;
            overflow: hidden;
            margin-bottom: 20px;
            background: linear-gradient(to bottom right, #ffffff, #f2f4f8);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            border: 1px solid #ddd;
          }
          .custom-table {
            margin: 0;
            background: transparent;
          }
          .custom-table tr {
            border-bottom: 1px solid #ddd;
          }
          .custom-table tr:last-child {
            border-bottom: 1px solid #ddd;
          }
          .custom-table td {
            padding: 25px 10px;
            text-align: center;
            vertical-align: middle;
            background: transparent;
          }
          .product-image {
            width: 60px;
            object-fit: contain;
            border-radius: 5px;
          }
          .quantity-group {
            display: flex;
            align-items: center;
            border: 1px solid #ccc;
            border-radius: 8px;
            overflow: hidden;
            width: 120px;
          }
          .quantity-btn {
            background: transparent;
            border: none;
            padding: 5px 10px;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
          }
          .quantity-btn:hover {
            background: #e2e6ea;
          }
          .quantity-input {
            width: 50px;
            text-align: center;
            border: none;
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            font-size: 14px;
            background: transparent;
          }
          .summary-box {
            border: 1px solid #ddd;
            border-radius: 8px;
            padding: 30px 20px;
            background: #f9f9f9;
            text-align: center;
          }
          .summary-divider {
            border-top: 1px solid #ccc;
            margin: 20px 0;
            opacity: 0.7;
          }
        `}
            </style>
        </Container>
    );
};

export default SepetPage;
