import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

const useToasterMessage = () => {
    useEffect(() => {
        const storedMessage = sessionStorage.getItem("toastMessage");
        if (storedMessage) {
            const { message, type } = JSON.parse(storedMessage);
            toast.dismiss();

            if (type === "success") {
                toast.success(message);
            } else if (type === "error") {
                toast.error(message);
            }

            sessionStorage.removeItem("toastMessage"); // Mesajı bir kez gösterip sil
        }
    }, []);
};

export default useToasterMessage;
