import React, { useState } from "react";
import {
    Card,
    ListGroup,
    Alert,
    Button,
    Collapse
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "../Context/UserContext";
// React Icons'tan hamburger (FaBars) ve çarpı (FaTimes) ikonları
import { FaBars, FaTimes } from "react-icons/fa";

const UyeMenu = () => {
    const { user, logout } = useUser();
    const location = useLocation();

    // Mobil menü aç/kapa
    const [showMenu, setShowMenu] = useState(false);

    if (!user) {
        return (
            <Alert variant="warning" className="text-center">
                Kullanıcı bilgileri bulunamadı. Lütfen tekrar giriş yapın.
            </Alert>
        );
    }

    // Kullanıcının adının ilk harfi
    const getInitials = (name) => (name ? name.charAt(0).toUpperCase() : "?");

    // Tekrar etmemek için menü öğelerini fonksiyon haline getirdik
    const renderMenuItems = () => (
        <ListGroup>
            <ListGroup.Item
                as={Link}
                to="/uye/panel"
                action
                active={location.pathname === "/uye/panel"}
            >
                Üye Paneli
            </ListGroup.Item>
            <ListGroup.Item
                as={Link}
                to="/uye/siparisler"
                action
                active={location.pathname === "/uye/siparisler"}
            >
                Siparişlerim
            </ListGroup.Item>
            <ListGroup.Item
                as={Link}
                to="/uye/profile"
                action
                active={location.pathname === "/uye/profile"}
            >
                Üyelik Bilgilerim
            </ListGroup.Item>
            <ListGroup.Item
                as={Link}
                to="/uye/adreslerim"
                action
                active={location.pathname === "/uye/adreslerim"}
            >
                Adreslerim
            </ListGroup.Item>
            <ListGroup.Item
                as={Link}
                to="/uye/favoriler"
                action
                active={location.pathname === "/uye/favoriler"}
            >
                Favorilerim
            </ListGroup.Item>
            <ListGroup.Item
                as={Link}
                to="/uye/kuponlar"
                action
                active={location.pathname === "/uye/kuponlar"}
            >
                Hediye Kuponlarım
            </ListGroup.Item>
            <ListGroup.Item
                as={Link}
                to="/uye/talepler"
                action
                active={location.pathname === "/uye/talepler"}
            >
                Destek Taleplerim
            </ListGroup.Item>
            {/* Çıkış Butonu */}
            <ListGroup.Item className="text-center">
                <Button variant="danger" size="sm" className="w-100" onClick={logout}>
                    Çıkış Yap
                </Button>
            </ListGroup.Item>
        </ListGroup>
    );

    return (
        <div>
            {/* Üst kısım: Kullanıcı Kartı */}
            <Card className="shadow-sm mb-4">
                <Card.Body className="text-center">
                    <div
                        style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50%",
                            backgroundColor: "#fa8c17",
                            color: "#ffffff",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "24px",
                            fontWeight: "bold",
                            margin: "15px auto",
                        }}
                    >
                        {getInitials(user.name)}
                    </div>
                    <h5>{user.name}</h5>
                </Card.Body>
            </Card>

            {/* Mobilde (d-md-none) menü butonu + Collapse */}
            <div className="d-md-none">
                <Button
                    variant="primary"
                    onClick={() => setShowMenu(!showMenu)}
                    className="mb-2 w-100 d-flex align-items-center justify-content-center"
                >
                    {/* showMenu true ise çarpı (FaTimes), değilse hamburger (FaBars) */}
                    {showMenu ? <FaTimes /> : <FaBars />}
                    <span className="ms-2">Menü</span>
                </Button>

                <Collapse in={showMenu}>
                    <div>{renderMenuItems()}</div>
                </Collapse>
            </div>

            {/* Masaüstünde (d-none d-md-block) menü her zaman açık */}
            <div className="d-none d-md-block">{renderMenuItems()}</div>
        </div>
    );
};

export default UyeMenu;
