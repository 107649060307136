import React from 'react';
import FeatureSlider from "./Component/FeatureSlider";
import { Helmet } from "react-helmet";
import InfoSection from "./Component/InfoSection";
import Populerurun from "./Component/Populerurun";
import SonEklenenUrunler from "./Component/SonEklenenUrunler";
import IndirimliUrunler from "./Component/IndirimliUrunler";
import Ustbanner from "./Component/Ustbanner";
import { useMediaQuery } from 'react-responsive';
import MobilFeatureSlider from "./Component/MobilFeatureSlider";

const Home = ({ onAddToCart }) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    return (
        <div>
            <Helmet>
                <title>Pahalıya Alma</title>
            </Helmet>
            <div>

                {isMobile ? <MobilFeatureSlider /> : <FeatureSlider />}

                <InfoSection/>
                <Populerurun onAddToCart={onAddToCart}/>
                <Ustbanner/>
                <SonEklenenUrunler onAddToCart={onAddToCart}/>
                <IndirimliUrunler onAddToCart={onAddToCart}/>

            </div>
        </div>
    );
};

export default Home;
