import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Table,
    Spinner,
    Badge,
    Button,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import UyeMenu from "../../Component/UyeMenu";
import { useUser } from "../../Context/UserContext";
import { Helmet } from "react-helmet";
import toast, { Toaster } from "react-hot-toast";


const getBadge = (durum) => {
    switch (durum) {
        case "0":
            return <Badge bg="warning">Bekliyor</Badge>;
        case "1":
            return <Badge bg="success">Sipariş Hazırlanıyor</Badge>;
        case "2":
            return <Badge bg="info">Kargoya Verildi</Badge>;
        case "3":
            return <Badge bg="danger">İptal Edildi</Badge>;
        case "4":
            return <Badge bg="dark">İade Talebi</Badge>;
        case "5":
            return <Badge bg="dark">İade Onay</Badge>;
        case "6":
            return <Badge bg="warning">Ödeme Bekliyor</Badge>;
        case "7":
            return <Badge bg="success">Ödeme Başarılı</Badge>;
        case "8":
            return <Badge bg="danger">Ödeme Başarısız</Badge>;
        case "9":
            return <Badge bg="success">Tamamlandı</Badge>;
        default:
            return <Badge bg="secondary">Bilinmiyor</Badge>;
    }
};

const UyeSiparisDetay = () => {
    const { user } = useUser();
    const { siparisId } = useParams();
    const navigate = useNavigate();

    const [orderDetails, setOrderDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = localStorage.getItem("api_token");

    useEffect(() => {
        const fetchOrderDetails = async () => {
            if (!user || !user.id) {
                setError("Kullanıcı giriş yapmamış.");
                setLoading(false);
                return;
            }

            try {
                const response = await axios.post(
                    "https://panel.pahaliyaalma.com/api/uye/siparisdetay",
                    {
                        user_id: user.id,
                        siparis_id: siparisId,
                    },{
                        headers: {
                            Authorization: `${token}`, // **"Bearer " ekledik!**
                        },
                    }
                );
                const { order } = response.data;

                // JSON parse fonksiyonu
                const parseAddress = (adres) => {
                    if (!adres) return null;
                    if (typeof adres === "string") {
                        try {
                            return JSON.parse(adres);
                        } catch {
                            return adres;
                        }
                    }
                    return adres;
                };

                setOrderDetails({
                    ...order,
                    teslimat_adresi: parseAddress(order.teslimat_adresi),
                    fatura_adresi: parseAddress(order.fatura_adresi),
                    items: order.items.map((item) => ({
                        urun_adi: item.urun_adi,
                        miktar: item.miktar,
                        birim_fiyat: item.birim_fiyat,
                        toplam_fiyat: item.toplam_fiyat,
                        urun_id: item.urun_id, // ekledik

                    })),
                });
                setLoading(false);
            } catch (err) {
                setError("Sipariş detayları yüklenirken bir hata oluştu.");
                setLoading(false);
            }
        };

        fetchOrderDetails();
    }, [user, siparisId]);

    if (loading) {
        return (
            <Container className="mt-5 text-center">
                <Spinner animation="border" role="status" />
                <p>Yükleniyor...</p>
            </Container>
        );
    }

    if (error) {
        return (
            <Container className="mt-5 text-center text-danger">
                <p>{error}</p>
                <Button variant="primary" onClick={() => navigate(-1)}>
                    Geri Dön
                </Button>
            </Container>
        );
    }

    // orderDetails yüklendiği zaman
    const {
        id,
        created_at,
        durum,
        sepet_toplam,
        kdv_miktar,
        kargo_ucreti,
        indirim_tutar,
        toplam_fiyat,
        yontem,
        referans_kodu,
        havale_hesabi,
        teslimat_adresi,
        fatura_adresi,
        kargo_yontem,
        kargo_takip,
        items,
    } = orderDetails;

    // Tarih-saat formatı
    const formattedDate = new Date(created_at).toLocaleDateString("tr-TR");
    const formattedTime = new Date(created_at).toLocaleTimeString("tr-TR");

    return (
        <Container className="mt-5">
            <Helmet>
                <title>Sipariş Detay</title>
            </Helmet>
            <Row>
                {/* Sol Menü */}
                <Col md={3}>
                    <UyeMenu />
                </Col>

                {/* Sağ Tarafta Sipariş Detayları */}
                <Col md={9}>
                    <Card className="shadow-sm p-3 mb-4">
                        <h4 className="text-center mb-4">Sipariş Detay</h4>

                        {/* SİPARİŞ ÖZETİ */}
                        <Card className="mb-3">
                            <Card.Header className="bg-light text-dark">
                                <h5 className="mb-0">Sipariş Özeti</h5>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Table bordered responsive className="mb-0">
                                    <tbody>
                                    <tr>
                                        <th style={{width: "30%"}}>Sipariş ID</th>
                                        <td>{id}</td>
                                    </tr>
                                    <tr>
                                        <th>Tarih</th>
                                        <td>
                                            {formattedDate} {formattedTime}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>Ara Toplam</th>
                                        <td>{sepet_toplam}₺</td>
                                    </tr>
                                    <tr>
                                        <th>KDV Toplam</th>
                                        <td>{kdv_miktar}₺</td>
                                    </tr>
                                    <tr>
                                        <th>Kargo Ücreti</th>
                                        <td>{kargo_ucreti}₺</td>
                                    </tr>

                                    {indirim_tutar > 0 && (
                                        <tr>
                                            <th>İndirim Tutarı</th>
                                            <td>{indirim_tutar}₺</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <th>
                                            <strong>Genel Toplam</strong>
                                        </th>
                                        <td>
                                            <strong>{toplam_fiyat}₺</strong>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>Durum</th>
                                        <td>{getBadge(durum)}</td>
                                    </tr>

                                    <tr>
                                        <th>Kargo</th>
                                        <td>{kargo_yontem}</td>
                                    </tr>

                                    {durum !== "9" && (
                                        <tr>
                                            <th>Kargom Nerede</th>
                                            <td>
                                                {kargo_takip ? (
                                                    <a target="_blank" href={kargo_takip} rel="noopener noreferrer">
                                                        {kargo_takip}
                                                    </a>
                                                ) : (
                                                    "Henüz kargoya verilmedi"
                                                )}
                                            </td>
                                        </tr>
                                    )}

                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>

                        <Row>
                            {/* TESLİMAT ADRESİ */}
                            <Col md={6}>
                                <Card className="mb-3">
                                    <Card.Header className="bg-light text-dark">
                                        <h5 className="mb-0">Teslimat Adresi</h5>
                                    </Card.Header>
                                    <Card.Body className="p-0">
                                        <Table bordered responsive className="mb-0">
                                            <tbody>
                                            {!teslimat_adresi && (
                                                <tr>
                                                    <td colSpan={2} className="text-danger p-3">
                                                        Bilgi Bulunmuyor
                                                    </td>
                                                </tr>
                                            )}
                                            {teslimat_adresi && typeof teslimat_adresi === "object" && (
                                                <>
                                                    {teslimat_adresi.tipi && (
                                                        <tr>
                                                            <th style={{ width: "30%" }}>Adres Tipi</th>
                                                            <td>{teslimat_adresi.tipi}</td>
                                                        </tr>
                                                    )}
                                                    {teslimat_adresi.sehir && (
                                                        <tr>
                                                            <th>Şehir</th>
                                                            <td>{teslimat_adresi.sehir}</td>
                                                        </tr>
                                                    )}
                                                    {teslimat_adresi.ilce && (
                                                        <tr>
                                                            <th>İlçe</th>
                                                            <td>{teslimat_adresi.ilce}</td>
                                                        </tr>
                                                    )}
                                                    {teslimat_adresi.adres && (
                                                        <tr>
                                                            <th>Adres</th>
                                                            <td>{teslimat_adresi.adres}</td>
                                                        </tr>
                                                    )}
                                                </>
                                            )}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>

                            {/* FATURA ADRESİ */}
                            <Col md={6}>
                                <Card className="mb-3">
                                    <Card.Header className="bg-light text-dark">
                                        <h5 className="mb-0">Fatura Adresi</h5>
                                    </Card.Header>
                                    <Card.Body className="p-0">
                                        <Table bordered responsive className="mb-0">
                                            <tbody>
                                            {!fatura_adresi && (
                                                <tr>
                                                    <td colSpan={2} className="text-danger p-3">
                                                        Bilgi Bulunmuyor
                                                    </td>
                                                </tr>
                                            )}
                                            {fatura_adresi && typeof fatura_adresi === "object" && (
                                                <>
                                                    {fatura_adresi.tipi && (
                                                        <tr>
                                                            <th style={{ width: "30%" }}>Adres Tipi</th>
                                                            <td>{fatura_adresi.tipi}</td>
                                                        </tr>
                                                    )}
                                                    {fatura_adresi.sehir && (
                                                        <tr>
                                                            <th>Şehir</th>
                                                            <td>{fatura_adresi.sehir}</td>
                                                        </tr>
                                                    )}
                                                    {fatura_adresi.ilce && (
                                                        <tr>
                                                            <th>İlçe</th>
                                                            <td>{fatura_adresi.ilce}</td>
                                                        </tr>
                                                    )}
                                                    {fatura_adresi.adres && (
                                                        <tr>
                                                            <th>Adres</th>
                                                            <td>{fatura_adresi.adres}</td>
                                                        </tr>
                                                    )}
                                                </>
                                            )}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            {/* ÖDEME BİLGİLERİ */}
                            <Col md={12}>
                                <Card className="mb-3">
                                    <Card.Header className="bg-light text-dark">
                                        <h5 className="mb-0">Ödeme Bilgileri</h5>
                                    </Card.Header>
                                    <Card.Body className="p-0">
                                        <Table bordered responsive className="mb-0">
                                            <tbody>
                                            <tr>
                                                <th style={{ width: "30%" }}>Ödeme Yöntemi</th>
                                                <td>{yontem === 1 ? "Banka Havalesi/EFT" : "Kredi Kartı"}</td>
                                            </tr>
                                            {referans_kodu && (
                                                <tr>
                                                    <th>Referans Kodu</th>
                                                    <td>{referans_kodu}</td>
                                                </tr>
                                            )}
                                            {havale_hesabi && (
                                                <tr>
                                                    <th>Havale Hesabı</th>
                                                    <td>{havale_hesabi}</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        {/* SİPARİŞ KALEMLERİ (TAM GENİŞLİK) */}
                        <Card>
                            <Card.Header className="bg-light text-dark">
                                <h5 className="mb-0">Sipariş Kalemleri</h5>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <Table striped bordered hover responsive className="mb-0">
                                    <thead>
                                    <tr>
                                        <th style={{ width: "30%" }}>Ürün Adı</th>
                                        <th>Miktar</th>
                                        <th>Birim Fiyat</th>
                                        <th>Toplam</th>
                                        {durum === "9" && <th>İşlem</th>}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {items.map((item, idx) => (
                                        <tr key={idx}>
                                            <td>{item.urun_adi}</td>
                                            <td>{item.miktar}</td>
                                            <td>{item.birim_fiyat}₺</td>
                                            <td>{item.toplam_fiyat}₺</td>
                                            {durum === "9" && (
                                                <td>
                                                    <a
                                                        href={`/urun/${item.urun_id}?yorum=1`}
                                                        className="btn btn-outline-primary btn-sm"
                                                    >
                                                        Ürünü Yorumla
                                                    </a>
                                                </td>
                                            )}

                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>

                        <div className="text-end mt-3">
                            <Button variant="primary" onClick={() => navigate(-1)}>
                                Geri Dön
                            </Button>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Toaster position="top-right" reverseOrder={false} />
        </Container>
    );
};

export default UyeSiparisDetay;
