import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Spinner, Alert } from "react-bootstrap";
import axios from "axios";
import { useUser } from "../../Context/UserContext";
import { Toaster, toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import UyeMenu from "../../Component/UyeMenu";

const Uyefavoriler = () => {
    const { user } = useUser();
    const [favoriler, setFavoriler] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = localStorage.getItem("api_token");

    useEffect(() => {
        fetchFavoriler();
    }, []);

    const fetchFavoriler = async () => {
        if (!user || !user.id) return;

        try {
            const response = await axios.post("https://panel.pahaliyaalma.com/api/uye/favoriler", {
                uye_id: user.id,
            },{
                headers: {
                    Authorization: `${token}`, // **"Bearer " ekledik!**
                },
            });

            setFavoriler(response.data);
        } catch (err) {
            setError("Favori ürünler yüklenirken bir hata oluştu.");
        } finally {
            setLoading(false);
        }
    };

    const handleFavoriKaldir = async (urunId) => {
        if (!window.confirm("Bu ürünü favorilerinizden kaldırmak istediğinize emin misiniz?")) return;

        try {
            await axios.post("https://panel.pahaliyaalma.com/api/uye/favorisil", {
                uye_id: user.id,
                urun_id: urunId,
            },{
                headers: {
                    Authorization: `${token}`, // **"Bearer " ekledik!**
                },
            });

            toast.success("Ürün favorilerden kaldırıldı.");
            fetchFavoriler(); // API'den tekrar veriyi çekiyoruz

        } catch (err) {
            toast.error("Ürün kaldırılırken hata oluştu.");
        }
    };

    return (
        <Container className="mt-5">
            <Helmet>
                <title>Üye Favoriler</title>
            </Helmet>
            <Row>
                <Col md={3}>
                    <UyeMenu />
                </Col>
                <Col md={9}>
                    <h3 className="text-center mb-4">Favorilerim</h3>

                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border"/>
                            <p>Yükleniyor...</p>
                        </div>
                    ) : error ? (
                        <Alert variant="danger" className="text-center">{error}</Alert>
                    ) : favoriler.length > 0 ? (
                        <Row>
                            {favoriler.map((favori) => (
                                <Col md={4} sm={6} xs={12} key={favori.id} className="mb-4">
                                    <Card className="shadow-sm">
                                        <Card.Img
                                            variant="top"
                                            src={`https://panel.pahaliyaalma.com/${favori.urun.resimyol}`} // Güncellendi
                                            alt={favori.urun.baslik}
                                            style={{height: "200px", objectFit: "cover"}}
                                        />
                                        <Card.Body>
                                            <Card.Title className="text-center">{favori.urun.baslik}</Card.Title>
                                            <Card.Text className="text-center text-muted">
                                                {favori.urun.fiyat}₺
                                            </Card.Text>
                                            <div className="d-flex justify-content-between">
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => handleFavoriKaldir(favori.urun.id)}
                                                >
                                                    Kaldır
                                                </Button>
                                                <Button as={Link} to={`/urun/${favori.urun.id}`} variant="primary" size="sm">
                                                    İncele
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <Alert variant="info" className="text-center">Henüz favori eklenmemiş.</Alert>
                    )}

                </Col>
            </Row>

            <Toaster position="top-right" reverseOrder={false}/>

        </Container>
    );
};

export default Uyefavoriler;
