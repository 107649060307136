import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';

export const CacheContext = createContext();

export const CacheProvider = ({ children }) => {
    const [cacheResetFlag, setCacheResetFlag] = useState(false);

    useEffect(() => {
        axios.get("https://panel.pahaliyaalma.com/api/data/cache-durum")
            .then(response => {
                if (response.data.cache_sifirlansin) {
                    // Cache sıfırlama sinyali geldi
                    localStorage.clear(); // Tüm localStorage silinir
                    setCacheResetFlag(true);
                }
            })
            .catch(err => {
                console.error("Cache kontrol hatası:", err);
            });
    }, []);

    return (
        <CacheContext.Provider value={{ cacheResetFlag }}>
            {children}
        </CacheContext.Provider>
    );
};
