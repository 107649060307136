import React, { createContext, useState, useContext, useEffect } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true); // 🔥 Loading eklendi

    // Uygulama açıldığında localStorage'dan kullanıcıyı al
    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        if (storedUser) {
            setUser(storedUser);
        }
        setLoading(false); // 🔥 Yükleme tamamlandı
    }, []);

    // Giriş yapma fonksiyonu
    const login = (userData) => {
        localStorage.setItem("user", JSON.stringify(userData));
        localStorage.setItem("api_token", userData.api_token);
        setUser(userData);
    };

    // Çıkış yapma fonksiyonu
    const logout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("api_token");
        setUser(null);
    };

    return (
        <UserContext.Provider value={{ user, login, logout, loading }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
