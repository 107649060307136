import React, { useState } from "react";
import { Container, Row, Col, Form, Button,Alert } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import "../../style/iletisim.css"; // Sayfaya özel CSS
import axios from "axios";
import ScrollToTopOnMount from "../../Component/ScrollToTopOnMount";
import { useSettings } from '../../Context/SettingsContext'; // Context’ten verileri çek


const Contact = () => {
    const { settings } = useSettings();

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage("");
        setErrorMessage("");

        try {
            const response = await axios.post("https://panel.pahaliyaalma.com/api/message/contact", formData);

            if (response.data.success) {
                setSuccessMessage("Mesajınız başarıyla gönderildi. En kısa sürede size geri dönüş yapacağız.");
                setFormData({ name: "", email: "", phone: "", message: "" });
            } else {
                setErrorMessage("Mesaj gönderilirken bir hata oluştu.");
            }
        } catch (error) {
            setErrorMessage("Bağlantı hatası: Lütfen tekrar deneyin.");
        }
    };

    return (
        <div className="iletisim">
            <ScrollToTopOnMount/>

            <Helmet>
                <title>İletişim</title>
            </Helmet>
            <Container>
                <Row className="iletisim-container pt-5 pb-5">
                    {/* Sol Bölüm: Firma Bilgileri */}
                    <Col md={6} className="iletisim-bilgi">
                        <h2>İLETİŞİM</h2>
                        <ul className="bilgi-listesi">
                            <li><strong>Firma Adı:</strong> Havasis Endüstriyel Ürünler A.Ş.</li>
                            <li><strong>Adres:</strong> {settings?.adres}</li>
                            <li><strong>Telefon:</strong> {settings?.telefon}</li>
                            <li><strong>Mobil Telefon:</strong> 0 (549) 449 35 35</li>
                            <li><strong>E-posta:</strong> {settings?.mail}</li>
                            <li><strong>Vergi Dairesi:</strong> Ege Vergi Dairesi</li>
                            <li><strong>Vergi Numarası:</strong> 4600481520</li>
                        </ul>
                        <h2>BANKA HESAP NUMARALARIMIZ</h2>
                        <div className="banka-bilgisi">
                            <p><strong>AKBANK</strong></p>
                            <p><strong>IBAN: TR370004600428888000209807</strong></p>
                            <p>Hesap Sahibi: Havasis Endüstriyel Ürünler A.Ş.</p>
                        </div>
                        <div className="banka-bilgisi">
                            <p><strong>AKBANK</strong></p>
                            <p><strong>IBAN: TR370004600428888000209807</strong></p>
                            <p>Hesap Sahibi: Havasis Endüstriyel Ürünler A.Ş.</p>
                        </div>
                    </Col>

                    {/* Sağ Bölüm: Form */}
                    <Col md={6} className="iletisim-form">
                        <h2>BİZİMLE İLETİŞİME GEÇİN</h2>


                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="name" className="mb-3">
                                <Form.Label><strong>Adınız Soyadınız</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Adınızı girin"
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="email" className="mb-3">
                                <Form.Label><strong>E-posta Adresiniz</strong></Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="E-posta adresinizi girin"
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="phone" className="mb-3">
                                <Form.Label><strong>Telefon Numaranız</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    placeholder="Telefon numaranızı girin"
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="message" className="mb-3">
                                <Form.Label><strong>Mesajınız</strong></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    rows={4}
                                    placeholder="Mesajınızı buraya yazın"
                                    required
                                />
                            </Form.Group>
                            {successMessage && <Alert variant="success">{successMessage}</Alert>}
                            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                            <Button type="submit" className="w-100 iletisim-gonder">
                                Mesajı Gönder
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Contact;
