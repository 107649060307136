import React, {useEffect, useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaInstagram, FaFacebook, FaYoutube, FaLinkedin, FaTwitter, FaTiktok } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useSettings } from '../Context/SettingsContext';
import axios from "axios"; // Context’ten verileri çek

const Footer = () => {
    const { settings, loading, error } = useSettings();


    // Ortak stiller
    const styles = {
        heading: {
            fontWeight: 'bold',
            marginBottom: '15px',
            color: '#000',
        },
        text: {
            fontSize: '15px',
            color: '#000',
            lineHeight: '1.6',
        },
        link: {
            color: '#000',
            textDecoration: 'none',
            fontSize: '17px',
            lineHeight: '25px',
        },
        borderTop: {
            borderTop: '1px solid #ddd',
        },
        socialIcon: {
            color: '#000',
            fontSize: '1.5em',
        },
    };

    return (
        <footer style={{ backgroundColor: '#ffffff', padding: '60px 0 0 0' }}>
            <Container>
                <Row className="mb-4">
                    {/* Sol taraf: Şirket bilgisi */}
                    <Col md={5}>
                        <h5 style={styles.heading}>PAHALIYAALMA.com</h5>
                        <p className="pe-2" style={styles.text}>
                            Müşteri odaklı yaklaşımımızla, her zaman en iyi hizmeti sunmayı hedefliyoruz.
                            Profesyonel ekibimiz, sizlere en uygun çözümleri sunmak için her an hazırdır.
                        </p>
                        <div>
                            <p style={{ ...styles.text, fontWeight: 'bold', marginBottom: '5px' }}>Bizi Takip Edin</p>

                            {/* Yükleniyor veya hata kontrolü */}
                            {loading ? (
                                <p>Yükleniyor...</p>
                            ) : error ? (
                                <p style={{ color: 'red' }}>{error}</p>
                            ) : (
                                settings && (
                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        {/* Tiktok */}
                                        {settings.tiktok && (
                                            <a href={settings.tiktok} target="_blank" rel="noopener noreferrer">
                                                <FaTiktok style={styles.socialIcon} />
                                            </a>
                                        )}
                                        {/* Instagram */}
                                        {settings.instagram && (
                                            <a href={settings.instagram} target="_blank" rel="noopener noreferrer">
                                                <FaInstagram style={styles.socialIcon} />
                                            </a>
                                        )}
                                        {/* Facebook */}
                                        {settings.facebook && (
                                            <a href={settings.facebook} target="_blank" rel="noopener noreferrer">
                                                <FaFacebook style={styles.socialIcon} />
                                            </a>
                                        )}
                                        {/* Youtube */}
                                        {settings.youtube && (
                                            <a href={settings.youtube} target="_blank" rel="noopener noreferrer">
                                                <FaYoutube style={styles.socialIcon} />
                                            </a>
                                        )}
                                        {/* LinkedIn */}
                                        {settings.linkedin && (
                                            <a href={settings.linkedin} target="_blank" rel="noopener noreferrer">
                                                <FaLinkedin style={styles.socialIcon} />
                                            </a>
                                        )}
                                        {/* Twitter */}
                                        {settings.twitter && (
                                            <a href={settings.twitter} target="_blank" rel="noopener noreferrer">
                                                <FaTwitter style={styles.socialIcon} />
                                            </a>
                                        )}
                                    </div>
                                )
                            )}
                        </div>

                        {/* Telefon Numarası */}
                        <p style={{ ...styles.text, fontWeight: 'bold', marginTop: '15px' }}>
                            {settings?.telefon || '0850 800 33 33'}
                        </p>
                    </Col>

                    {/* Kurumsal */}
                    <Col md={2}>
                        <h5 style={styles.heading}>KURUMSAL</h5>
                        <ul className="list-unstyled">
                            <li>
                                <Link to="/hakkimizda" style={styles.link}>
                                    Hakkımızda
                                </Link>
                            </li>
                            <li>
                                <Link to="/subelerimiz" style={styles.link}>
                                    Şubelerimiz
                                </Link>
                            </li>
                            <li>
                                <Link to="/iletisim" style={styles.link}>
                                    İletişim
                                </Link>
                            </li>
                        </ul>
                    </Col>



                    {/* Hesabım */}
                    <Col md={2}>
                        <h5 style={styles.heading}>HESABIM</h5>
                        <ul className="list-unstyled">
                            <li>
                                <Link to="/uye/panel" style={styles.link}>
                                    Hesabım
                                </Link>
                            </li>
                            <li>
                                <Link to="/uye/siparisler" style={styles.link}>
                                    Siparişlerim
                                </Link>
                            </li>
                            <li>
                                <Link to="/uye/favoriler" style={styles.link}>
                                    Favori Ürünlerim
                                </Link>
                            </li>

                        </ul>
                    </Col>

                    {/* Bilgilendirme */}
                    <Col md={3}>
                        <h5 style={styles.heading}>BİLGİLENDİRME</h5>
                        <ul className="list-unstyled">
                            <li>
                                <Link to="/mesafeli-satis-sozlesmesi" style={styles.link}>
                                    Mesafeli Satış Sözleşmesi
                                </Link>
                            </li>
                            <li>
                                <Link to="/kvkk-bilgilendirme" style={styles.link}>
                                    KVKK Bilgilendirme
                                </Link>
                            </li>
                            <li>
                                <Link to="/teslimat-iade" style={styles.link}>
                                    Teslimat ve İade
                                </Link>
                            </li>
                            <li>
                                <Link to="/gizlilik-politikasi" style={styles.link}>
                                    Gizlilik Politikası
                                </Link>
                            </li>
                            <li>
                                <Link to="/uyelik-sozlesmesi" style={styles.link}>
                                    Üyelik Sözleşmesi
                                </Link>
                            </li>
                            <li>
                                <Link to="/cerez-politikasi" style={styles.link}>
                                    Çerez Politikası
                                </Link>
                            </li>
                        </ul>
                    </Col>
                </Row>

                {/* Alt Kısım */}
                <Row className="pt-3 mb-1" style={styles.borderTop}>
                    {/* Sol Taraf */}
                    <Col md={6} className="text-md-start text-center">
                        <p style={styles.text}>
                            Tasarım & Yazılım AngelWorks
                        </p>
                    </Col>
                    {/* Sağ Taraf */}
                    <Col md={6} className="text-md-end text-center">
                        <p style={styles.text}>
                            {settings?.copyright}

                        </p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
