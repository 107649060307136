import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import React, { useState,useEffect } from 'react';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import Home from "./Home";
import SepetPage from "./Pages/Sepet/SepetPage";
import Faq from "./Pages/Sayfalar/faq";
import Megabar from "./Component/Megabar";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'
import FooterInfo from "./Component/FooterInfo";
import 'react-lazy-load-image-component/src/effects/blur.css';
import UrunlerList from "./Pages/Urunler/UrunlerList";
import Cart from './Component/Cart';
import AppNavbar from "./Component/Navbar";
import Topbar from "./Component/Topbar";
import Footer from "./Component/Footer";
import LoginPage from "./Pages/Uye/LoginPage";
import RegisterPage from "./Pages/Uye/RegisterPage";
import Account from "./Pages/Uye/Account";
import {UserProvider} from "./Context/UserContext";
import ProtectedRoute from "./Component/ProtectedRoute";
import About from "./Pages/Sayfalar/About";
import Contact from "./Pages/Sayfalar/Contact";
import Blog from "./Pages/Sayfalar/Blog";
import { HelmetProvider } from "react-helmet-async";
import UyeEdit from "./Pages/Uye/UyeEdit";
import UyeSiparisler from "./Pages/Uye/Uyesiparisler";
import UyeTalepler from "./Pages/Uye/Uyetalepler";
import SatinAl from "./Pages/Uye/SatinAl";
import TalepAc from "./Pages/Uye/TalepAc";
import UrunDetay from "./Pages/Urunler/UrunDetay";
import UyeSiparisDetay from "./Pages/Uye/UyeSiparisDetay";
import MesafeliSatisPage from "./Pages/Bilgilendirme/MesafeliSatisPage";
import KvkkPage from "./Pages/Bilgilendirme/KvkkPage";
import TeslimatIadePage from "./Pages/Bilgilendirme/TeslimatIadePage";
import UyelikSozlesmesiPage from "./Pages/Bilgilendirme/UyelikSozlesmesiPage";
import GizlilikPage from "./Pages/Bilgilendirme/GizlilikPage";
import CerezPolitikasiPage from "./Pages/Bilgilendirme/CerezPolitikasiPage";
import AramaSonuc from "./Pages/Sayfalar/AramaSonuc";
import Uyeadreslerim from "./Pages/Uye/Uyeadreslerim";
import Uyekuponlar from "./Pages/Uye/Uyekuponlar";
import Uyefavoriler from "./Pages/Uye/Uyefavoriler";
import {SettingsProvider} from "./Context/SettingsContext";
import Uyetalepdetay from "./Pages/Uye/Uyetalepdetay";
import UstFooter from "./Component/UstFooter";
import PaytrPaymentPage from "./Pages/Uye/PaytrPaymentPage";
import SifreUnuttum from "./Pages/Uye/SifreUnuttum";
import SifreSifirla from "./Pages/Uye/SifreSifirla";
import { CacheProvider } from "./Context/CacheContext"; // yeni import
import { useMediaQuery } from 'react-responsive';
import MobilTopbar from "./Component/MobilTopbar";
import MobilMegaNavbar from "./Component/MobilMegaNavbar";
import MobilAppNavbar from "./Component/MobilAppNavbar";
import OdemeOnay from "./Pages/Odeme/OdemeOnay";
import OdemeHata from "./Pages/Odeme/OdemeHata";





function App() {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    const [cartItems, setCartItems] = useState(() => {
        // Local Storage'dan sepeti al
        const savedCart = localStorage.getItem("cartItems");
        return savedCart ? JSON.parse(savedCart) : [];
    });
    const clearCart = () => {
        setCartItems([]); // Sepeti boşalt
    };

    const [showCart, setShowCart] = useState(false);

    // Sepet verilerini kaydet
    useEffect(() => {
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
    }, [cartItems]);

    // Sepete ürün ekleme fonksiyonu
    const handleAddToCart = (product, quantity) => {
        setCartItems((prevItems) => {
            const existingItem = prevItems.find((item) => item.id === product.id);
            if (existingItem) {
                return prevItems.map((item) =>
                    item.id === product.id
                        ? { ...item, quantity: item.quantity + quantity } // Burada artık seçilen miktarı ekliyoruz
                        : item
                );
            }
            return [...prevItems, { ...product, quantity }]; // Yeni ürün eklerken de seçilen miktarı alıyoruz
        });
        setShowCart(true);
    };


    const cartTotal = () => {
        return cartItems.reduce((total, item) => {
            return total + Number(item.fiyat) * item.quantity;
        }, 0); // Başlangıç değeri 0
    };


    // Ürün silme
    const handleRemoveItem = (id) => {
        setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
    };

    // Miktar güncelleme
    const handleUpdateQuantity = (id, quantity) => {
        if (quantity < 1 || isNaN(quantity)) return;
        setCartItems((prevItems) =>
            prevItems.map((item) =>
                item.id === id ? { ...item, quantity } : item
            )
        );
    };
  return (

      <HelmetProvider>
          <SettingsProvider>
              <UserProvider>
                  <CacheProvider>
                      <Router>


                          <div style={{background:'#ffffff'}}>

                              {isMobile ? <MobilTopbar /> : <Topbar />}


                              {isMobile ? <MobilMegaNavbar cartItemCount={cartItems.length} cartTotal={cartTotal} // Sepet ürün sayısı
                                                            onCartClick={() => setShowCart(true)} /> : <Megabar
                                  cartItemCount={cartItems.length} cartTotal={cartTotal} // Sepet ürün sayısı
                                  onCartClick={() => setShowCart(true)} // Sepeti açma
                              />}

                              {isMobile ? <MobilAppNavbar /> : <AppNavbar />}


                              <Cart
                                  cartItems={cartItems}
                                  show={showCart}
                                  onHide={() => setShowCart(false)}
                                  onUpdateQuantity={handleUpdateQuantity}
                                  onRemoveItem={handleRemoveItem}
                              />
                              <Routes>
                                  <Route path="/" element={<Home onAddToCart={handleAddToCart} />} />
                                  <Route
                                      path="/sepet"
                                      element={
                                          <SepetPage
                                              cartItems={cartItems}
                                              onRemoveItem={handleRemoveItem}
                                              onUpdateQuantity={handleUpdateQuantity}
                                          />
                                      }
                                  />

                                  <Route path="/urunler" element={<UrunlerList onAddToCart={handleAddToCart} />} />


                                  <Route path="/kategori/:categorySlug" element={<UrunlerList onAddToCart={handleAddToCart} />} />

                                  <Route path="/arama" element={<AramaSonuc onAddToCart={handleAddToCart} />} />


                                  <Route path="/Urun/:id" element={<UrunDetay onAddToCart={handleAddToCart} />} />

                                  <Route path="/giris-yap" element={<LoginPage />} />
                                  <Route path="/kayit-ol" element={<RegisterPage />} />
                                  <Route path="/sifremi-unuttum" element={<SifreUnuttum />} />
                                  <Route path="/sifre-sifirla" element={<SifreSifirla />} />



                                  <Route
                                      path="/uye/panel"
                                      element={
                                          <ProtectedRoute>
                                              <Account />
                                          </ProtectedRoute>
                                      }
                                  />

                                  <Route
                                      path="/uye/profile"
                                      element={
                                          <ProtectedRoute>
                                              <UyeEdit />
                                          </ProtectedRoute>
                                      }
                                  />



                                  <Route
                                      path="/uye/siparis/:siparisId"
                                      element={
                                          <ProtectedRoute>
                                              <UyeSiparisDetay />
                                          </ProtectedRoute>
                                      }
                                  />

                                  <Route
                                      path="/uye/siparisler"
                                      element={
                                          <ProtectedRoute>
                                              <UyeSiparisler />
                                          </ProtectedRoute>
                                      }
                                  />

                                  <Route
                                      path="/uye/talepler"
                                      element={
                                          <ProtectedRoute>
                                              <UyeTalepler />
                                          </ProtectedRoute>
                                      }
                                  />

                                  <Route
                                      path="/uye/talep-ac"
                                      element={
                                          <ProtectedRoute>
                                              <TalepAc />
                                          </ProtectedRoute>
                                      }
                                  />


                                  <Route
                                      path="/uye/talepler/detay/:talepId"
                                      element={
                                          <ProtectedRoute>
                                              <Uyetalepdetay />
                                          </ProtectedRoute>
                                      }
                                  />

                                  <Route
                                      path="/uye/adreslerim"
                                      element={
                                          <ProtectedRoute>
                                              <Uyeadreslerim />
                                          </ProtectedRoute>
                                      }
                                  />

                                  <Route
                                      path="/uye/kuponlar"
                                      element={
                                          <ProtectedRoute>
                                              <Uyekuponlar />
                                          </ProtectedRoute>
                                      }
                                  />

                                  <Route
                                      path="/uye/favoriler"
                                      element={
                                          <ProtectedRoute>
                                              <Uyefavoriler />
                                          </ProtectedRoute>
                                      }
                                  />

                                  <Route
                                      path="/satinal"
                                      element={
                                          <SatinAl
                                              cartItems={cartItems} clearCart={clearCart}
                                          />
                                      }
                                  />




                                  <Route path="/hakkimizda" element={<About />} />
                                  <Route path="/iletisim" element={<Contact />} />
                                  <Route path="/bloglar" element={<Blog />} />





                                  <Route path="/mesafeli-satis-sozlesmesi" element={<MesafeliSatisPage />} />
                                  <Route path="/kvkk-bilgilendirme" element={<KvkkPage />} />
                                  <Route path="/teslimat-iade" element={<TeslimatIadePage />} />
                                  <Route path="/gizlilik-politikasi" element={<GizlilikPage />} />
                                  <Route path="/uyelik-sozlesmesi" element={<UyelikSozlesmesiPage />} />
                                  <Route path="/cerez-politikasi" element={<CerezPolitikasiPage />} />


                                  <Route path="/odeme/:orderId" element={<PaytrPaymentPage />} />

                                  <Route path="/odeme/odemeonay" element={<OdemeOnay />} />
                                  <Route path="/odeme/hata" element={<OdemeHata />} />



                                  <Route path="/sss" element={<Faq />} />

                              </Routes>
                              <FooterInfo/>
                              <UstFooter/>
                              <Footer/>


                          </div>
                      </Router>

                  </CacheProvider>

              </UserProvider>

          </SettingsProvider>

      </HelmetProvider>


  );
}

export default App;
