import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';

const SubscribeSection = () => {
    const [email, setEmail] = useState(''); // E-posta state'i
    const [successMessage, setSuccessMessage] = useState(''); // Başarı mesajı
    const [errorMessage, setErrorMessage] = useState(''); // Hata mesajı

    const handleSubmit = async (e) => {
        e.preventDefault(); // Sayfanın yenilenmesini engelle

        if (!email) {
            setErrorMessage('Lütfen e-posta adresinizi girin.'); // Boş e-posta kontrolü
            setSuccessMessage(''); // Başarı mesajını temizle
            return;
        }

        try {
            const response = await axios.post('https://panel.pahaliyaalma.com/api/aboneekle', {
                email: email, // Gönderilecek veri
            });

            if (response.status === 200 || response.status === 201) {
                setSuccessMessage('Başarıyla kayıt oldunuz!'); // Başarı mesajını ayarla
                setErrorMessage(''); // Hata mesajını temizle
                setEmail(''); // Input'u temizle
            }
        } catch (error) {
            console.error('Kayıt sırasında bir hata oluştu:', error);
            if (error.response && error.response.data.errors) {
                setErrorMessage(error.response.data.errors.email[0]); // API'den gelen hata mesajını göster
            } else {
                setErrorMessage('Kayıt sırasında bir hata oluştu. Lütfen tekrar deneyin.'); // Genel hata mesajı
            }
            setSuccessMessage(''); // Başarı mesajını temizle
        }
    };

    return (
        <div style={{ backgroundColor: '#f5f5f5', padding: '30px 0', marginTop: '30px', borderTop: '1px solid #ddd' }}>
            <Container>
                <Row className="align-items-center">
                    {/* Sol taraf: Başlık ve açıklama */}
                    <Col md={6} className="text-md-start text-center mb-3 mb-md-0">
                        <h4 style={{ fontWeight: 'bold', marginBottom: '10px' }}>Fırsatları Kaçırmayın!</h4>
                        <p style={{ color: '#666', fontSize: '14px' }}>
                            Mail listemize kayıt olun, yeni fırsatları takip edin!
                        </p>
                    </Col>

                    {/* Sağ taraf: Input ve buton */}
                    <Col md={6}>
                        <Form className="d-flex" onSubmit={handleSubmit}>
                            <Form.Control
                                type="email"
                                placeholder="E-posta adresinizi girin"
                                value={email} // State'i bağla
                                onChange={(e) => setEmail(e.target.value)} // Input değiştiğinde state'i güncelle
                                style={{
                                    borderRadius: '5px 0 0 5px',
                                    border: '1px solid #ccc',
                                    padding: '10px',
                                    fontSize: '14px',
                                    width: '70%',
                                }}
                            />
                            <Button
                                type="submit"
                                style={{
                                    backgroundColor: '#FF6600',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '0 5px 5px 0',
                                    padding: '10px 20px',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    width: '30%',
                                }}
                            >
                                Kayıt Ol
                            </Button>
                        </Form>

                        {/* Bildirimler */}
                        {successMessage && (
                            <Alert variant="success" className="mt-3">
                                {successMessage}
                            </Alert>
                        )}
                        {errorMessage && (
                            <Alert variant="danger" className="mt-3">
                                {errorMessage}
                            </Alert>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SubscribeSection;