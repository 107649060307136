import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../Context/UserContext";

const ProtectedRoute = ({ children }) => {
    const { user, loading } = useUser(); // Kullanıcı bilgisi ve loading durumu

    // Eğer kullanıcı verisi yükleniyorsa bekleyelim
    if (loading) {
        return <p>Yükleniyor...</p>;
    }

    // Kullanıcı bilgisi gelmemişse giriş sayfasına yönlendir
    if (!user) {
        return <Navigate to="/giris-yap" replace />;
    }

    // Kullanıcı giriş yapmışsa içeriği render et
    return children;
};

export default ProtectedRoute;
