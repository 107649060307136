import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UyeMenu from "../../Component/UyeMenu";
import axios from "axios";
import { useUser } from "../../Context/UserContext";


const TalepAc = () => {
    const [formData, setFormData] = useState({ title: "", description: "" });
    const { user } = useUser();

    const navigate = useNavigate();
    const token = localStorage.getItem("api_token");

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                "https://panel.pahaliyaalma.com/api/uye/talepac",
                {
                    user_id: user.id, // user_id'yi buradan alıyoruz
                    title: formData.title,
                    description: formData.description,
                },  {
                    headers: {
                        Authorization: `${token}`, // **"Bearer " ekledik!**
                    },
                }
            );

            console.log("Talep oluşturuldu:", response.data);
            navigate("/uye/talepler");
        } catch (error) {
            console.error("Talep oluşturulamadı:", error.response?.data || error.message);
            alert("Talep oluşturulurken bir hata oluştu.");
        }
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={3}>
                    <UyeMenu />
                </Col>

                <Col md={9}>
                    <Card className="shadow-sm">
                        <Card.Body>
                            <h5 className="mb-4 text-center">Talep Aç</h5>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="title" className="mb-3">
                                    <Form.Label>Talep Başlığı</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Talep başlığını giriniz"
                                        value={formData.title}
                                        onChange={(e) =>
                                            setFormData({ ...formData, title: e.target.value })
                                        }
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="description" className="mb-3">
                                    <Form.Label>Talep Açıklaması</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        placeholder="Talebinizi açıklayınız"
                                        value={formData.description}
                                        onChange={(e) =>
                                            setFormData({ ...formData, description: e.target.value })
                                        }
                                        required
                                    />
                                </Form.Group>
                                <Button variant="success" type="submit" className="w-100">
                                    Talep Gönder
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default TalepAc;
