import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import { CacheContext } from "../Context/CacheContext";

const MobilFeatureSlider = () => {
    const { cacheResetFlag } = useContext(CacheContext);
    const [sliderData, setSliderData] = useState([]);
    const [sideImages, setSideImages] = useState({
        imageOne: {},
        imageTwo: {},
    });

    const isMobile = window.innerWidth < 768;
    const SLIDER_CACHE_KEY = isMobile ? "sliderdata_mobile" : "sliderdata_desktop";
    const SIDEIMAGE_CACHE_KEY = isMobile ? "sideimage_mobile" : "sideimage_desktop";



    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const cachedSlider = localStorage.getItem(SLIDER_CACHE_KEY);
        const cachedSide = localStorage.getItem(SIDEIMAGE_CACHE_KEY);

        if (cachedSlider && cachedSide && !cacheResetFlag) {
            setSliderData(JSON.parse(cachedSlider));
            setSideImages(JSON.parse(cachedSide));
            setLoading(false);
        } else {
            const fetchData = async () => {
                try {
                    const [sliderRes, sideRes] = await Promise.all([
                        axios.get("https://panel.pahaliyaalma.com/api/data/sliderdata"),
                        axios.get("https://panel.pahaliyaalma.com/api/data/sideimage"),
                    ]);

                    const sliderArray = sliderRes.data.map((item) => ({
                        imagePath: item.resimyol,
                        imagePathMobile: item.resimyol_mobile, // 👈 mobil görseli çekiyoruz
                        link: item.linkyol,
                    }));

                    const sideObj = {
                        imageOne: {
                            url: `https://panel.pahaliyaalma.com/${sideRes.data.sagresimyolbir_mobil}`,
                            alt: sideRes.data.sagresimyolbir_alt,
                            link: sideRes.data.saglinkyolbir,
                        },
                        imageTwo: {
                            url: `https://panel.pahaliyaalma.com/${sideRes.data.sagresimyoliki_mobil}`,
                            alt: sideRes.data.sagresimyoliki_alt,
                            link: sideRes.data.saglinkyoliki,
                        },
                    };

                    setSliderData(sliderArray);
                    setSideImages(sideObj);

                    localStorage.setItem(SLIDER_CACHE_KEY, JSON.stringify(sliderArray));
                    localStorage.setItem(SIDEIMAGE_CACHE_KEY, JSON.stringify(sideObj));
                } catch (error) {
                    console.error("Mobil veri çekme hatası:", error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [cacheResetFlag]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };

    if (loading) {
        return (
            <div style={{ textAlign: "center", padding: "30px 0" }}>
                <h5>Veriler yükleniyor...</h5>
            </div>
        );
    }

    return (
        <div className="container mt-3">
            <div className="row g-2">
                {/* SLIDER - Kare format */}
                <div className="col-12">
                    <Slider {...settings}>
                        {sliderData.map((item, index) => (
                            <div key={index}>
                                <a href={item.link}>
                                    <img
                                        src={`https://panel.pahaliyaalma.com/${item.imagePathMobile}`}
                                        alt={`Slider Resmi ${index + 1}`}
                                        style={{
                                            width: "100%",
                                            aspectRatio: "16 / 12", // ya da "4 / 3"
                                            objectFit: "cover",
                                            borderRadius: "5px",
                                        }}
                                    />
                                </a>
                            </div>
                        ))}
                    </Slider>
                </div>

                {/* SAĞ GÖRSELLER - Yanyana kare */}
                <div className="col-6">
                    <a href={sideImages.imageOne.link || "#"} rel="noreferrer">
                        <img
                            src={sideImages.imageOne.url}
                            alt={sideImages.imageOne.alt || "Resim"}
                            style={{
                                width: "100%",
                                aspectRatio: "1 / 1",
                                objectFit: "cover",
                                borderRadius: "5px",
                            }}
                        />
                    </a>
                </div>
                <div className="col-6">
                    <a href={sideImages.imageTwo.link || "#"} rel="noreferrer">
                        <img
                            src={sideImages.imageTwo.url}
                            alt={sideImages.imageTwo.alt || "Resim"}
                            style={{
                                width: "100%",
                                aspectRatio: "1 / 1",
                                objectFit: "cover",
                                borderRadius: "5px",
                            }}
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default MobilFeatureSlider;
