import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FaUser, FaUserPlus,FaUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useUser } from "../Context/UserContext";

const MobilTopbar = () => {
    const { user, logout } = useUser();

    return (
        <div
            style={{
                backgroundColor: "#f0f0f0", // Açık gri zemin
                padding: "10px 0",
                borderBottom: "1px solid #ddd",
            }}
        >
            <Container>
                <Row className="justify-content-center text-center g-1">
                    {user ? (
                        <>
                            <Col xs={6} className="pt-2">
                                <span style={{ fontWeight: "bold", color: "#000" }}>
                                    {user.name}
                                </span>
                            </Col>
                            <Col xs={6}>
                                <Button
                                    size="sm"
                                    variant="danger"
                                    onClick={logout}
                                    style={{ borderRadius: "30px", fontWeight: "bold", width: "100%" }}
                                >
                                    Çıkış Yap
                                </Button>
                            </Col>
                        </>
                    ) : (
                        <>

                            <Col xs={4}>
                                <Link to="/giris-yap">
                                    <Button
                                        variant="warning"
                                        size="sm"
                                        style={{
                                            width: "100%",
                                            borderRadius: "20px",
                                            fontWeight: "bold",
                                            color: "#fff",
                                            fontSize:'11px',
                                            padding:'4px 3px',
                                            margin:'0'

                                        }}
                                    >
                                        <FaUserCircle className="me-1" />
                                        Müşteri Girişi
                                    </Button>
                                </Link>
                            </Col>

                            <Col xs={4}>
                                <Link to="/giris-yap">
                                    <Button
                                        variant="warning"
                                        size="sm"
                                        style={{
                                            width: "100%",
                                            borderRadius: "20px",
                                            fontWeight: "bold",
                                            color: "#fff",
                                            fontSize:'11px',
                                            padding:'4px 3px',
                                            margin:'0'



                                        }}
                                    >
                                        <FaUserCircle className="me-1" />
                                        Bayi Girişi
                                    </Button>
                                </Link>
                            </Col>
                            <Col xs={4}>
                                <Link to="/kayit-ol">
                                    <Button
                                        variant="warning"
                                        size="sm"
                                        style={{
                                            width: "100%",
                                            borderRadius: "20px",
                                            fontWeight: "bold",
                                            color: "#fff",
                                            fontSize:'11px',
                                            padding:'4px 3px',
                                            margin:'0'



                                        }}
                                    >
                                        <FaUserCircle className="me-1" />
                                        Hesap Oluştur
                                    </Button>
                                </Link>
                            </Col>
                        </>
                    )}
                </Row>
            </Container>
        </div>
    );
};

export default MobilTopbar;
