import React, {useContext, useEffect, useState} from "react";
import Slider from "react-slick";
import axios from "axios";
import { CacheContext } from "../Context/CacheContext"; // ekle


const FeatureSlider = () => {
    const { cacheResetFlag } = useContext(CacheContext); // al
    const [sliderData, setSliderData] = useState([]);
    const [sideImages, setSideImages] = useState({
        imageOne: {},
        imageTwo: {},
    });
    const [loading, setLoading] = useState(true); // Tek bir loading durumu

    const isMobile = window.innerWidth < 768;
    const SLIDER_CACHE_KEY = isMobile ? "sliderdata_mobile" : "sliderdata_desktop";
    const SIDEIMAGE_CACHE_KEY = isMobile ? "sideimage_mobile" : "sideimage_desktop";

    useEffect(() => {
        const cachedSlider = localStorage.getItem(SLIDER_CACHE_KEY);
        const cachedSide = localStorage.getItem(SIDEIMAGE_CACHE_KEY);

        if (cachedSlider && cachedSide && !cacheResetFlag) {
            // Cache'den yükle
            setSliderData(JSON.parse(cachedSlider));
            setSideImages(JSON.parse(cachedSide));
            setLoading(false);
        } else {
            // Cache yoksa veya Laravel cache sıfırlamışsa API'den çek
            const fetchData = async () => {
                try {
                    const [sliderRes, sideRes] = await Promise.all([
                        axios.get("https://panel.pahaliyaalma.com/api/data/sliderdata"),
                        axios.get("https://panel.pahaliyaalma.com/api/data/sideimage"),
                    ]);

                    const sliderArray = sliderRes.data.map((item) => ({
                        imagePath: item.resimyol,
                        link: item.linkyol,
                    }));

                    const sideObj = {
                        imageOne: {
                            url: `https://panel.pahaliyaalma.com/${sideRes.data.sagresimyolbir}`,
                            alt: sideRes.data.sagresimyolbir_alt,
                            link: sideRes.data.saglinkyolbir,
                        },
                        imageTwo: {
                            url: `https://panel.pahaliyaalma.com/${sideRes.data.sagresimyoliki}`,
                            alt: sideRes.data.sagresimyoliki_alt,
                            link: sideRes.data.saglinkyoliki,
                        },
                    };

                    // State'lere yükle
                    setSliderData(sliderArray);
                    setSideImages(sideObj);

                    // Cache'e yaz
                    localStorage.setItem(SLIDER_CACHE_KEY, JSON.stringify(sliderArray));
                    localStorage.setItem(SIDEIMAGE_CACHE_KEY, JSON.stringify(sideObj));
                } catch (error) {
                    console.error("Veri çekme hatası:", error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [cacheResetFlag]); // cacheResetFlag değişirse veri yeniden çekilsin


    // Slider ayarları
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };

    if (loading) {
        // Tek seferde tüm veriler gelene kadar sadece bu renderlanır.
        return (
            <div style={{ textAlign: "center", padding: "50px 0" }}>
                {/* Burada isterseniz bir spinner veya skeleton kullanabilirsiniz */}
                <h5>Veriler yükleniyor...</h5>
            </div>
        );
    }

    return (
        <div className="container mt-4">
            <div className="row g-0"> {/* Tüm padding boşluklarını kaldır */}
                {/* SLIDER - Sol kısım */}
                <div className="col-md-8 pe-1">
                    <Slider {...settings}>
                        {sliderData.map((item, index) => (
                            <div key={index}>
                                <a href={item.link}>
                                    <img
                                        src={`https://panel.pahaliyaalma.com/${item.imagePath}`}
                                        alt={`Slider Resmi ${index + 1}`}
                                        className="slider-img slider-main"
                                    />
                                </a>
                            </div>
                        ))}
                    </Slider>
                </div>


                <div className="col-md-4 ps-1">
                    <div className="row g-1">
                        {/* Üst Görsel */}
                        <div className="col-12 mb-2">
                            <a href={sideImages.imageOne.link || "#"} rel="noreferrer">
                                <img
                                    src={sideImages.imageOne.url}
                                    alt={sideImages.imageOne.alt || "Resim"}
                                    className="slider-img side-img"
                                />
                            </a>
                        </div>

                        {/* Alt Görsel */}
                        <div className="col-12">
                            <a href={sideImages.imageTwo.link || "#"} rel="noreferrer">
                                <img
                                    src={sideImages.imageTwo.url}
                                    alt={sideImages.imageTwo.alt || "Resim"}
                                    className="slider-img side-img"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeatureSlider;
