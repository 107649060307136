import React from "react";

const CustomStylishMenu = ({ onClick, color = "#000", size = 35 }) => {
    return (
        <svg
            onClick={onClick}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: "pointer" }}
        >
            <g id="Menu / Menu_Alt_03">
                <path
                    d="M5 17H13M5 12H19M5 7H13"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default CustomStylishMenu;
