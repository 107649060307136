import React from "react";
import { Offcanvas, Button, ListGroup, Form, Image, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Cart = ({ cartItems, show, onHide, onUpdateQuantity, onRemoveItem }) => {
    const navigate = useNavigate();

    // Fiyatları Türk Lirası (binlik . ve ondalık ,) formatında göstermek için yardımcı fonksiyon
    const formatCurrency = (amount) => {
        // Ondalıklı gösterim için aşağıdaki gibi min/max fraction değerleri 2 yapılabilir.
        // Eğer sadece binlik ayırma istiyorsanız minFraction 0, maxFraction 0 da yapabilirsiniz.
        return amount.toLocaleString("tr-TR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) + "₺";
    };

    // Ara toplamı hesaplayan fonksiyon
    const calculateTotal = () =>
        cartItems.reduce(
            (total, item) => total + Number(item.fiyat) * (item.quantity || 1),
            0
        );

    return (
        <Offcanvas
            show={show}
            onHide={onHide}
            placement="end"
            style={{
                backgroundColor: "#ffffff",
                color: "#333",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            }}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title style={{ fontWeight: "bold", fontSize: "20px" }}>
                    Alışveriş Sepeti
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {cartItems.length === 0 ? (
                    <p style={{ textAlign: "center", fontSize: "16px", color: "#666" }}>
                        Sepetiniz boş.
                    </p>
                ) : (
                    <>
                        <ListGroup variant="flush">
                            {cartItems.map((item, index) => (
                                <ListGroup.Item
                                    key={index}
                                    style={{
                                        padding: "15px",
                                        marginBottom: "10px",
                                        borderRadius: "8px",
                                        backgroundColor: "#f8f9fa",
                                        border: "1px solid #ddd",
                                    }}
                                >
                                    <Row className="align-items-center">
                                        <Col xs={3}>
                                            <Image
                                                src={`https://panel.pahaliyaalma.com/${item.resimyol}`}
                                                alt={item.baslik}
                                                rounded
                                                style={{
                                                    width: "100%",
                                                    height: "auto",
                                                    objectFit: "contain",
                                                    borderRadius: "5px",
                                                    border: "1px solid #ddd",
                                                }}
                                            />
                                        </Col>
                                        <Col xs={9}>
                                            <h6 style={{ fontWeight: "bold", marginBottom: "5px" }}>
                                                {item.baslik}
                                            </h6>
                                            <p
                                                style={{
                                                    fontSize: "14px",
                                                    color: "#102a60",
                                                    fontWeight: 500,
                                                    marginBottom: "5px",
                                                }}
                                            >
                                                Fiyat: {formatCurrency(Number(item.fiyat))}
                                            </p>
                                            <p
                                                style={{
                                                    fontSize: "14px",
                                                    color: "#102a60",
                                                    fontWeight: 500,
                                                    marginBottom: "5px",
                                                }}
                                            >
                                                Toplam: {formatCurrency(Number(item.fiyat) * (item.quantity || 1))}
                                            </p>
                                            <div
                                                className="d-flex align-items-center mt-2"
                                                style={{ gap: "10px" }}
                                            >
                                                <Button
                                                    style={{
                                                        backgroundColor: "#f8f9fa",
                                                        color: "#333",
                                                        border: "1px solid #ddd",
                                                    }}
                                                    size="sm"
                                                    onClick={() =>
                                                        onUpdateQuantity(
                                                            item.id,
                                                            Math.max(1, (item.quantity || 1) - 1)
                                                        )
                                                    }
                                                >
                                                    -
                                                </Button>
                                                <Form.Control
                                                    type="text"
                                                    value={item.quantity || 1}
                                                    readOnly
                                                    style={{
                                                        width: "50px",
                                                        textAlign: "center",
                                                        border: "1px solid #ddd",
                                                        borderRadius: "5px",
                                                    }}
                                                />
                                                <Button
                                                    style={{
                                                        backgroundColor: "#f8f9fa",
                                                        color: "#333",
                                                        border: "1px solid #ddd",
                                                    }}
                                                    size="sm"
                                                    onClick={() =>
                                                        onUpdateQuantity(item.id, (item.quantity || 1) + 1)
                                                    }
                                                >
                                                    +
                                                </Button>
                                                <Button
                                                    style={{
                                                        backgroundColor: "#ff4d4d",
                                                        color: "#fff",
                                                        border: "none",
                                                    }}
                                                    size="sm"
                                                    onClick={() => onRemoveItem(item.id)}
                                                >
                                                    Sil
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                        <div
                            className="mt-4"
                            style={{
                                padding: "10px",
                                borderRadius: "10px",
                                backgroundColor: "#f8f9fa",
                                border: "1px solid #ddd",
                            }}
                        >
                            <h5 style={{ textAlign: "end", fontWeight: "bold", color: "#333" }}>
                                Ara Toplam: {formatCurrency(calculateTotal())}
                            </h5>
                            <div className="d-flex flex-column gap-2 mt-3">
                                <Button
                                    style={{
                                        backgroundColor: "#007bff",
                                        color: "#fff",
                                        fontWeight: "bold",
                                        border: "none",
                                    }}
                                    onClick={() => {
                                        navigate("/sepet");
                                        onHide();
                                    }}
                                >
                                    Sepetim
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: "#28a745",
                                        color: "#fff",
                                        fontWeight: "bold",
                                        border: "none",
                                    }}
                                    onClick={onHide}
                                >
                                    Alışverişe Devam Et
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default Cart;
