import React from "react";
import { Container, Row, Col, Nav, Button } from "react-bootstrap";
import { FaUser, FaUserPlus } from "react-icons/fa";
import { useUser } from "../Context/UserContext";
import { Link } from "react-router-dom";

const Topbar = () => {
    const { user, logout } = useUser();

    return (
        <div className="topbar" style={{ color: "#000000", padding: "5px 0" }}>
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} className="">
                        <Nav className="topbar-menu">
                            <Link
                                to="/"
                                style={{ color: "#000", marginRight: "10px", fontWeight: 500 }}
                            >
                                Anasayfa
                            </Link>
                            <Link
                                to="/hakkimizda"
                                style={{ color: "#000", marginRight: "10px", fontWeight: 500 }}
                            >
                                Hakkımızda
                            </Link>
                            <Link
                                to="/iletisim"
                                style={{ color: "#000", marginRight: "10px", fontWeight: 500 }}
                            >
                                İletişim
                            </Link>
                        </Nav>
                    </Col>

                    <Col
                        xs={12}
                        md={6}
                        className="d-flex align-items-center justify-content-center justify-content-md-end"
                    >
                        {user ? (
                            // Giriş Yapmış Kullanıcı
                            <>
                                <Link
                                    to="/uye/panel"
                                    style={{
                                        marginRight: "10px",
                                        fontWeight: "bold",
                                        textDecoration: "none",
                                        color: "#000000",
                                    }}
                                >
                                    {user.name}
                                </Link>
                                <Button
                                    className="topbar-button topbar-logout"
                                    variant="danger"
                                    size="sm"
                                    onClick={logout}
                                >
                                    Çıkış Yap
                                </Button>
                            </>
                        ) : (
                            // Misafir Kullanıcı (Henüz giriş yapmamış)
                            <>
                                <Link to="/giris-yap" style={{ color: "#000", marginRight: "8px" }}>
                                    <Button
                                        className="topbar-button topbar-login"
                                        variant="outline-light"
                                        size="sm"
                                    >
                                        <FaUser className="button-icon" />
                                        Müşteri Girişi
                                    </Button>
                                </Link>

                                <Link to="/giris-yap" style={{ color: "#000", marginRight: "8px" }}>
                                    <Button
                                        className="topbar-button topbar-login"
                                        variant="outline-light"
                                        size="sm"
                                    >
                                        <FaUser className="button-icon" />
                                        Bayi Girişi
                                    </Button>
                                </Link>

                                <Link to="/kayit-ol" style={{ color: "#000" }}>
                                    <Button
                                        className="topbar-button topbar-signup"
                                        variant="light"
                                        size="sm"
                                    >
                                        <FaUserPlus className="button-icon" />
                                        Hesap Oluştur
                                    </Button>
                                </Link>
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Topbar;
