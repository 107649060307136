import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Table, Button, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Sayfa yönlendirme için
import UyeMenu from "../../Component/UyeMenu";
import axios from "axios";
import { useUser } from "../../Context/UserContext"; // Kullanıcı bağlamını dahil ediyoruz

const UyeTalepler = () => {
    const navigate = useNavigate(); // Yönlendirme işlemleri için
    const { user } = useUser(); // Kullanıcı bilgilerini bağlamdan al
    const [loading, setLoading] = useState(true); // Yükleme durumu için state
    const [error, setError] = useState(null); // Hata durumu için state
    const [talepler, setTalepler] = useState([]); // Talepleri saklamak için state
    const token = localStorage.getItem("api_token");

    // Talep statüsüne göre Badge döndüren yardımcı fonksiyon
    const renderStatuBadge = (statu) => {
        switch (parseInt(statu)) {
            case 1:
                return <Badge bg="warning">Bekliyor</Badge>;
            case 2:
                return <Badge bg="success">Yanıtlandı</Badge>;
            case 4:
                return <Badge bg="danger">Kapatıldı</Badge>;
            default:
                return <Badge bg="secondary">Bilinmiyor</Badge>;
        }
    };

    useEffect(() => {
        const fetchOrders = async () => {
            if (!user || !user.id) {
                setError("Kullanıcı giriş yapmamış.");
                setLoading(false);
                return;
            }

            try {
                // API'ye kullanıcı kimliğiyle isteği gönder
                const response = await axios.post("https://panel.pahaliyaalma.com/api/uye/talepler", {
                    user_id: user.id, // Kullanıcı kimliği gönderiliyor
                },{
                    headers: {
                        Authorization: `${token}`, // **"Bearer " ekledik!**
                    },
                });

                setTalepler(response.data.orders); // API'den gelen talepleri kaydet
                setLoading(false);
            } catch (err) {
                setError("Talepler yüklenirken bir hata oluştu.");
                setLoading(false);
            }
        };

        fetchOrders();
    }, [user]);

    if (loading) {
        return (
            <Container className="mt-5">
                <Row>
                    <Col className="text-center">
                        <p>Yükleniyor...</p>
                    </Col>
                </Row>
            </Container>
        );
    }

    if (error) {
        return (
            <Container className="mt-5">
                <Row>
                    <Col className="text-center">
                        <p style={{ color: "red" }}>{error}</p>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container className="mt-5">
            <Row>
                {/* Sol Menü */}
                <Col md={3}>
                    <UyeMenu />
                </Col>

                {/* Talep Tablosu */}
                <Col md={9}>
                    <Card className="shadow-sm">
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <h5 className="text-center">Taleplerim</h5>
                                <Button
                                    variant="success"
                                    onClick={() => navigate("/uye/talep-ac")} // Talep açma formuna yönlendirme
                                    style={{ fontWeight: "bold" }}
                                >
                                    Talep Aç
                                </Button>
                            </div>
                            {talepler && talepler.length > 0 ? (
                                <Table striped bordered hover responsive>
                                    <thead>
                                    <tr>
                                        <th>Talep ID</th>
                                        <th>Konu</th>
                                        <th>Durum</th>
                                        <th>Detay</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {talepler.map((talep, index) => (
                                        <tr key={index}>
                                            <td>{talep.id}</td>
                                            <td>{talep.talepadi}</td>
                                            <td>{renderStatuBadge(talep.statu)}</td>
                                            <td>
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    onClick={() => navigate(`/uye/talepler/detay/${talep.id}`)}
                                                >
                                                    Detay
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p className="text-center text-muted">
                                    Henüz talebiniz bulunmamaktadır.
                                </p>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default UyeTalepler;
