import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Table, Button, Spinner, Modal, Form } from "react-bootstrap";
import axios from "axios";
import UyeMenu from "../../Component/UyeMenu";
import { useUser } from "../../Context/UserContext";
import toast, { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";
import useToasterMessage from "../../hooks/useToasterMessage";
import data from "../../data/data.json"; // JSON dosyasını doğru şekilde içe aktar

const Uyeadreslerim = () => {
    const { user } = useUser();
    const [adresler, setAdresler] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [editingAdres, setEditingAdres] = useState(null);
    const [adresBilgi, setAdresBilgi] = useState({ tipi: "", adres: "" });
    const [iller, setIller] = useState([]);
    const [ilceler, setIlceler] = useState([]);
    const [secilenIl, setSecilenIl] = useState("");
    const [secilenIlce, setSecilenIlce] = useState("");
    useToasterMessage();

    const token = localStorage.getItem("api_token");

    useEffect(() => {
        fetchAdresler();
        fetchIller(); // İller ve ilçeler verisini yükle
    }, [user]);

    const fetchAdresler = async () => {
        if (!user || !user.id) return;

        try {
            const response = await axios.get(
                `https://panel.pahaliyaalma.com/api/uye/adresler/${user.id}`,
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                }
            );

            if (response.data.status === 200) {
                setAdresler(response.data.adresler);
            } else {
                toast.error(response.data.message);
            }
        } catch (err) {
            toast.error("Adresler yüklenirken bir hata oluştu.");
        } finally {
            setLoading(false);
        }
    };

    const fetchIller = () => {
        try {
            setIller(data); // JSON dosyasındaki veriyi doğrudan state'e atayın
        } catch (error) {
            console.error("Veri yüklenirken hata oluştu:", error);
        }
    };

    // İl değiştiğinde ilçeleri yükle
    useEffect(() => {
        if (secilenIl) {
            const secilenIlObj = iller.find((il) => il.il === secilenIl);
            if (secilenIlObj) {
                setIlceler(secilenIlObj.ilceleri);
            } else {
                setIlceler([]);
            }
        } else {
            setIlceler([]);
        }
    }, [secilenIl, iller]);

    const handleAdresKaydet = async () => {
        if (!adresBilgi.tipi || !secilenIl || !secilenIlce || !adresBilgi.adres) {
            toast.error("Lütfen tüm alanları doldurun.");
            return;
        }

        const adresData = {
            tipi: adresBilgi.tipi,
            sehir: secilenIl,
            ilce: secilenIlce,
            adres: adresBilgi.adres,
        };

        try {
            if (editingAdres) {
                await axios.post(
                    "https://panel.pahaliyaalma.com/api/uye/adres-guncelle",
                    {
                        id: editingAdres.id,
                        ...adresData,
                    },
                    {
                        headers: {
                            Authorization: `${token}`,
                        },
                    }
                );
                toast.success("Adres başarıyla güncellendi.");
            } else {
                await axios.post(
                    "https://panel.pahaliyaalma.com/api/uye/adres-ekle",
                    {
                        uye_id: user.id,
                        ...adresData,
                    },
                    {
                        headers: {
                            Authorization: `${token}`,
                        },
                    }
                );
                toast.success("Adres başarıyla eklendi.");
            }

            fetchAdresler();
            setModalShow(false);
            setAdresBilgi({ tipi: "", adres: "" });
            setSecilenIl("");
            setSecilenIlce("");
            setEditingAdres(null);
        } catch (err) {
            toast.error("İşlem sırasında bir hata oluştu.");
        }
    };

    const handleAdresSil = async (id) => {
        if (!window.confirm("Bu adresi silmek istediğinizden emin misiniz?")) return;

        try {
            await axios.post(
                "https://panel.pahaliyaalma.com/api/uye/adres-sil",
                { id },
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                }
            );
            toast.success("Adres başarıyla silindi.");
            fetchAdresler();
        } catch (err) {
            toast.error("Silme işlemi sırasında hata oluştu.");
        }
    };

    return (
        <Container className="mt-5">
            <Helmet>
                <title>Adreslerim</title>
            </Helmet>
            <Row>
                <Col md={3}>
                    <UyeMenu />
                </Col>
                <Col md={9}>
                    <Card className="shadow-sm">
                        <Card.Body>
                            <h5 className="mb-4 text-center">Adreslerim</h5>
                            {loading ? (
                                <div className="text-center">
                                    <Spinner animation="border" role="status" />
                                    <p>Yükleniyor...</p>
                                </div>
                            ) : (
                                <>
                                    <Button
                                        variant="success"
                                        className="mb-3"
                                        onClick={() => {
                                            setModalShow(true);
                                            setEditingAdres(null);
                                            setAdresBilgi({ tipi: "", adres: "" });
                                            setSecilenIl("");
                                            setSecilenIlce("");
                                        }}
                                    >
                                        + Yeni Adres Ekle
                                    </Button>
                                    {adresler.length > 0 ? (
                                        <Table striped bordered hover responsive>
                                            <thead>
                                            <tr>
                                                <th>Tip</th>
                                                <th>Şehir</th>
                                                <th>İlçe</th>
                                                <th>Adres</th>
                                                <th>İşlemler</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {adresler.map((adres) => (
                                                <tr key={adres.id}>
                                                    <td>{adres.tipi}</td>
                                                    <td>{adres.sehir}</td>
                                                    <td>{adres.ilce}</td>
                                                    <td>{adres.adres}</td>
                                                    <td>
                                                        <Button
                                                            variant="warning"
                                                            size="sm"
                                                            className="me-2"
                                                            onClick={() => {
                                                                setEditingAdres(adres);
                                                                setAdresBilgi({
                                                                    tipi: adres.tipi,
                                                                    adres: adres.adres,
                                                                });
                                                                setSecilenIl(adres.sehir);
                                                                const secilenIlObj = iller.find(
                                                                    (il) => il.il === adres.sehir
                                                                );
                                                                setIlceler(secilenIlObj ? secilenIlObj.ilceleri : []);
                                                                setSecilenIlce(adres.ilce);
                                                                setModalShow(true);
                                                            }}
                                                        >
                                                            Düzenle
                                                        </Button>
                                                        <Button
                                                            variant="danger"
                                                            size="sm"
                                                            onClick={() => handleAdresSil(adres.id)}
                                                        >
                                                            Sil
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    ) : (
                                        <p className="text-center text-muted">Henüz adres eklenmemiş.</p>
                                    )}
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{editingAdres ? "Adres Güncelle" : "Yeni Adres Ekle"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Adres Tipi</Form.Label>
                            <Form.Control
                                type="text"
                                value={adresBilgi.tipi}
                                onChange={(e) => setAdresBilgi({ ...adresBilgi, tipi: e.target.value })}
                                placeholder="Ev, İş, Yazlık vb."
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Şehir</Form.Label>
                            <Form.Control
                                as="select"
                                value={secilenIl}
                                onChange={(e) => {
                                    const secilenIlAdi = e.target.value;
                                    setSecilenIl(secilenIlAdi);
                                    setSecilenIlce("");
                                }}
                            >
                                <option value="">İl seçiniz</option>
                                {iller.map((il, index) => (
                                    <option key={index} value={il.il}>
                                        {il.il}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>İlçe</Form.Label>
                            <Form.Control
                                as="select"
                                value={secilenIlce}
                                onChange={(e) => setSecilenIlce(e.target.value)}
                                disabled={!secilenIl}
                            >
                                <option value="">İlçe seçiniz</option>
                                {ilceler.map((ilce, index) => (
                                    <option key={index} value={ilce}>
                                        {ilce}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Adres</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={adresBilgi.adres}
                                onChange={(e) => setAdresBilgi({ ...adresBilgi, adres: e.target.value })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleAdresKaydet}>
                        {editingAdres ? "Güncelle" : "Ekle"}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Toaster position="top-right" reverseOrder={false} />
        </Container>
    );
};

export default Uyeadreslerim;