import React from "react";
import { Container, Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const OdemeOnay = () => {
    return (
        <Container className="mt-5 text-center">
            <Alert variant="success">
                <h2>✅ Ödeme Başarılı!</h2>
                <p>Siparişiniz başarıyla alındı. Teşekkür ederiz!</p>
                <Link to="/uye/siparisler">
                    <Button variant="primary">Siparişlerimi Görüntüle</Button>
                </Link>
            </Alert>
        </Container>
    );
};

export default OdemeOnay;
