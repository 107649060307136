import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Form,
    Button,
    Table,
    Nav,
    Alert,
} from "react-bootstrap";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom"; // Dikkat: useLocation import edildi
import { Helmet } from "react-helmet";
import { useUser } from "../../Context/UserContext";
import toast from "react-hot-toast";
import data from "../../data/data.json"; // JSON dosyasını doğru şekilde içe aktar


const Satinal = ({ cartItems, clearCart }) => {
    const { user, login } = useUser();
    const navigate = useNavigate();

    // BURADA: useLocation ile state üzerinden kuponBilgi alıyoruz
    const location = useLocation();
    const kuponBilgi = location.state?.kuponBilgi || null;
    const [iller, setIller] = useState([]);
    const [ilceler, setIlceler] = useState([]);
    const [secilenIl, setSecilenIl] = useState("");
    const [secilenIlce, setSecilenIlce] = useState("");

    useEffect(() => {
        // İlleri yükle
        setIller(data);
    }, []);
    useEffect(() => {
        if (secilenIl) {
            const secilenIlObj = iller.find((il) => il.il === secilenIl);
            if (secilenIlObj) {
                setIlceler(secilenIlObj.ilceleri);
            } else {
                setIlceler([]);
            }
        } else {
            setIlceler([]);
        }
    }, [secilenIl, iller]);
    // ------------------
    // 1) State: Auth
    // ------------------
    const [activeTab, setActiveTab] = useState("giris-yap");
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        name: "",
        confirmPassword: "",
    });
    const [loginError, setLoginError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false);

    // ------------------
    // 2) State: Adres Yönetimi
    // ------------------
    // Kullanıcının kayıtlı adresleri
    const [userAddresses, setUserAddresses] = useState([]);
    // Teslimat adresi seçimi (ID)
    const [selectedDeliveryAddressId, setSelectedDeliveryAddressId] = useState("");

    // Yeni teslimat adresi ekleme
    const [showNewDeliveryForm, setShowNewDeliveryForm] = useState(false);
    const [prevSelectedAddressId, setPrevSelectedAddressId] = useState("");

    const [newDeliveryData, setNewDeliveryData] = useState({
        adresTanimi: "Teslimat",
        tipi: "Ev",
        sehir: "",
        ilce: "",
        adres: "",
    });

    function handleToggleNewAddress() {
        if (!showNewDeliveryForm) {
            setPrevSelectedAddressId(selectedDeliveryAddressId);
            // 2) Radio seçimini boşalt
            setSelectedDeliveryAddressId("");
            // 3) Formu aç
            setShowNewDeliveryForm(true);
        } else {
            // Form açıksa "Vazgeç" butonuna basıldı demektir
            // 1) Eski seçili adresi geri yükle
            setSelectedDeliveryAddressId(prevSelectedAddressId);
            // 2) Artık bu "prev" state’i sıfırlayabiliriz
            setPrevSelectedAddressId("");
            // 3) Formu kapat
            setShowNewDeliveryForm(false);
        }
    }

    // ------------------
    // 3) Fatura Adresi
    // ------------------
    // Fatura Adresi varsayılan olarak “Teslimat Adresi” ile aynı gelir
    const [faturaAyniMi, setFaturaAyniMi] = useState(true); // true => aynı
    const [faturaData, setFaturaData] = useState({
        adresTanimi: "Fatura",
        tipi: "Ev",
        sehir: "",
        ilce: "",
        adres: "",
    });

    // ------------------
    // 4) Kargo & Ödeme
    // ------------------
    const [kargoSecenekleri, setKargoSecenekleri] = useState([]);
    const [selectedKargo, setSelectedKargo] = useState("");
    const [kargoUcreti, setKargoUcreti] = useState(0);
    const [selectedOdeme, setSelectedOdeme] = useState("kredi_karti");
    const [selectedHesap, setSelectedHesap] = useState("");
    const [referansKodu, setReferansKodu] = useState("");

    const [hesapBilgileri, setHesapBilgileri] = useState([]);
    const token = localStorage.getItem("api_token");


    useEffect(() => {
        axios
            .get("https://panel.pahaliyaalma.com/api/data/hesaplar")
            .then((response) => {
                setHesapBilgileri(response.data);
            })
            .catch((error) => {
                console.error("Hata oluştu:", error);
            });
    }, []);

    useEffect(() => {
        axios.get("https://panel.pahaliyaalma.com/api/data/kargolar")
            .then((response) => {
                const formattedKargolar = response.data.map((kargo) => ({
                    id: `kargo${kargo.id}`,
                    name: kargo.baslik,
                    teslimatSuresi: kargo.teslimat_suresi,
                    fiyat: parseFloat(kargo.fiyat),
                    resimYol: `https://panel.pahaliyaalma.com/${kargo.resimyol}`

                }));
                setKargoSecenekleri(formattedKargolar);

                // İlk kargoyu otomatik seç
                if (formattedKargolar.length > 0) {
                    setSelectedKargo(formattedKargolar[0].name);
                    setKargoUcreti(formattedKargolar[0].fiyat);
                }
            })
            .catch((error) => {
                console.error("Kargo verileri alınamadı:", error);
            });
    }, []);

    useEffect(() => {
        // kargoSecenekleri API'den geldi veya statik olabilir
        if (kargoSecenekleri.length > 0 && selectedKargo === "") {
            // Henüz bir seçim yoksa, ilk elemanı seç
            setSelectedKargo(kargoSecenekleri[0].name);
            setKargoUcreti(kargoSecenekleri[0].fiyat);
        }
    }, [kargoSecenekleri, selectedKargo]);

    // ------------------
    // useEffect: Kullanıcının adreslerini çek
    // ------------------



    useEffect(() => {
        const fetchAdresler = async () => {
            if (!user || !user.id) return;

            try {
                const response = await axios.get(
                    `https://panel.pahaliyaalma.com/api/uye/adresler/${user.id}`,
                    {
                        headers: {
                            Authorization: `${token}`,
                        },
                    }
                );

                if (response.data.status === 200) {
                    setUserAddresses(Array.isArray(response.data.adresler) ? response.data.adresler : []);
                } else {
                    console.error("Adresler yüklenirken hata: ", response.data.message);
                }
            } catch (err) {
                console.error("Adresler alınamadı:", err);
            }
        };

        fetchAdresler(); // async fonksiyonu çağırıyoruz
    }, [user]);



    useEffect(() => {
        // userAddresses API'den geldiğinde eğer henüz seçili adres yoksa ilkini seç
        if (userAddresses.length > 0 && selectedDeliveryAddressId === "") {
            setSelectedDeliveryAddressId(userAddresses[0].id);
        }
    }, [userAddresses, selectedDeliveryAddressId]);

    // ------------------
    // Rastgele referans kodu
    // ------------------
    const generateReferansKodu = () => {
        return Math.random().toString(36).substr(2, 6).toUpperCase();
    };

    // ------------------
    //  AUTH Fonksiyonları
    // ------------------
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                "https://panel.pahaliyaalma.com/api/uye/userLogin",
                {
                    email: formData.email,
                    password: formData.password,
                }
            );
            const { status, user } = response.data;
            if (status === 200) {
                if (user.api_token) {
                    localStorage.setItem("api_token", user.api_token);
                    axios.defaults.headers.common["Authorization"] = `Bearer ${user.api_token}`;
                }


                login(user);
                setLoginError("");
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setLoginError("Yanlış kullanıcı adı veya şifre.");
            } else {
                setLoginError("Sunucuya bağlanılamadı. Lütfen daha sonra tekrar deneyin.");
            }
        }
    };

    const handleregister = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            setErrorMessage("Şifreler uyuşmuyor.");
            return;
        }
        try {
            // 1) Kayıt
            await axios.post("https://panel.pahaliyaalma.com/api/uye/store", {
                name: formData.name,
                email: formData.email,
                password: formData.password,
            });
            // 2) Başarılı kayıt sonrası otomatik giriş
            const loginResponse = await axios.post(
                "https://panel.pahaliyaalma.com/api/uye/userLogin",
                {
                    email: formData.email,
                    password: formData.password,
                }
            );
            const { user } = loginResponse.data;
            if (user.api_token) {
                localStorage.setItem("api_token", user.api_token);
            }
            login(user);
            setSuccessMessage("Kayıt başarılı, otomatik giriş yapıldı!");
            setLoginError("");
        } catch (error) {
            setErrorMessage(
                error.response?.data?.message || "Bir hata oluştu. Lütfen tekrar deneyin."
            );
        }
    };

    // ------------------
    // Form Handler'lar
    // ------------------
    // Giriş/Kayıt
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Yeni teslimat adresi
    const handleNewDeliveryChange = (e) => {
        const { name, value } = e.target;
        setNewDeliveryData({ ...newDeliveryData, [name]: value });
    };

    // Fatura formu
    const handleFaturaChange = (e) => {
        const { name, value } = e.target;
        setFaturaData({ ...faturaData, [name]: value });
    };

    // Kargo seçimi
    const handleKargoChange = (e) => {
        const val = e.target.value;
        setSelectedKargo(val);
        const foundKargo = kargoSecenekleri.find((k) => k.name === val);
        setKargoUcreti(foundKargo ? foundKargo.fiyat : 0);
    };

    // Ödeme seçimi
    const handleOdemeChange = (e) => {
        const val = e.target.value;
        setSelectedOdeme(val);
        setSelectedHesap("");
        if (val === "havale") {
            setReferansKodu(generateReferansKodu());
        } else {
            setReferansKodu("");
        }
    };

    const handleHesapChange = (e) => {
        setSelectedHesap(e.target.value);
    };

    // ------------------
    // Sepet Hesaplamaları
    // ------------------
    const subTotal = cartItems.reduce(
        (total, item) => total + Number(item.fiyat) * item.quantity,
        0
    );

    // Kupon indirimi hesaplama
    const calculateCouponDiscount = () => {
        if (!kuponBilgi) return 0;
        if (kuponBilgi.indirim_orani) {
            return subTotal * kuponBilgi.indirim_orani; // Yüzdelik indirim
        } else if (kuponBilgi.indirim_miktar) {
            return kuponBilgi.indirim_miktar; // Sabit miktar indirimi
        }
        return 0;
    };

    const kuponIndirimi = calculateCouponDiscount();
    const totalAfterDiscount = subTotal - kuponIndirimi;

    const kdvOrani = 0.2; // %20
    const kdvTutar = subTotal * kdvOrani;
    const finalTotal = totalAfterDiscount + kdvTutar + kargoUcreti;



    const handleCompleteOrder = async () => {
        if (!user) {
            alert("Lütfen giriş yapın!");
            return;
        }

        setLoading(true);
        try {
            let deliveryAddressData = null;
            let foundHesap = null;

            // 1️⃣ Eğer ödeme yöntemi havale ise seçilen hesabı bul
            if (selectedOdeme === "havale" && selectedHesap) {
                foundHesap = hesapBilgileri.find(
                    (h) => h.id === parseInt(selectedHesap, 10)
                );
            }

            // 2️⃣ Kullanıcının seçtiği teslimat adresini belirle
            if (showNewDeliveryForm && newDeliveryData.adres.trim() !== "") {
                const responseAddr = await axios.post(
                    "https://panel.pahaliyaalma.com/api/uye/adres-ekle",
                    {
                        uye_id: user.id,
                        tipi: newDeliveryData.tipi,
                        sehir: newDeliveryData.sehir,
                        ilce: newDeliveryData.ilce,
                        adres: newDeliveryData.adres,
                    },
                    {
                        headers: { Authorization: `${token}` },
                    }
                );

                if (responseAddr.data.success) {
                    const createdAddress = responseAddr.data.adres;
                    setUserAddresses((prev) => [...prev, createdAddress]);
                    deliveryAddressData = createdAddress;
                } else {
                    deliveryAddressData = { ...newDeliveryData };
                }
            } else {
                deliveryAddressData = userAddresses.find(
                    (a) => a.id === selectedDeliveryAddressId
                );
            }

            // 3️⃣ Fatura adresini belirle
            let invoiceAddressData = faturaAyniMi
                ? { ...deliveryAddressData, adresTanimi: "Fatura (Teslimatla Aynı)" }
                : { ...faturaData };

            // 4️⃣ Seçilen kargoyu belirle
            const selectedKargoObj = kargoSecenekleri.find(
                (k) => k.name === selectedKargo
            );

            // 5️⃣ Ödeme yöntemine göre değer belirleme
            let paymentMethodValue = selectedOdeme === "kredi_karti" ? 2
                : selectedOdeme === "havale" ? 1
                    : 3;

            // 6️⃣ Sipariş oluşturma isteğini gönder
            const payload = {
                user_id: user.id,
                email: user.email,
                payment_method: paymentMethodValue,
                total_amount: finalTotal,
                sub_total: subTotal,
                kupon_indirimi: kuponIndirimi || 0,
                kdv_tutar: kdvTutar,
                kargo_ucreti: kargoUcreti,
                kargo_id: selectedKargoObj ? selectedKargoObj.id : null,
                kargo_adi: selectedKargoObj ? selectedKargoObj.name : null,
                items: cartItems.map((item) => ({
                    product_id: item.id,
                    name: item.baslik,
                    price: item.fiyat,
                    quantity: item.quantity,
                })),
                delivery_address: deliveryAddressData,
                invoice_address: invoiceAddressData,
                referans_kodu: referansKodu,
                selected_havale_account: foundHesap
                    ? foundHesap.baslik + "-" + foundHesap.icerik
                    : "",
            };

            const response = await axios.post(
                "https://panel.pahaliyaalma.com/api/uye/siparis-olustur",
                payload,
                {
                    headers: { Authorization: `${token}` },
                }
            );

            if (response.status === 200) {
                const orderId = response.data.order_id; // Sipariş ID'si

                // 7️⃣ Eğer ödeme yöntemi kredi kartı ise PayTR iframe sayfasına yönlendir
                if (selectedOdeme === "kredi_karti") {
                    navigate(`/odeme/${orderId}`);
                    return; // Ödeme sayfasına yönlendirildi, işlemi burada durdur
                }

                clearCart();
                navigate("/uye/siparisler");
            } else {
                alert("Siparişiniz oluşturulamadı. Lütfen tekrar deneyin.");
            }
        } catch (error) {
            console.error("Sipariş/Ödeme işlemi hatası:", error);
            alert("İşlem sırasında hata oluştu.");
        } finally {
            setLoading(false);
        }
    };

    // ------------------
    // RENDER
    // ------------------
    return (
        <Container className="mt-5">
            <Helmet>
                <title>Satın Al</title>
            </Helmet>
            <Row>
                {/* SOL KISIM: Giriş & Adres */}
                <Col md={5}>
                    {/* Kullanıcı Yok => Giriş / Kayıt */}
                    {!user ? (
                        <Card className="shadow-sm mb-4">
                            <Card.Header>
                                <Nav
                                    variant="tabs"
                                    activeKey={activeTab}
                                    onSelect={(key) => setActiveTab(key)}
                                >
                                    <Nav.Item>
                                        <Nav.Link eventKey="giris-yap">Giriş Yap</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="kayit-ol">Kayıt Ol</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Card.Header>
                            <Card.Body>
                                {activeTab === "giris-yap" && (
                                    <>
                                        <h5 className="mb-3">Hesabınıza Giriş Yapın</h5>
                                        {loginError && <Alert variant="danger">{loginError}</Alert>}
                                        <Form onSubmit={handleLogin}>
                                            <Form.Group className="mb-3" controlId="loginEmail">
                                                <Form.Label>E-Posta</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="E-posta adresinizi girin"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="loginPassword">
                                                <Form.Label>Şifre</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Şifrenizi girin"
                                                    name="password"
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                            <Button type="submit" variant="primary" className="w-100">
                                                Giriş Yap
                                            </Button>
                                        </Form>
                                    </>
                                )}
                                {activeTab === "kayit-ol" && (
                                    <>
                                        <h5 className="mb-3">Hesap Oluşturun</h5>
                                        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                                        {successMessage && <Alert variant="success">{successMessage}</Alert>}
                                        <Form onSubmit={handleregister}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>Ad Soyad</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Adınızı girin"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="email">
                                                <Form.Label>E-Posta</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="E-posta adresinizi girin"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="password">
                                                <Form.Label>Şifre</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Şifrenizi girin"
                                                    name="password"
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="confirmPassword">
                                                <Form.Label>Şifre Tekrar</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Şifrenizi tekrar girin"
                                                    name="confirmPassword"
                                                    value={formData.confirmPassword}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                            <Button type="submit" variant="success" className="w-100">
                                                Kayıt Ol
                                            </Button>
                                        </Form>
                                    </>
                                )}
                            </Card.Body>
                        </Card>
                    ) : (
                        <>
                            <Card className="shadow-sm mb-4">
                                <Card.Body>
                                    <h5 className="mb-3">Hoş Geldiniz, {user.name}!</h5>
                                </Card.Body>
                            </Card>

                            {/* Teslimat Adresi Seçimi */}
                            <Card className="mb-4 shadow">
                                <Card.Header className="bg-light text-dark">
                                    <h5 className="mb-0">Teslimat Adresi</h5>
                                </Card.Header>
                                <Card.Body>
                                    {userAddresses.length === 0 ? (
                                        <Alert variant="warning">Kayıtlı adresiniz bulunmuyor.</Alert>
                                    ) : (
                                        <>
                                            <p>Mevcut adreslerinizden birini seçin:</p>
                                            {userAddresses.map((addr) => (
                                                <Form.Check
                                                    key={addr.id}
                                                    type="radio"
                                                    id={`deliver-addr-${addr.id}`}
                                                    name="deliveryAddress"
                                                    label={`${addr.tipi} - ${addr.ilce}/${addr.sehir}/${addr.adres}`}
                                                    value={addr.id}
                                                    checked={selectedDeliveryAddressId === addr.id}
                                                    onChange={() => setSelectedDeliveryAddressId(addr.id)}
                                                    className="mb-2"
                                                />
                                            ))}
                                        </>
                                    )}

                                    <Button
                                        variant="outline-primary"
                                        onClick={handleToggleNewAddress}
                                        className="mt-3"
                                    >
                                        {showNewDeliveryForm ? "Vazgeç" : "Yeni Adres Ekle"}
                                    </Button>

                                    {showNewDeliveryForm && (
                                        <div className="mt-3">
                                            <Row>
                                                <Col md={12}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Adres Başlığı (Ev, İş vb.)</Form.Label>
                                                        <Form.Control
                                                            name="tipi"
                                                            value={newDeliveryData.tipi}
                                                            onChange={handleNewDeliveryChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Şehir</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            name="sehir"
                                                            value={newDeliveryData.sehir}
                                                            onChange={(e) => {
                                                                const secilenIlAdi = e.target.value;
                                                                setNewDeliveryData({ ...newDeliveryData, sehir: secilenIlAdi });
                                                                setSecilenIl(secilenIlAdi);
                                                                setSecilenIlce("");
                                                            }}
                                                        >
                                                            <option value="">İl seçiniz</option>
                                                            {iller.map((il, index) => (
                                                                <option key={index} value={il.il}>
                                                                    {il.il}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>

                                                    <Form.Group className="mb-3">
                                                        <Form.Label>İlçe</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            name="ilce"
                                                            value={newDeliveryData.ilce}
                                                            onChange={(e) => {
                                                                setNewDeliveryData({ ...newDeliveryData, ilce: e.target.value });
                                                                setSecilenIlce(e.target.value);
                                                            }}
                                                            disabled={!secilenIl}
                                                        >
                                                            <option value="">İlçe seçiniz</option>
                                                            {ilceler.map((ilce, index) => (
                                                                <option key={index} value={ilce}>
                                                                    {ilce}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Adres</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={2}
                                                    name="adres"
                                                    value={newDeliveryData.adres}
                                                    onChange={handleNewDeliveryChange}
                                                />
                                            </Form.Group>
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>

                            {/* Fatura Adresi */}
                            <Card className="mb-4 shadow">
                                <Card.Header className="bg-light text-dark">
                                    <h5 className="mb-0">Fatura Adresi</h5>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Check
                                        type="radio"
                                        id="faturaAyniSecimAyni"
                                        label="Teslimat Adresiyle Aynı"
                                        name="faturaAyniSecim"
                                        checked={faturaAyniMi}
                                        onChange={() => setFaturaAyniMi(true)}
                                        className="mb-2"
                                        style={{ cursor: "pointer" }}
                                    />

                                    <Form.Check
                                        type="radio"
                                        id="faturaAyniSecimFarkli"
                                        label="Farklı Fatura Adresi Girmek İstiyorum"
                                        name="faturaAyniSecim"
                                        checked={!faturaAyniMi}
                                        onChange={() => setFaturaAyniMi(false)}
                                        className="mb-3"
                                        style={{ cursor: "pointer" }}
                                    />

                                    {/* Eğer farklı seçildiyse fatura formu görünsün */}
                                    {!faturaAyniMi && (
                                        <div>
                                            <Row>
                                                <Col md={12}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Adres Tipi (Ev, İş)</Form.Label>
                                                        <Form.Control
                                                            name="tipi"
                                                            value={faturaData.tipi}
                                                            onChange={handleFaturaChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Şehir</Form.Label>
                                                        <Form.Control
                                                            name="sehir"
                                                            value={faturaData.sehir}
                                                            onChange={handleFaturaChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>İlçe</Form.Label>
                                                        <Form.Control
                                                            name="ilce"
                                                            value={faturaData.ilce}
                                                            onChange={handleFaturaChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Adres</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={2}
                                                            name="adres"
                                                            value={faturaData.adres}
                                                            onChange={handleFaturaChange}
                                                            placeholder="Fatura adresi.."
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>
                        </>
                    )}
                </Col>

                {/* SAĞ KISIM: Kargo, Ödeme, Sepet Özeti */}
                <Col md={7}>
                    {/* Kargo */}
                    <Card className="mb-4 shadow">
                        <Card.Header className="bg-light text-dark">
                            <h5 className="mb-0">Kargo Seçimi</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                {kargoSecenekleri.length > 0 ? (
                                    kargoSecenekleri.map((kargo) => (
                                        <Col md={6} key={kargo.id}>
                                            <Card className={`mb-3 shadow-sm ${selectedKargo === kargo.name ? 'border-primary' : ''}`}>
                                                <Card.Body className="d-flex align-items-center">
                                                    <input
                                                        type="radio"
                                                        id={kargo.id}
                                                        name="kargoSecim"
                                                        value={kargo.name}
                                                        checked={selectedKargo === kargo.name}
                                                        onChange={handleKargoChange}
                                                        style={{ display: "none" }}
                                                    />
                                                    <label htmlFor={kargo.id} style={{ cursor: "pointer", display: "flex", alignItems: "center", width: "100%" }}>
                                                        <img
                                                            src={kargo.resimYol}
                                                            alt={kargo.name}
                                                            style={{ width: '140px', objectFit: 'contain', marginRight: '5px' }}
                                                        />
                                                        <div>
                                                            <h6 className="mb-1 fw-bold">{kargo.name}</h6>
                                                            <p className="mb-1" style={{ fontSize: '14px', color: '#555' }}>{kargo.teslimatSuresi}</p>
                                                            <p className="fw-bold">{kargo.fiyat}₺</p>
                                                        </div>
                                                    </label>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))
                                ) : (
                                    <p>Kargo seçenekleri yükleniyor...</p>
                                )}
                            </Row>
                        </Card.Body>
                    </Card>

                    {/* Ödeme Yöntemi */}
                    <Card className="mb-4 shadow">
                        <Card.Header className="bg-light text-dark">
                            <h5 className="mb-0">Ödeme Yöntemi Seçimi</h5>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <Row className="mb-4">
                                    <Col md={4}>
                                    <Form.Check
                                            type="radio"
                                            id="odeme-kredi-karti"
                                            label="Kredi Kartı 💳"
                                            name="odemeYontemi"
                                            value="kredi_karti"
                                            checked={selectedOdeme === "kredi_karti"}
                                            onChange={handleOdemeChange}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </Col>

                                    <Col md={4}>
                                        <Form.Check
                                            type="radio"
                                            id="odeme-havale"
                                            label="Havale/EFT 🏦"
                                            name="odemeYontemi"
                                            value="havale"
                                            checked={selectedOdeme === "havale"}
                                            onChange={handleOdemeChange}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </Col>

                                    {user && user.bakiye > 0 && (
                                        <Col md={4}>
                                            <Form.Check
                                                type="radio"
                                                id="odeme-bakiye"
                                                label={`Bakiyem ile Ödeme (${user.bakiye}₺)`}
                                                name="odemeYontemi"
                                                value="bakiye"
                                                checked={selectedOdeme === "bakiye"}
                                                onChange={handleOdemeChange}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </Col>
                                    )}
                                </Row>

                                {selectedOdeme === "havale" && (
                                    <>
                                        <Alert variant="info">
                                            Lütfen aşağıdaki hesaplardan birini seçin ve ödeme açıklamasına{" "}
                                            <strong>{referansKodu}</strong> referans kodunu eklemeyi unutmayın.
                                        </Alert>

                                        <Form.Group controlId="hesapBilgisi" className="mb-3">
                                            <Form.Label>Hesap Bilgileri</Form.Label>
                                            <Form.Select value={selectedHesap} onChange={handleHesapChange}>
                                                <option value="">Hesap Seçiniz</option>
                                                {hesapBilgileri.map((h) => (
                                                    <option key={h.id} value={h.id}>
                                                        {h.baslik} - {h.icerik}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                        {selectedHesap && (
                                            <Alert variant="success">
                                                <strong>Seçilen Hesap:</strong> <br />
                                                Banka:{" "}
                                                {
                                                    hesapBilgileri.find((x) => x.id === parseInt(selectedHesap))
                                                        ?.baslik
                                                }
                                                <br />
                                                IBAN:{" "}
                                                {
                                                    hesapBilgileri.find((x) => x.id === parseInt(selectedHesap))
                                                        ?.icerik
                                                }
                                            </Alert>
                                        )}
                                    </>
                                )}
                            </Form>
                        </Card.Body>
                    </Card>

                    {/* Sepet Özeti */}
                    <Card className="shadow-sm mb-4">
                        <Card.Header className="bg-light text-dark">
                            <h5 className="mb-0">Sepet Özeti</h5>
                        </Card.Header>
                        <Card.Body>
                            {cartItems.length === 0 ? (
                                <p className="text-center" style={{ fontSize: "18px", color: "#666" }}>
                                    Sepetiniz boş.
                                </p>
                            ) : (
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>Ürün Adı</th>
                                        <th>Birim Fiyatı</th>
                                        <th>Adet</th>
                                        <th>Toplam</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {cartItems.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.baslik}</td>
                                            <td>{item.fiyat} TL</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.fiyat * item.quantity} TL</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            )}

                            {/* Ara Toplam, KDV, Kargo, İndirim (varsa), Genel Toplam */}
                            <div className="d-flex justify-content-between mt-2">
                                <strong>Ara Toplam:</strong>
                                <span>{subTotal.toFixed(2)}₺</span>
                            </div>
                            <div className="d-flex justify-content-between mt-2">
                                <strong>KDV (%20):</strong>
                                <span>{kdvTutar.toFixed(2)}₺</span>
                            </div>
                            <div className="d-flex justify-content-between mt-2">
                                <strong>Kargo Ücreti:</strong>
                                <span>{kargoUcreti.toFixed(2)}₺</span>
                            </div>

                            {kuponIndirimi > 0 && (
                                <div className="d-flex justify-content-between mt-2" style={{ color: "green" }}>
                                    <strong>İndirim:</strong>
                                    <span>-{kuponIndirimi}₺</span>
                                </div>
                            )}

                            <hr />
                            <div className="d-flex justify-content-between mt-2">
                                <strong>Toplam:</strong>
                                <span style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                  {finalTotal.toFixed(2)}₺
                </span>
                            </div>
                        </Card.Body>
                    </Card>

                    <Button
                        className="w-100 mt-4 gradient-button"
                        style={{ fontSize: "1.2rem", fontWeight: "bold", borderRadius: "30px" }}
                        onClick={handleCompleteOrder}
                        disabled={loading || cartItems.length === 0}
                    >
                        {loading ? "Sipariş İşleniyor..." : "Siparişi Tamamla"}
                    </Button>
                </Col>
            </Row>

            <style jsx>{`
        .custom-tabs .nav-link {
          font-size: 1rem;
          font-weight: bold;
          color: #6c757d;
          border: none;
          background: none;
        }
        .custom-tabs .nav-link.active {
          color: #1e1e1e;
          border-top: 1px solid rgba(0, 123, 255, 0.37);
          border-left: 1px solid rgba(0, 123, 255, 0.37);
          border-right: 1px solid rgba(0, 123, 255, 0.37);
          padding: 10px 20px;
          line-height: 20px;
        }
        .gradient-button {
          background: linear-gradient(45deg, #007bff, #0056b3);
          color: white;
          border: none;
          transition: all 0.3s ease;
        }
        .gradient-button:hover {
          background: linear-gradient(45deg, #0056b3, #004080);
        }
      `}</style>
        </Container>
    );
};

export default Satinal;
