import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col, Card, Form, Button, Alert } from "react-bootstrap";

const SifreSifirla = () => {
    const [searchParams] = useSearchParams(); // URL'deki token'ı almak için
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const token = searchParams.get("token"); // URL'den "token" parametresini al

    useEffect(() => {
        if (!token) {
            setErrorMessage("Geçersiz veya eksik bağlantı.");
        }
    }, [token]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorMessage("");
        setSuccessMessage("");

        if (password !== confirmPassword) {
            setErrorMessage("Şifreler uyuşmuyor.");
            setIsLoading(false);
            return;
        }

        try {
            const response = await axios.post("https://panel.pahaliyaalma.com/api/uye/sifre-sifirla", {
                token: token,
                password: password,
                password_confirmation: confirmPassword
            });

            if (response.data.status === 200) {
                setSuccessMessage("Şifreniz başarıyla sıfırlandı. Giriş sayfasına yönlendiriliyorsunuz...");
                setTimeout(() => navigate("/giris-yap"), 3000); // 3 saniye sonra giriş sayfasına yönlendir
            } else {
                setErrorMessage(response.data.message);
            }
        } catch (error) {
            setErrorMessage("Bir hata oluştu, lütfen tekrar deneyin.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container>
            <Row className="justify-content-center mmt-5 mb-5">
                <Col md={6}>
                    <Card className="p-4 shadow-lg rounded">
                        <h3 className="text-center mb-4">Yeni Şifre Belirle</h3>

                        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                        {successMessage && <Alert variant="success">{successMessage}</Alert>}

                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="password">
                                <Form.Label>Yeni Şifre</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Yeni şifrenizi girin"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="confirmPassword" className="mt-3">
                                <Form.Label>Yeni Şifreyi Tekrar Girin</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Yeni şifrenizi tekrar girin"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Button type="submit" variant="primary" className="w-100 mt-3" disabled={isLoading}>
                                {isLoading ? "İşlem Yapılıyor..." : "Şifreyi Güncelle"}
                            </Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default SifreSifirla;
