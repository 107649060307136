import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Banner from "../../Component/Banner";

const BlogList = () => {
    // Örnek blog verileri
    const blogs = [
        {
            id: 1,
            title: "React ile Web Geliştirme",
            summary: "React kullanarak modern ve dinamik web uygulamaları nasıl geliştirilir?",
            image: "https://via.placeholder.com/300x150",
        },
        {
            id: 2,
            title: "JavaScript İleri Düzey Teknikler",
            summary: "JavaScript'in karmaşık yapılarını öğrenerek daha etkili kod yazabilirsiniz.",
            image: "https://via.placeholder.com/300x150",
        },
        {
            id: 3,
            title: "Frontend ve Backend Arasındaki Farklar",
            summary: "Frontend ve Backend geliştirme süreçlerini öğrenin.",
            image: "https://via.placeholder.com/300x150",
        },
    ];

    return (
        <div>
            <Helmet>
                <title>Blog</title>
            </Helmet>
            <Banner title="Bloglar"/>

            <Container className="mt-5">

                <Row className="justify-content-center">
                    <Col md={8}>
                        <h1 className="text-center mb-4">Blog</h1>
                        <p className="text-center mb-4">
                            En son blog yazılarımıza göz atın ve teknoloji dünyasındaki gelişmeleri takip edin.
                        </p>
                    </Col>
                </Row>
                <Row>
                    {blogs.map((blog) => (
                        <Col md={4} className="mb-4" key={blog.id}>
                            <Card className="shadow-lg">
                                <Card.Img variant="top" src={blog.image} alt={blog.title} />
                                <Card.Body>
                                    <Card.Title>{blog.title}</Card.Title>
                                    <Card.Text>{blog.summary}</Card.Text>
                                    <Link to={`/blog/${blog.id}`}>
                                        <Button variant="primary">Devamını Oku</Button>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>

        </div>
    );
};

export default BlogList;
