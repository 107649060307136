import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Button,
    Badge,
    Form,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useUser } from "../../Context/UserContext";
import {Helmet} from "react-helmet";
import UyeMenu from "../../Component/UyeMenu";

function UyeTalepDetay() {
    const { talepId } = useParams(); // URL param: /uye/talepler/detay/:talepId
    const { user } = useUser(); // Giriş yapmış kullanıcı bilgisi
    const navigate = useNavigate();

    const [talep, setTalep] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Yeni mesaj göndermek için state
    const [newMessage, setNewMessage] = useState("");
    const token = localStorage.getItem("api_token");

    // 1) Talep detayını ve mesajları çek
    useEffect(() => {
        if (!user || !user.id) {
            setError("Kullanıcı giriş yapmamış.");
            setLoading(false);
            return;
        }

        const fetchTalepDetay = async () => {
            try {
                const res = await axios.get(
                    `https://panel.pahaliyaalma.com/api/uye/talep-detay/${talepId}`,{
                        headers: {
                            Authorization: `${token}`, // **"Bearer " ekledik!**
                        },
                    }
                );
                setTalep(res.data.talep);
                setLoading(false);
            } catch (err) {
                setError("Talep detayı yüklenirken hata oluştu.");
                setLoading(false);
            }
        };

        fetchTalepDetay();
    }, [talepId, user]);

    // 2) Talep statüsüne göre Badge
    const renderStatuBadge = (statu) => {
        switch (parseInt(statu, 10)) {
            case 1:
                return <Badge bg="warning">Bekliyor</Badge>;
            case 2:
                return <Badge bg="success">Yanıtlandı</Badge>;
            case 4:
                return <Badge bg="danger">Kapatıldı</Badge>;
            default:
                return <Badge bg="secondary">Bilinmiyor</Badge>;
        }
    };

    // Daha okunabilir tarih formatı
    const formatDate = (dateStr) => {
        if (!dateStr) return "";
        // Örnek format: 25.01.2025 17:16
        const d = new Date(dateStr);
        return d.toLocaleString("tr-TR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        });
    };

    // 3) Yeni mesaj gönder
    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim()) return;

        try {
            await axios.post("https://panel.pahaliyaalma.com/api/uye/talep-mesaj-gonder", {
                talep_id: talepId,
                icerik: newMessage,
            },{
                headers: {
                    Authorization: `${token}`, // **"Bearer " ekledik!**
                },
            });
            // Mesaj gönderildikten sonra input'u temizle, talebi yeniden çek
            setNewMessage("");
            reloadTalepDetay();
        } catch (err) {
            alert("Mesaj gönderimi sırasında hata oluştu!");
        }
    };

    // Talebi yeniden yükleme fonksiyonu (mesaj gönderdikten sonra güncel listeyi görmek için)
    const reloadTalepDetay = async () => {
        setLoading(true);
        try {
            const res = await axios.get(
                `https://panel.pahaliyaalma.com/api/uye/talep-detay/${talepId}`,{
                    headers: {
                        Authorization: `${token}`, // **"Bearer " ekledik!**
                    },
                }
            );
            setTalep(res.data.talep);
            setLoading(false);
        } catch (err) {
            setError("Talep detayı yenilenirken hata oluştu.");
            setLoading(false);
        }
    };

    // 4) Talebi kapat
    const handleCloseTalep = async () => {
        if (!window.confirm("Talebi kapatmak istediğinizden emin misiniz?")) return;
        try {
            await axios.post("https://panel.pahaliyaalma.com/api/uye/talep-kapat", {
                talep_id: talepId,
            },{
                headers: {
                    Authorization: `${token}`, // **"Bearer " ekledik!**
                },
            });
            alert("Talep kapatıldı.");
            reloadTalepDetay();
        } catch (err) {
            alert("Talep kapatma sırasında hata oluştu!");
        }
    };

    // Yükleniyor
    if (loading) {
        return (
            <Container className="mt-4">
                <p>Yükleniyor...</p>
            </Container>
        );
    }

    // Hata
    if (error) {
        return (
            <Container className="mt-4">
                <p style={{ color: "red" }}>{error}</p>
            </Container>
        );
    }

    // Talep yok
    if (!talep) {
        return (
            <Container className="mt-4">
                <p>Talep bulunamadı.</p>
            </Container>
        );
    }

    return (
        <Container className="mt-4">
            <Helmet>
                <title>Destek Talep Detay</title>
            </Helmet>
            <Row>
                <Col md={3}>
                    <UyeMenu />
                </Col>
                <Col md={9}>
                    {/* T A L E P   B I L G I L E R I */}
                    <Card className="shadow-sm mb-4">
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 style={{ fontWeight: "bold" }}>Talep Detayı</h4>
                                <Button variant="secondary" onClick={() => navigate("/uye/talepler")}>
                                    Geri
                                </Button>
                            </div>
                            <hr />
                            <Row>
                                <Col md={6}>
                                    <p style={{ marginBottom: "8px" }}>
                                        <strong>Konu:</strong> {talep.talepadi}
                                    </p>
                                    <p style={{ marginBottom: "8px" }}>
                                        <strong>Durum:</strong> {renderStatuBadge(talep.statu)}
                                    </p>
                                    <p style={{ marginBottom: "8px" }}>
                                        <strong>Oluşturulma:</strong> {formatDate(talep.created_at)}
                                    </p>
                                </Col>
                                <Col md={6} className="text-end">
                                    {parseInt(talep.statu, 10) !== 4 && (
                                        <Button
                                            variant="danger"
                                            onClick={handleCloseTalep}
                                            style={{ minWidth: "140px" }}
                                        >
                                            Talebi Kapat
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    {/* M E S A J L A R */}
                    <Card className="shadow-sm mb-4">
                        <Card.Header style={{ fontWeight: "500" }}>Mesajlar</Card.Header>
                        <Card.Body>
                            {talep.messages && talep.messages.length > 0 ? (
                                talep.messages.map((msg) => {
                                    const isYonetim = msg.yonetim_mi === "1";

                                    const senderName = isYonetim
                                        ? "Yönetim"
                                        : msg.gonderen?.name
                                            ? `${msg.gonderen.name} ${msg.gonderen.surname || ""}`.trim()
                                            : user?.name || "Bilinmeyen Kullanıcı";

                                    return (
                                        <div
                                            key={msg.id}
                                            style={{
                                                display: "flex",
                                                justifyContent: isYonetim ? "flex-start" : "flex-end",
                                                marginBottom: "20px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "100%",  // Mesaj kutusu genişliği
                                                    border: "1px solid",
                                                    borderColor: isYonetim ? "#adb5bd" : "#28a745",
                                                    padding: "10px",
                                                    borderRadius: "10px",
                                                    backgroundColor: isYonetim ? "#f8f9fa" : "#e9fbe5",
                                                    boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.1)",
                                                    textAlign: "left",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontWeight: "bold",
                                                        color: isYonetim ? "#495057" : "#155724",
                                                        marginBottom: "5px",
                                                    }}
                                                >
                                                    {senderName}{" "}
                                                    <span style={{ fontSize: "0.8rem", color: "#666" }}>
                            ({formatDate(msg.created_at)})
                        </span>
                                                </p>
                                                <p style={{ margin: 0 }}>{msg.icerik}</p>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <p className="text-muted">Henüz mesaj yok.</p>
                            )}
                        </Card.Body>
                    </Card>

                    {/* Y E N I   M E S A J */}
                    {parseInt(talep.statu, 10) !== 4 && (
                        <Card className="shadow-sm">
                            <Card.Header>Yeni Mesaj Gönder</Card.Header>
                            <Card.Body>
                                <Form onSubmit={handleSendMessage}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Mesajınız</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={newMessage}
                                            onChange={(e) => setNewMessage(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Button variant="primary" type="submit">
                                        Gönder
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default UyeTalepDetay;
