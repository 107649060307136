import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner, Card, Button, Alert } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "../../style/urunler.css";


function AramaSonuc({ onAddToCart }) {
    const [arananUrunler, setArananUrunler] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    // URL'deki query parametresini yakala
    // Ör: "/arama?query=elma" => query = "elma"
    const queryParams = new URLSearchParams(location.search);
    const searchTerm = queryParams.get("query") || "";

    useEffect(() => {
        if (!searchTerm) return;

        // Arama yapmak için API isteği
        async function fetchSearchResults() {
            setLoading(true);
            setError(null);

            try {
                // Örn. GET isteği: /api/urun-ara?term=searchTerm
                const response = await fetch(
                    `https://panel.pahaliyaalma.com/api/data/urunara?term=${encodeURIComponent(
                        searchTerm
                    )}`
                );
                if (!response.ok) {
                    throw new Error("Arama isteği başarısız oldu");
                }
                const data = await response.json();

                // Beklenen veri yapısına göre
                // data = { status: 200, products: [...] }
                setArananUrunler(data.products || []);
            } catch (err) {
                setError("Arama sonuçları yüklenirken bir hata oluştu.");
                console.error(err);
            } finally {
                setLoading(false);
            }
        }

        fetchSearchResults();
    }, [searchTerm]);

    const handleAddToCart = (product, e) => {
        e.stopPropagation();
        onAddToCart(product);
    };

    return (
        <Container className="mt-5">
            <h4>Arama Sonuçları - "{searchTerm}"</h4>

            {loading && (
                <div className="text-center my-5">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Yükleniyor...</span>
                    </Spinner>
                </div>
            )}

            {error && (
                <Alert variant="danger">
                    {error}
                </Alert>
            )}

            {!loading && !error && arananUrunler.length === 0 && (
                <Alert variant="warning">
                    Aradığınız ürün bulunamadı.
                </Alert>
            )}

            {!loading && !error && arananUrunler.length > 0 && (
                <Row className="g-3">
                    {arananUrunler.map((product) => (
                        <Col xs={12} sm={6} md={3} key={product.id}>
                            <Card
                                className="text-center shadow-sm p-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate(`/urun/${product.id}`)}
                            >
                                {/* Ürün resmi */}
                                <Card.Img
                                    variant="top"
                                    src={`https://panel.pahaliyaalma.com/${product.resimyol}`}
                                    style={{ height: "170px", objectFit: "scale-down" }}
                                />
                                <Card.Body>
                                    <Card.Title style={{ fontSize: "0.95rem", fontWeight: "500" }}>
                                        {product.baslik}
                                    </Card.Title>
                                    <div className="d-flex justify-content-between mt-3">
                    <span className="text-muted" style={{ textDecoration: "line-through" }}>
                      {product.eski_fiyat}₺
                    </span>
                                        <span style={{ fontWeight: "bold" }}>{product.fiyat}₺</span>
                                    </div>
                                    <Button
                                        className="sepete-ekle-button mt-2"
                                        onClick={(e) => handleAddToCart(product, e)}
                                    >
                                        Sepete Ekle
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            )}
        </Container>
    );
}

export default AramaSonuc;
