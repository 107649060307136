import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card, Alert, Image } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { FaGoogle } from "react-icons/fa";
import "../../style/login.css";
import { useUser } from "../../Context/UserContext";
import loginImage from "../../assets/icons/user_login.png";
import ScrollToTopOnMount from "../../Component/ScrollToTopOnMount";

const RegisterPage = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        // Yeni eklediklerimiz
        membershipType: "bireysel",   // varsayılan “bireysel”
        vergiDairesi: "",
        vergiNumarasi: "",
        firmaAdi: "",
    });

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const { login } = useUser();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    // Radio buton değiştiğinde membershipType güncellenecek
    const handleMembershipChange = (e) => {
        setFormData({
            ...formData,
            membershipType: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Şifreler uyuşuyor mu kontrol
        if (formData.password !== formData.confirmPassword) {
            setErrorMessage("Şifreler uyuşmuyor.");
            return;
        }

        setIsLoading(true);
        try {
            await axios.post("https://panel.pahaliyaalma.com/api/uye/store", {
                name: formData.name,
                email: formData.email,
                password: formData.password,
                membershipType: formData.membershipType,
                vergiDairesi: formData.vergiDairesi,
                vergiNumarasi: formData.vergiNumarasi,
                firmaAdi: formData.firmaAdi,
            });

            // Kayıt başarılı -> otomatik login
            const loginResponse = await axios.post(
                "https://panel.pahaliyaalma.com/api/uye/userLogin",
                {
                    email: formData.email,
                    password: formData.password,
                }
            );

            const { user } = loginResponse.data;
            if (user.api_token) {
                localStorage.setItem("api_token", user.api_token);
            }
            login(user);
            setErrorMessage("");
            setSuccessMessage("Kayıt başarılı, otomatik giriş yapıldı!");

            navigate("/uye/panel", {
                state: { message: "Başarılı kayıt işlemi tamamlandı!", type: "success" },
            });
        } catch (error) {
            setErrorMessage(
                error.response?.data?.message || "Bir hata oluştu. Lütfen tekrar deneyin."
            );
            setSuccessMessage("");
        } finally {
            setIsLoading(false);
        }
    };

    const handleLogin = () => {
        navigate("/giris-yap");
    };

    return (
        <Container className="login-container">
            <ScrollToTopOnMount />
            <Helmet>
                <title>Kayıt Ol</title>
            </Helmet>

            <Row className="justify-content-center align-items-center">
                <Col md={5}>
                    <h3 className="text-center mb-4 login-title">KAYIT OL</h3>

                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    {successMessage && <Alert variant="success">{successMessage}</Alert>}

                    <Form onSubmit={handleSubmit}>
                        <Card className="p-4 shadow-lg rounded login-card">
                            <Card.Body>
                                <div className="text-center mb-4">
                                    <Image src={loginImage} alt="Register Icon" className="login-image" />
                                </div>

                                {/* Üyelik Türü */}
                                <Form.Group className="mb-3">
                                    <Form.Label>Üyelik Türü</Form.Label>
                                    <div>
                                        <Form.Check
                                            type="radio"
                                            name="membershipType"
                                            id="bireysel"
                                            label="Bireysel"
                                            value="bireysel"
                                            checked={formData.membershipType === "bireysel"}
                                            onChange={handleMembershipChange}
                                            inline
                                        />
                                        <Form.Check
                                            type="radio"
                                            name="membershipType"
                                            id="tuzel"
                                            label="Kurumsal"
                                            value="tuzel"
                                            checked={formData.membershipType === "tuzel"}
                                            onChange={handleMembershipChange}
                                            inline
                                        />
                                    </div>
                                </Form.Group>

                                {/* Tüzel seçiliyse ek alanlar */}
                                {formData.membershipType === "tuzel" && (
                                    <>

                                        <Form.Group controlId="firmaAdi" className="mb-3">
                                            <Form.Label>Firma Adı:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="firmaAdi"
                                                placeholder="Firma Adı"
                                                value={formData.firmaAdi}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="vergiDairesi" className="mb-3">
                                            <Form.Label>Vergi Dairesi:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="vergiDairesi"
                                                placeholder="Vergi Dairesi"
                                                value={formData.vergiDairesi}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="vergiNumarasi" className="mb-3">
                                            <Form.Label>Vergi Numarası:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="vergiNumarasi"
                                                placeholder="Vergi Numarası"
                                                value={formData.vergiNumarasi}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Form.Group>

                                    </>
                                )}

                                <Form.Group controlId="name" className="mb-3">
                                    <Form.Label>Ad Soyad:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder="Adınızı ve soyadınızı girin"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="email" className="mb-3">
                                    <Form.Label>E-Posta Adresi:</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        placeholder="E-posta adresinizi girin"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="password" className="mb-3">
                                    <Form.Label>Şifre:</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        placeholder="Şifrenizi girin"
                                        value={formData.password}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="confirmPassword" className="mb-3">
                                    <Form.Label>Şifre Tekrar:</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="confirmPassword"
                                        placeholder="Şifrenizi tekrar girin"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Card.Body>
                        </Card>

                        <Button
                            style={{ fontWeight: "500", padding: "10px 20px", fontSize: "18px" }}
                            variant="warning"
                            type="submit"
                            className="w-100 mt-4"
                            disabled={isLoading}
                        >
                            {isLoading ? "Kayıt Yapılıyor..." : "Üye Ol"}
                        </Button>
                    </Form>

                    <Card.Body>

                        <hr className="my-4" />
                        <div className="text-center">
                            <h4 style={{ fontWeight: "600" }} className="mb-2">
                                ÜYE MİSİNİZ?
                            </h4>
                            <p style={{ fontWeight: "400", color: "black" }} className="mb-3">
                                Üyeler özel fırsatlardan yararlanmak için <br />
                                hemen giriş yapın.
                            </p>
                            <Button
                                onClick={handleLogin}
                                style={{ fontWeight: "500", padding: "10px 20px", fontSize: "18px" }}
                                variant="primary"
                                className="w-100"
                            >
                                Giriş Yap
                            </Button>
                        </div>
                    </Card.Body>
                </Col>
            </Row>
        </Container>
    );
};

export default RegisterPage;
