import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Form, Button, Alert } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "../../style/login.css";
import { useUser } from "../../Context/UserContext";
import loginImage from "../../assets/icons/user_login.png"
import { FaGoogle } from "react-icons/fa";
import ScrollToTopOnMount from "../../Component/ScrollToTopOnMount"; // Google ikonu


const LoginPage = () => {
    const [formData, setFormData] = useState({ email: "", password: "" });
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const { login } = useUser();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorMessage(""); // Önceki hata mesajlarını sıfırla
        setSuccessMessage(""); // Önceki başarı mesajlarını sıfırla

        try {
            const response = await axios.post(
                "https://panel.pahaliyaalma.com/api/uye/userLogin",
                {
                    email: formData.email,
                    password: formData.password,
                }
            );

            const { status, user, message } = response.data;

            if (status === 200) {
                if (user.api_token) {
                    localStorage.setItem("api_token", user.api_token);
                    axios.defaults.headers.common["Authorization"] = `Bearer ${user.api_token}`;
                }


                login(user);
                setSuccessMessage("Giriş başarılı!");
                navigate("/uye/panel");
            } else {
                setErrorMessage(message || "Yanlış kullanıcı adı veya şifre.");
            }
        } catch (error) {
            console.error("Hata Detayı:", error);

            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message || "Bir hata oluştu, lütfen tekrar deneyin.");
            } else if (error.request) {
                setErrorMessage("Sunucuya bağlanılamıyor, lütfen bağlantınızı kontrol edin.");
            } else {
                setErrorMessage("Bir hata oluştu, lütfen daha sonra tekrar deneyin.");
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleGuestLogin = () => {
        // Ana sayfaya yönlendir
        navigate("/");
    };

    const handleRegister = () => {
        // Ana sayfaya yönlendir
        navigate("/kayit-ol");
    };

    return (
        <Container className="login-container">
            <ScrollToTopOnMount/>

            <Helmet>
                <title>Üye Girişi</title>
            </Helmet>

            <Row className="justify-content-center align-items-center">
                <Col md={5}>

                    <h3 className="text-center mb-4 login-title">ÜYE GİRİŞİ</h3>



                    <Form onSubmit={handleSubmit}>

                        <Card className="p-4 shadow-lg rounded login-card">

                            <img src={loginImage} alt="Giriş Yap" className="login-image"/>

                            <Form.Group controlId="email" className="mb-3">
                                <Form.Label>E-Posta Adresi:</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="E-posta adresinizi girin"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="password" className="mb-3">
                                <Form.Label>Şifre:</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder="Şifrenizi girin"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>


                            {errorMessage && (
                                <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                    {errorMessage}
                                </Alert>
                            )}
                            {successMessage && (
                                <Alert variant="success" onClose={() => setSuccessMessage("")} dismissible>
                                    {successMessage}
                                </Alert>
                            )}
                        </Card>


                        <Button style={{fontWeight:'500',padding:'10px 20px',fontSize:'18px'}}
                            variant="primary"
                            type="submit"
                            className="w-100 mt-4"
                            disabled={isLoading}
                        >
                            {isLoading ? "Giriş Yapılıyor..." : "Giriş Yap"}
                        </Button>


                        <br/><br/>


                    </Form>


                    <Card.Body>


                        <hr className="my-3"/>
                        <div className="text-center">
                            <div className="text-center mb-3">
                                <Link style={{fontWeight:'500'}} to="/sifremi-unuttum" className="forgot-password-link text-primary">Şifremi Unuttum</Link>
                            </div>
                            <h4 style={{fontWeight:'600'}} className="mb-2">ÜYE DEĞİL MİSİNİZ?</h4>
                            <p style={{fontWeight:'400',color:'black'}} className="mb-3">
                                Üyeler özel fırsatlardan yararlanmak için <br/>hemen üye olun.
                            </p>
                            <Button
                                onClick={handleRegister}
                                style={{fontWeight:'500',padding:'10px 20px',fontSize:'18px'}} variant="warning" className="w-100">Hemen Üye Ol</Button>

                        </div>
                    </Card.Body>





                </Col>
            </Row>
        </Container>
    );
};

export default LoginPage;
