import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Nav } from "react-bootstrap";
import { FaShoppingCart, FaSearch } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import logo from "../assets/pahaliyaalmacom-logo.png";

const Megabar = ({ cartTotal, onCartClick }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (!searchQuery.trim()) return;
        navigate(`/arama?query=${encodeURIComponent(searchQuery)}`);
    };

    return (
        <div
            style={{
                backgroundColor: "#ffffff",
                padding: "25px 0",
                borderBottom: "1px solid #ccc",
            }}
        >
            <Container>
                <Row className="align-items-center">
                    {/* LOGO (Sadece masaüstünde görünür) */}
                    <Col md={3} className="d-none d-md-block">
                        <Nav.Link
                            href="/"
                            style={{
                                textDecoration: "none",
                                fontWeight: "bold",
                                fontSize: "20px",
                                color: "#333",
                            }}
                        >
                            <img
                                src={logo}
                                alt="Logo"
                                style={{ maxWidth: "250px", height: "auto" }}
                            />
                        </Nav.Link>
                    </Col>

                    {/* ARAMA (Mobilde 9 kolon, masaüstünde 6 kolon) */}
                    <Col xs={12} md={6}>
                        <Form
                            className="d-flex"
                            style={{ position: "relative" }}
                            onSubmit={handleSearchSubmit}
                        >
                            <Form.Control
                                type="text"
                                placeholder="Marka, Kategori, Ürün Arayın..."
                                style={{
                                    borderRadius: "5px 0 0 5px",
                                    border: "1px solid #ccc",
                                    padding: "10px 15px",
                                    boxShadow: "none",
                                }}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <Button
                                variant="primary"
                                type="submit"
                                style={{
                                    borderRadius: "0 5px 5px 0",
                                    border: "none",
                                    backgroundColor: "#FA8C17",
                                    padding: "0 15px",
                                }}
                            >
                                <FaSearch />
                            </Button>
                        </Form>
                    </Col>

                    {/* SEPET (Mobilde 3 kolon, masaüstünde 3 kolon) */}
                    <Col
                        xs={12}
                        md={3}
                        className="d-flex  justify-content-center justify-content-md-end mt-md-0 mt-2"
                        // Küçük bir sağ/sol padding ekleyebilirsiniz
                        style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                        <div
                            onClick={onCartClick}
                            style={{
                                background: "#fff",
                                borderRadius: "12px",
                                padding: "5px 10px",
                                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                                border: "1px solid #ccc",
                                cursor: "pointer",
                                textAlign: "center",
                            }}
                        >
                            <div
                                style={{
                                    marginBottom: "2px",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "#333",
                                }}
                            >
                                Sepetiniz:
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                <span
                    style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#000",
                        marginRight: "5px",
                    }}
                >
                  {cartTotal().toLocaleString("tr-TR", {
                      style: "currency",
                      currency: "TRY",
                  })}
                </span>
                                <FaShoppingCart size="1.2em" style={{ color: "#000" }} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Megabar;
