import React, { useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Form, Button, Alert } from "react-bootstrap";

const SifreUnuttum = () => {
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [resetLink, setResetLink] = useState(""); // 🔥 Şifre sıfırlama linkini burada tutacağız
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorMessage("");
        setSuccessMessage("");
        setResetLink("");

        try {
            const response = await axios.post("https://panel.pahaliyaalma.com/api/uye/sifremi-unuttum", { email });

            if (response.data.status === 200) {
                setSuccessMessage("Şifre sıfırlama bağlantısı e-posta adresinize gönderildi. Lütfen 30 dakika içinde kullanın.");
                setResetLink(response.data.reset_link); // 🔥 API'den gelen frontend URL'sini kaydediyoruz
            }
        } catch (error) {
            if (error.response) {
                // **🔥 API'den gelen hatayı yakala ve göster**
                if (error.response.status === 404) {
                    setErrorMessage("Bu e-posta adresi kayıtlı değil. Lütfen doğru adresi girin.");
                } else if (error.response.status === 429) {
                    setErrorMessage("Zaten bir şifre sıfırlama bağlantısı aldınız. Lütfen mevcut bağlantıyı kullanın veya bir süre sonra tekrar deneyin.");
                } else {
                    setErrorMessage(error.response.data.message || "Bir hata oluştu, lütfen tekrar deneyin.");
                }
            } else {
                setErrorMessage("Sunucuya bağlanırken bir hata oluştu, lütfen internet bağlantınızı kontrol edin.");
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container>
            <Row className="justify-content-center mt-5 mb-5">
                <Col md={6}>
                    <Card className="p-4 shadow-lg rounded">
                        <h3 className="text-center mb-4">Şifrenizi Mi Unuttunuz?</h3>

                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="email">
                                <Form.Label>E-Posta Adresi:</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="E-posta adresinizi girin"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                            {successMessage && (
                                <Alert variant="success">
                                    {successMessage}
                                    {resetLink && (
                                        <p>
                                            <a href={resetLink} target="_blank" rel="noopener noreferrer">
                                                Şifrenizi Sıfırlamak İçin Tıklayın
                                            </a>
                                        </p>
                                    )}
                                </Alert>
                            )}

                            <Button type="submit" variant="primary" className="w-100 mt-3" disabled={isLoading}>
                                {isLoading ? "İşlem Yapılıyor..." : "Şifremi Unuttum"}
                            </Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default SifreUnuttum;
