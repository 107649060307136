import React, { useState, useEffect,useContext } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import axios from "axios";
import logo from "../assets/logo.webp";
import { CacheContext } from "../Context/CacheContext"; // ekle


function AppNavbar() {
    const location = useLocation();
    const [datacat, setDatacat] = useState([]); // API'den gelen veriler
    const [loading, setLoading] = useState(true);
    const { cacheResetFlag } = useContext(CacheContext); // al

    // Menünün açık/kapalı durumunu kontrol eden state
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        const cachedData = localStorage.getItem("anamenuler_cache");
        if (cachedData && !cacheResetFlag) {
            setDatacat(JSON.parse(cachedData));
            setLoading(false);
        } else {
            axios.get("https://panel.pahaliyaalma.com/api/data/anamenuler")
                .then(response => {
                    setDatacat(response.data);
                    localStorage.setItem("anamenuler_cache", JSON.stringify(response.data));
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error fetching data: ", error);
                    setLoading(false);
                });
        }
    }, [cacheResetFlag]); // cacheResetFlag değişirse yeniden çalışsın

    if (loading) {
        // Yüklenme aşamasında boş veya spinner gösterebilirsiniz
        return <p></p>;
    }

    // Tüm linkler için temel stil
    const navLinkStyle = {
        color: "#ffffff",
        fontSize: "15px",
        fontWeight: "bold",
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        gap: "2px",
        borderRadius: "4px",
    };



    const activeLinkStyle = {
        backgroundColor: "#ffffff",
        color: "#fa8c17",
        transition: "all 0.3s ease",
        boxShadow: "0 0 10px rgba(255, 255, 255, 0.7)",
        padding:'10px',
    };

    return (
        <Navbar
            collapseOnSelect
            expand="lg"
            // expanded/onToggle ile mobil açılır menüyü manuel kontrol ediyoruz
            expanded={expanded}
            onToggle={() => setExpanded(!expanded)}
            style={{
                backgroundColor: "#FA8C17",
                borderBottom: "1px solid #cccccc",
                padding: "1px 0",
                height: "55px",
            }}
            variant="light"
        >
            <Container>
                {/* LOGO (Sadece lg altı, yani mobil ekranlarda görünür) */}
                <Navbar.Brand
                    href="/"
                    className="d-lg-none"
                    style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#ffffff",
                    }}
                >
                    <img
                        src={logo}
                        alt="Logo"
                        style={{ maxWidth: "200px", height: "auto" }}
                    />
                </Navbar.Brand>

                {/* Hamburger butonu */}
                <Navbar.Toggle aria-controls="responsive-navbar-nav">
                    <FaBars color="#ffffff" />
                </Navbar.Toggle>

                {/* Açılır/Kapanır menü alanı */}
                <Navbar.Collapse
                    id="responsive-navbar-nav"
                    style={{
                        zIndex: 100,
                        backgroundColor: "#FA8C17",
                    }}
                >
                    <Nav
                        className="mx-auto"
                        style={{ gap: "35px", alignItems: "center" }}
                    >
                        {datacat.map((category) => {
                            // Link aktif mi kontrol et
                            const isActive = location.pathname === category.linkyol;
                            return (
                                <Link
                                    key={category.id}
                                    to={category.linkyol || "#"}
                                    onClick={() => setExpanded(false)} // Linke tıklayınca menüyü kapat
                                    style={{
                                        ...navLinkStyle,
                                        ...(isActive ? activeLinkStyle : {}),
                                    }}
                                >
                                    {category.baslik}
                                </Link>
                            );
                        })}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default AppNavbar;
