
import React, { useEffect, useState } from 'react';
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import axios from 'axios';
import ScrollToTopOnMount from "../../Component/ScrollToTopOnMount";

const KvkkPage = () => {
    const [data, setData] = useState({ title: '', content: '' });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAgreement = async () => {
            try {
                const response = await axios.get('https://panel.pahaliyaalma.com/api/data/kvkk');
                setData(response.data);
            } catch (err) {
                setError('Sözleşme içeriği yüklenirken bir hata oluştu.');
            } finally {
                setLoading(false);
            }
        };

        fetchAgreement();
    }, []);

    if (loading) {
        return <p>Yükleniyor...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    return (
        <div>
            <ScrollToTopOnMount/>

            <Helmet>
                <title>{data.baslik || 'Mesafeli Satış Sözleşmesi'}</title>
            </Helmet>
            <Container className="mt-5">
                <h2>{data.baslik}</h2>
                <div
                    dangerouslySetInnerHTML={{__html: data.icerik}}
                    style={{fontSize: '16px', lineHeight: '1.6', color: '#333'}}
                />
            </Container>
        </div>
    );
};

export default KvkkPage;
