import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col, Spinner, Alert } from "react-bootstrap";

const PaytrPaymentPage = () => {
    const { orderId } = useParams();
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPaymentToken = async () => {
            try {
                const response = await axios.post("https://panel.pahaliyaalma.com/api/paytr-payment", {
                    order_id: orderId,
                });

                if (response.data.status === "success") {
                    setToken(response.data.token);
                } else {
                    setError("Ödeme işlemi başlatılamadı.");
                }
            } catch (err) {
                setError("Bağlantı hatası oluştu, tekrar deneyin.");
            } finally {
                setLoading(false);
            }
        };

        fetchPaymentToken();
    }, [orderId]);

    return (
        <div className="payment-page bg-light py-5">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8}>
                        {loading && <Spinner animation="border" variant="primary" className="d-block mx-auto my-5" />}
                        {error && <Alert variant="danger">{error}</Alert>}

                        {token && (
                            <div className="payment-wrapper">
                                <iframe
                                    src={`https://www.paytr.com/odeme/guvenli/${token}`}
                                    frameBorder="0"
                                    allowFullScreen
                                    className="payment-iframe"
                                    title="PayTR Ödeme"
                                    onLoad={(e) => {
                                        // Otomatik scroll yukarı gelsin istersen:
                                        window.scrollTo(0, 0);
                                    }}
                                ></iframe>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>

            <style>{`
                .payment-iframe {
                    width: 100%;
                    min-height: 700px;
                    border: none;
                    border-radius: 10px;
                    box-shadow: 0 0 10px rgba(0,0,0,0.15);
                    transition: all 0.3s ease;
                }

                .payment-wrapper {
                    width: 100%;
                    overflow: hidden;
                }

                @media (max-width: 768px) {
                    .payment-iframe {
                        min-height: 800px;
                        border-radius: 0;
                    }
                }
            `}</style>
        </div>
    );
};

export default PaytrPaymentPage;
