import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Ustbanner from "../../Component/Ustbanner";
import ScrollToTopOnMount from "../../Component/ScrollToTopOnMount";

const About = () => {
    const [loading, setLoading] = useState(true);
    const [aboutData, setAboutData] = useState({});

    useEffect(() => {
        fetch("https://panel.pahaliyaalma.com/api/data/about")
            .then((response) => response.json())
            .then((data) => {
                setAboutData(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Hata oluştu:", error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <div>Yükleniyor...</div>;
    }

    // aboutData içindeki alanları parçalayalım
    const {
        baslik,
        ustbaslik,
        icerik1,
        resimyol,
        resimyol_alt,
        resimyol2,
        resimyol2_alt,
        resimyol3,
        resimyol3_alt,
        bilgibirbaslik,
        bilgibiralt,
        bilgiikibaslik,
        bilgiikialt,
        bilgiucbaslik,
        bilgiucalt,
        bilgidortbaslik,
        bilgidortalt,
        solbaslik,
        solicerik,
        solresimyol,
        solresimyolalt,
        sagbaslik,
        sagicerik,
        sagresimyol,
        sagresimyolalt,
    } = aboutData;

    return (
        <div className="bg-white">
            <ScrollToTopOnMount />
            <Helmet>
                <title>{baslik}</title>
            </Helmet>

            <Container>
                <Row className="justify-content-center">
                    <Col md={12}>
                        {/* ÜST BAŞLIK + İÇERİK */}
                        <h2 className="mt-4">{ustbaslik}</h2>

                        <Row>
                            <Col md={6}>
                                <h1 className="mt-5 mb-1">{baslik}</h1>
                                {icerik1 && (
                                    <div
                                        dangerouslySetInnerHTML={{ __html: icerik1 }}
                                        style={{ lineHeight: "32px", fontSize: 17 }}
                                    />
                                )}
                            </Col>
                            <Col
                                md={6}
                                className="border mt-5 d-flex justify-content-center align-items-center"
                            >
                                {resimyol && (
                                    <img
                                        src={`https://panel.pahaliyaalma.com/${resimyol}`}
                                        alt={resimyol_alt || ""}
                                        style={{ maxWidth: "300px", height: "auto" }}
                                    />
                                )}
                            </Col>
                        </Row>


                        {/* 4 Bilgi Başlık/Alt Metin */}
                        <Row style={{ margin: "100px 0" }} className="text-center">
                            <Col md={3}>
                                {bilgibirbaslik && (
                                    <h3 style={{ color: "black" }} className="fw-bold">
                                        {bilgibirbaslik}
                                    </h3>
                                )}
                                {bilgibiralt && <p>{bilgibiralt}</p>}
                            </Col>
                            <Col md={3}>
                                {bilgiikibaslik && (
                                    <h3 style={{ color: "black" }} className="fw-bold">
                                        {bilgiikibaslik}
                                    </h3>
                                )}
                                {bilgiikialt && <p>{bilgiikialt}</p>}
                            </Col>
                            <Col md={3}>
                                {bilgiucbaslik && (
                                    <h3 style={{ color: "black" }} className="fw-bold">
                                        {bilgiucbaslik}
                                    </h3>
                                )}
                                {bilgiucalt && <p>{bilgiucalt}</p>}
                            </Col>
                            <Col md={3}>
                                {bilgidortbaslik && (
                                    <h3 style={{ color: "black" }} className="fw-bold">
                                        {bilgidortbaslik}
                                    </h3>
                                )}
                                {bilgidortalt && <p>{bilgidortalt}</p>}
                            </Col>
                        </Row>

                        {/* Sol Kısım */}
                        {/* Mobilde önce metin (order:1), sonra resim (order:2).
                Masaüstünde ise solda metin (order:1), sağda resim (order:2). */}
                        <Row className="align-items-center mt-5">
                            <Col
                                xs={{ order: 1 }}
                                md={{ span: 6, order: 1 }}
                            >
                                {solbaslik && <h4 className="fw-bold">{solbaslik}</h4>}
                                {solicerik && (
                                    <div
                                        dangerouslySetInnerHTML={{ __html: solicerik }}
                                        style={{ lineHeight: "32px", fontSize: 17 }}
                                    />
                                )}
                            </Col>
                            <Col
                                xs={{ order: 2 }}
                                md={{ span: 6, order: 2 }}
                                className="d-flex justify-content-center"
                            >
                                {solresimyol && (
                                    <img
                                        src={`https://panel.pahaliyaalma.com/${solresimyol}`}
                                        alt={solresimyolalt || ""}
                                        className="img-fluid rounded"
                                    />
                                )}
                            </Col>
                        </Row>

                        {/* Sağ Kısım */}
                        {/* Mobilde önce resim (order:1), sonra metin (order:2).
                Masaüstünde solda resim (order:1), sağda metin (order:2). */}
                        <Row className="align-items-center mt-5">
                            <Col
                                xs={{ order: 1 }}
                                md={{ span: 6, order: 1 }}
                                className="d-flex justify-content-center"
                            >
                                {sagresimyol && (
                                    <img
                                        src={`https://panel.pahaliyaalma.com/${sagresimyol}`}
                                        alt={sagresimyolalt || ""}
                                        className="img-fluid rounded"
                                    />
                                )}
                            </Col>
                            <Col
                                xs={{ order: 2 }}
                                md={{ span: 6, order: 2 }}
                            >
                                {sagbaslik && <h4 className="fw-bold">{sagbaslik}</h4>}
                                {sagicerik && (
                                    <div
                                        dangerouslySetInnerHTML={{ __html: sagicerik }}
                                        style={{ lineHeight: "32px", fontSize: 17 }}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default About;
