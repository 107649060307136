import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        try {
            const response = await axios.get("https://panel.pahaliyaalma.com/api/data/ayarlar");
            setSettings(response.data);
        } catch (err) {
            setError("Ayarlar yüklenirken hata oluştu.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <SettingsContext.Provider value={{ settings, loading, error }}>
            {children}
        </SettingsContext.Provider>
    );
};

// Özel Hook (Kolay kullanım için)
export const useSettings = () => useContext(SettingsContext);
