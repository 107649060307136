import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {  FaBars } from "react-icons/fa";
import { HiOutlineShoppingBag,HiOutlineMenu } from "react-icons/hi";
import { RxHamburgerMenu } from "react-icons/rx";
import { TbMenu2 } from "react-icons/tb";
import CustomStylishMenu from "./CustomStylishMenu";


import axios from "axios";
import logo from "../assets/pahaliyaalmacom-logo.png";
import { CacheContext } from "../Context/CacheContext";

const MobilMegaNavbar = ({ cartTotal, onCartClick,cartItemCount  }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [datacat, setDatacat] = useState([]);
    const location = useLocation();
    const { cacheResetFlag } = useContext(CacheContext);

    useEffect(() => {
        const cachedData = localStorage.getItem("anamenuler_cache");
        if (cachedData && !cacheResetFlag) {
            setDatacat(JSON.parse(cachedData));
        } else {
            axios.get("https://panel.pahaliyaalma.com/api/data/anamenuler")
                .then(res => {
                    setDatacat(res.data);
                    localStorage.setItem("anamenuler_cache", JSON.stringify(res.data));
                })
                .catch(err => console.error("Navbar veri hatası:", err));
        }
    }, [cacheResetFlag]);

    return (
        <div style={{ borderBottom: "1px solid #ccc", backgroundColor: "#fff",padding:'20px 10px' }}>
            {/* Üst Bar */}
            <Container>
                <Row className="align-items-center py-2">
                    {/* Hamburger */}
                    <Col xs={2} className="text-start">
                        <CustomStylishMenu
                            onClick={() => setMenuOpen(!menuOpen)}
                            color="#000" // turuncu istersen
                            size={42}
                        />
                    </Col>

                    {/* Logo */}
                    <Col xs={8} className="text-center">
                        <Link to="/">
                            <img
                                src={logo}
                                alt="Logo"
                                style={{ width:'100%' }}
                            />
                        </Link>
                    </Col>

                    {/* Sepet */}
                    <Col xs={2} className="text-end position-relative">
                        <HiOutlineShoppingBag
                            size={28}
                            color="#000"
                            onClick={onCartClick}
                            style={{ cursor: "pointer",marginTop:'-10px' }}
                        />
                        {cartItemCount > 0 && (
                            <span style={{
                                position: "absolute",
                                top: -5,
                                right: '35px',
                                backgroundColor: "#FA8C17",
                                color: "#fff",
                                borderRadius: "50%",
                                width: "15px",
                                height: "15px",
                                fontSize: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: "bold",
                                transform: "translate(50%, -50%)"
                            }}>
                                {cartItemCount}
                            </span>
                        )}
                    </Col>
                </Row>
            </Container>

            {/* Menü (Kategori Linkleri) */}
            <Collapse in={menuOpen}>
                <div style={{ backgroundColor: "#FA8C17", padding: "10px" }}>
                    {datacat.map((category) => {
                        const isActive = location.pathname === category.linkyol;
                        return (
                            <Link
                                key={category.id}
                                to={category.linkyol || "#"}
                                onClick={() => setMenuOpen(false)}
                                style={{
                                    display: "block",
                                    color: isActive ? "#fa8c17" : "#fff",
                                    backgroundColor: isActive ? "#fff" : "transparent",
                                    fontWeight: "bold",
                                    borderRadius: "4px",
                                    padding: "8px",
                                    marginBottom: "5px",
                                    textDecoration: "none",
                                }}
                            >
                                {category.baslik}
                            </Link>
                        );
                    })}
                </div>
            </Collapse>
        </div>
    );
};

export default MobilMegaNavbar;
