import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { CacheContext } from "../Context/CacheContext";


const Ustbanner = () => {
    const [kampanyalar, setKampanyalar] = useState([]);
    const { cacheResetFlag } = useContext(CacheContext); // Laravel’den gelen flag
    const CACHE_KEY = "ustbanner_cache";

    useEffect(() => {
        const cachedData = localStorage.getItem(CACHE_KEY);

        if (cachedData && !cacheResetFlag) {
            setKampanyalar(JSON.parse(cachedData));
        } else {
            axios
                .get("https://panel.pahaliyaalma.com/api/data/kampanyalar")
                .then((response) => {
                    setKampanyalar(response.data);
                    localStorage.setItem(CACHE_KEY, JSON.stringify(response.data)); // cache’e yaz
                })
                .catch((error) => {
                    console.error("Error fetching data: ", error);
                });
        }
    }, [cacheResetFlag]); // cache sıfırlanırsa yeniden çalış

    return (
        <div className="container mt-4">
            <div className="row">
                {kampanyalar.map((kampanya) => (
                    <div className="col-md-6 mb-3" key={kampanya.id}>
                        <a href={kampanya.linkyol}>
                            <img
                                src={`https://panel.pahaliyaalma.com/${kampanya.resimyol}`}
                                alt={kampanya.resimalt}
                                className="img-fluid"
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    borderRadius: "10px",
                                    objectFit: "cover",
                                }}
                            />
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Ustbanner;
