import React, {useContext, useEffect, useState} from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {Link, useNavigate} from "react-router-dom";
import "../style/urunler.css";
import { CacheContext } from "../Context/CacheContext";


const IndirimliUrunler = ({ onAddToCart }) => {
    const [products, setProducts] = useState([]); // Popüler ürünler
    const [loading, setLoading] = useState(true); // Yüklenme durumu

    const CACHE_KEY = "populerurun_cache";
    const { cacheResetFlag } = useContext(CacheContext); // cache durumu



    useEffect(() => {
        const cachedData = localStorage.getItem(CACHE_KEY);

        if (cachedData && !cacheResetFlag) {
            setProducts(JSON.parse(cachedData));
            setLoading(false);
        } else {
            axios
                .get("https://panel.pahaliyaalma.com/api/data/soneklenenlerurundata")
                .then((response) => {
                    setProducts(response.data);
                    localStorage.setItem(CACHE_KEY, JSON.stringify(response.data)); // cache yaz
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("API Error:", error);
                    setLoading(false);
                });
        }
    }, [cacheResetFlag]); // cache silindiğinde tekrar veri çek



    if (loading) {
        return <p style={{ textAlign: "center", margin: "50px 0" }}>Son Eklenen ürünler yükleniyor...</p>;
    }

    return (
        <Container>
            {/* 1) className="populer-urunler-header" ekliyoruz */}
            <div
                className="d-flex justify-content-between align-items-center populer-urunler-header"
                style={{ marginTop: "40px", marginRight: "15px" }}
            >
                <h2 style={{marginBottom:'10px'}}>Son Eklenen Ürünler</h2>

                <Link
                    style={{ fontWeight: "600", color: "rgba(0,0,0,0.89)" }}
                    to={`/urunler`}
                >
                    Tümünü Göster
                </Link>
            </div>

            <Row className="g-1">
                {products.map((product) => (
                    <Col xs={6} sm={6} md={4} lg={2} key={product.id} style={{ marginBottom: "20px" }}>
                        <ProductCard product={product} onAddToCart={onAddToCart} />
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

// Ürün Kartı Bileşeni
const ProductCard = ({ product, onAddToCart }) => {
    const navigate = useNavigate(); // useNavigate hook'unu kullan

    return (
        <Card
            style={{ cursor: "pointer" }}
            className="text-center shadow-sm p-1"
            onClick={() => navigate(`/urun/${product.id}`)}
        >
            <LazyLoadImage
                src={`https://panel.pahaliyaalma.com/${product.resimyol}`}
                alt={product.baslik}
                effect="blur"
                style={{
                    width: "100%",
                    height: "170px",
                    objectFit: "scale-down",
                    marginBottom: "10px",
                    borderRadius: "5px",
                }}
            />
            <Card.Body className="p-1 m-1">
                {/* 2) className="product-title-clamp" ekliyoruz */}
                <Card.Title
                    className="product-title-clamp"
                    style={{
                        fontSize: "0.95rem",
                        fontWeight: "500",
                        textAlign: "left",
                        lineHeight: "1.2",
                        height: "4.8em", // Masaüstü için 4 satır
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 4,
                        WebkitBoxOrient: "vertical",
                    }}
                >
                    {product.baslik}
                </Card.Title>

                {product.eski_fiyat && product.fiyat ? (
                    <>
                        <div className="discount-badge">
                            - %
                            {Math.round(
                                ((product.eski_fiyat - product.fiyat) / product.eski_fiyat) * 100
                            )}
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                            }}
                        >
                            <Card.Text
                                style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    color: "rgb(32,29,29)",
                                    textDecoration: "line-through",
                                }}
                            >
                                {product.eski_fiyat}₺
                            </Card.Text>

                            <Card.Text
                                style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    color: "rgb(32,29,29)",
                                }}
                            >
                                {product.fiyat}₺
                            </Card.Text>
                        </div>
                    </>
                ) : (
                    <Card.Text
                        style={{
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "rgb(32,29,29)",
                            marginTop: "10px",
                        }}
                    >
                        {product.fiyat}₺
                    </Card.Text>
                )}

                <Button
                    className="sepete-ekle-button"
                    size="sm"
                    onClick={(e) => {
                        e.stopPropagation();
                        onAddToCart(product, 1);
                    }}
                >
                    Sepete Ekle
                </Button>
            </Card.Body>
        </Card>
    );
};


export default IndirimliUrunler;
