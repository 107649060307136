import React, { useEffect, useState } from "react";
import {Container, Row, Col, Card, Table, Button, Spinner, Badge} from "react-bootstrap";
import { FaBox, FaHeadset, FaWallet } from "react-icons/fa";
import "../../style/account.css";
import UyeMenu from "../../Component/UyeMenu";
import toast, { Toaster } from "react-hot-toast";
import {Link, useLocation} from "react-router-dom";
import axios from "axios";
import { useUser } from "../../Context/UserContext"; // Kullanıcı bağlamını dahil ediyoruz
import { useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet"; // Sayfa yönlendirme için


const Account = () => {
    const navigate = useNavigate(); // Yönlendirme işlemleri için


    const { user } = useUser();
    const [orderCount, setOrderCount] = useState(0); // Sipariş sayısını saklamak için state
    const [loading, setLoading] = useState(true); // Yükleme durumu için state
    const [error, setError] = useState(null); // Hata durumu için state
    const [orders, setOrders] = useState([]); // Siparişleri saklamak için state
    const [talepler, setTalepler] = useState([]); // Siparişleri saklamak için state

    const location = useLocation();
    const token = localStorage.getItem("api_token");


    React.useEffect(() => {
        if (location.state?.message) {
            const { message, type } = location.state;
            toast.dismiss(); // Önceki bildirimi temizle
            if (type === "success") {
                toast.success(message);
            } else if (type === "error") {
                toast.error(message);
            }
        }
    }, [location]);

    // Sipariş sayısını API'den çek
    useEffect(() => {
        const fetchOrders = async () => {
            if (!user || !user.id) {
                setError("Kullanıcı giriş yapmamış.");
                setLoading(false);
                return;
            }

            try {
                // API'ye kullanıcı kimliğiyle isteği gönder
                const response = await axios.post("https://panel.pahaliyaalma.com/api/uye/siparisler", {
                    user_id: user.id, // Kullanıcı kimliği gönderiliyor
                },{
                    headers: {
                        Authorization: `${token}`, // **"Bearer " ekledik!**
                    },
                });

                setOrders(response.data.orders); // API'den gelen siparişleri kaydet
                setLoading(false);
            } catch (err) {
                setError("Siparişler yüklenirken bir hata oluştu.");
                setLoading(false);
            }
        };

        fetchOrders();
    }, [user]); // Kullanıcı değiştiğinde yeniden çalışır

    useEffect(() => {
        const fetchOrders = async () => {
            if (!user || !user.id) {
                setError("Kullanıcı giriş yapmamış.");
                setLoading(false);
                return;
            }

            try {
                // API'ye kullanıcı kimliğiyle isteği gönder
                const response = await axios.post("https://panel.pahaliyaalma.com/api/uye/talepler", {
                    user_id: user.id, // Kullanıcı kimliği gönderiliyor
                },{
                    headers: {
                        Authorization: `${token}`, // **"Bearer " ekledik!**
                    },
                });

                setTalepler(response.data.orders); // API'den gelen siparişleri kaydet
                setLoading(false);
            } catch (err) {
                setError("Siparişler yüklenirken bir hata oluştu.");
                setLoading(false);
            }
        };

        fetchOrders();
    }, [user]); // Kullanıcı değiştiğinde yeniden çalışır


    const getBadge = (durum) => {
        switch (durum) {
            case "0":
                return <Badge bg="warning">Bekliyor</Badge>;
            case "1":
                return <Badge bg="success">Sipariş Hazırlanıyor</Badge>;
            case "2":
                return <Badge bg="info">Kargoya Verildi</Badge>;
            case "3":
                return <Badge bg="danger">İptal Edildi</Badge>;
            case "4":
                return <Badge bg="primary">İade Talebi</Badge>;
            case "5":
                return <Badge bg="primary">İade Onay</Badge>;
            case "6":
                return <Badge bg="warning">Ödeme Bekliyor</Badge>;
            case "7":
                return <Badge bg="success">Ödeme Başarılı</Badge>;
            case "8":
                return <Badge bg="danger">Ödeme Başarısız</Badge>;
            case "9":
                return <Badge bg="success">Tamamlandı</Badge>;
            default:
                return <Badge bg="secondary">Bilinmiyor</Badge>;
        }
    };

    const getTalep = (durum) => {
        switch (durum) {
            case "0":
                return <Badge bg="warning">Bekliyor</Badge>;
            case "1":
                return <Badge bg="success">Tamamlandı</Badge>;
            case 2:
                return <Badge bg="danger">İptal Edildi</Badge>;
            default:
                return <Badge bg="secondary">Bilinmiyor</Badge>;
        }
    };

    return (
        <div style={{backgroundColor:'#f6f6f6',padding:'0',margin:'0'}}>
            <Container style={{ paddingTop:'50px',padding: "20px", borderRadius: "10px" }}>
                <Helmet>
                    <title>Hesabım</title>
                </Helmet>
                <Row>
                    {/* Sol Menü */}
                    <Col md={3}>
                        <UyeMenu user={user} />
                    </Col>

                    {/* Sağ Panel */}
                    <Col md={9}>
                        <Row className="mb-4">
                            <Col md={4}>
                                <Card
                                    className="shadow-sm mt-md-0 mt-2"
                                    style={{
                                        backgroundColor: "#28a745",
                                        color: "#fff",
                                        borderRadius: "10px",
                                        textAlign: "center",
                                        padding: "5px",
                                        margin:0,
                                    }}
                                >
                                    <Card.Body className=" m-0 p-0">
                                        <FaBox size={30} className="mb-2" />
                                        <h6>Toplam Siparişiniz</h6>
                                        {loading ? (
                                            <h3 style={{ color: "#ffffff" }}>Yükleniyor...</h3>
                                        ) : error ? (
                                            <h3 style={{ color: "#ffffff" }}>Hata</h3>
                                        ) : (
                                            <h3 style={{ color: "#ffffff" }}>{orders.length}</h3>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card
                                    className="shadow-sm mt-md-0 mt-2"
                                    style={{
                                        backgroundColor: "#ffc107",
                                        color: "#fff",
                                        borderRadius: "10px",
                                        textAlign: "center",
                                        padding: "5px",
                                    }}
                                >
                                    <Card.Body className="m-0 p-0">
                                        <FaHeadset size={30} className="mb-2" />
                                        <h6>Destek Talepleriniz</h6>
                                        {loading ? (
                                            <h3 style={{ color: "#ffffff" }}>Yükleniyor...</h3>
                                        ) : error ? (
                                            <h3 style={{ color: "#ffffff" }}>Hata</h3>
                                        ) : (
                                            <h3 style={{ color: "#ffffff" }}>{talepler.length}</h3>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card
                                    className="shadow-sm mt-md-0 mt-2"
                                    style={{
                                        backgroundColor: "#007bff",
                                        color: "#fff",
                                        borderRadius: "10px",
                                        textAlign: "center",
                                        padding: "5px",
                                    }}
                                >
                                    <Card.Body className="m-0 p-0">
                                        <FaWallet size={30} className="mb-2" />
                                        <h6>Toplam Bakiyeniz</h6>
                                        <h3 style={{color:'#ffffff'}}>{user.bakiye}₺</h3>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>



                        {/* Son Siparişler */}
                        <Card
                            className="shadow-sm mb-4"
                            style={{
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                padding: "15px",
                            }}
                        >
                            <Card.Body>
                                <h5 className="mb-4 text-center">Son Siparişler</h5>
                                {loading ? (
                                    <div className="text-center">
                                        <Spinner animation="border" role="status" />
                                        <p>Yükleniyor...</p>
                                    </div>
                                ) : error ? (
                                    <p className="text-center text-danger">{error}</p>
                                ) : orders && orders.length > 0 ? (
                                    <Table striped bordered hover responsive>
                                        <thead>
                                        <tr>
                                            <th>Sipariş ID</th>
                                            <th>Toplam</th>
                                            <th>Durum</th>
                                            <th>Detay</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {orders.map((order, index) => (
                                            <tr key={index}>
                                                <td>{order.id}</td>
                                                <td>{order.toplam_fiyat}₺</td>
                                                <td>{getBadge(order.durum)}</td>
                                                <td>
                                                    <Link
                                                        to={`/uye/siparis/${order.id}`}
                                                        state={{showToast: false}}
                                                        className="btn btn-primary btn-sm"
                                                    >
                                                        Detay
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <p className="text-center text-muted">
                                        Henüz siparişiniz bulunmamaktadır.
                                    </p>
                                )}
                            </Card.Body>
                        </Card>

                        {/* Son Destek Talebi */}
                        <Card
                            className="shadow-sm"
                            style={{
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                padding: "15px",
                            }}
                        >
                            <Card.Body>
                                <div className="d-flex justify-content-between align-items-center mb-4">
                                    <h5 className="text-center">Taleplerim</h5>
                                    <Button
                                        variant="success"
                                        onClick={() => navigate("/uye/talep-ac")} // Talep açma formuna yönlendirme
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Talep Aç
                                    </Button>
                                </div>
                                {talepler && talepler.length > 0 ? (
                                    <Table striped bordered hover responsive>
                                        <thead>
                                        <tr>
                                            <th>Talep ID</th>
                                            <th>Konu</th>
                                            <th>Durum</th>
                                            <th>Detay</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {talepler.map((talep, index) => (
                                            <tr key={index}>
                                                <td>{talep.id}</td>
                                                <td>{talep.talepadi}</td>
                                                <td>{getTalep(talep.statu)}</td>
                                                <td>
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        onClick={() => console.log("Detay", talep.id)}
                                                    >
                                                        Detay
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <p className="text-center text-muted">
                                        Henüz talebiniz bulunmamaktadır.
                                    </p>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Toaster position="top-right" reverseOrder={false} />

            </Container>


        </div>
    );
};

export default Account;
