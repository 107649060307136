import React, { useEffect, useState, useContext } from "react";
import {
    Spinner,
    Container,
    Row,
    Col,
    Card,
    Button,
    ListGroup,
    Form,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate, useParams } from "react-router-dom";
import "react-lazy-load-image-component/src/effects/blur.css";
import ScrollToTopOnMount from "../../Component/ScrollToTopOnMount";
import "../../style/urunler.css";
import { CacheContext } from "../../Context/CacheContext";
import {  FaBars } from "react-icons/fa";




const UrunlerList = ({ onAddToCart }) => {
    const { categorySlug } = useParams(); // undefined ise /urunler'deyiz
    const navigate = useNavigate();
    const { cacheResetFlag } = useContext(CacheContext);

    const CACHE_KEY = "urunlerlist_kategoriler_cache";

    // Kategoriler
    const [categories, setCategories] = useState([]);
    // Ürünler
    const [urunler, setUrunler] = useState([]);
    const [sortedProducts, setSortedProducts] = useState([]);
    const [loadingProducts, setLoadingProducts] = useState(false);

    // Kategori seçim / açma-kapama
    const [activeCategory, setActiveCategory] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);

    // Sıralama seçeneği
    const [sortOption, setSortOption] = useState("");
    const [showMobileKategori, setShowMobileKategori] = useState(false);

    useEffect(() => {
        setShowMobileKategori(false); // sayfa değişince mobil menü kapansın
    }, [categorySlug]);

    /**
     * 1) Kategorileri Çek
     */
    useEffect(() => {
        const cachedData = localStorage.getItem(CACHE_KEY);

        if (cachedData && !cacheResetFlag) {
            setCategories(JSON.parse(cachedData));
        } else {
            fetch("https://panel.pahaliyaalma.com/api/data/kategoriler")
                .then((res) => res.json())
                .then((data) => {
                    setCategories(data);
                    localStorage.setItem(CACHE_KEY, JSON.stringify(data));
                })
                .catch((error) => console.error("Kategoriler yüklenemedi:", error));
        }
    }, [cacheResetFlag]);

    /**
     * 2) Eğer categorySlug varsa kategoriye göre ürünler,
     *    yoksa tüm ürünleri (veya popüler) çek.
     */
    useEffect(() => {
        if (categories.length === 0) return;

        if (!categorySlug) {
            // categorySlug YOK => /urunler
            // Burada popüler veya tüm ürünlerinizi çekebilirsiniz.
            loadAllProducts();
            setSelectedCategory(null);
        } else {
            // /kategori/:categorySlug => ilgili kategori bulunup ürünleri yüklenir
            const kat = categories.find((c) => c.slug === categorySlug);
            if (kat) {
                setSelectedCategory(kat.id);
                loadProductsByCategory(kat.id);
            } else {
                // slug var ama eşleşen kategori yoksa yine tüm ürünleri/popup/error
                loadAllProducts();
                setSelectedCategory(null);
            }
        }
    }, [categorySlug, categories]);

    /**
     * (A) Tüm ürünleri (örnek) yükleme fonksiyonu
     *    => /urunler sayfası için
     */
    const loadAllProducts = () => {
        setLoadingProducts(true);

        const endpoint = "https://panel.pahaliyaalma.com/api/data/populerurundata";

        fetch(endpoint)
            .then((res) => res.json())
            .then((data) => {
                const productsArray = data.data || data;
                setUrunler(productsArray);
                setSortedProducts(productsArray);
                setLoadingProducts(false);
            })
            .catch((error) => {
                console.error("Ürünler yüklenemedi:", error);
                setLoadingProducts(false);
            });
    };

    /**
     * (B) Belirli kategoriye göre ürünleri yükleme fonksiyonu
     */
    const loadProductsByCategory = (catId) => {
        setLoadingProducts(true);
        const endpoint = `https://panel.pahaliyaalma.com/api/data/urunkategorial/${catId}`;

        fetch(endpoint)
            .then((res) => res.json())
            .then((data) => {
                const productsArray = data.data || data;
                setUrunler(productsArray);
                setSortedProducts(productsArray);
                setLoadingProducts(false);
            })
            .catch((error) => {
                console.error("Kategori bazlı ürünler yüklenemedi:", error);
                setLoadingProducts(false);
            });
    };

    /**
     * Kategori tıklanınca slug'a gidiyoruz.
     */
    const handleCategoryClick = (categoryItem) => {
        navigate(`/kategori/${categoryItem.slug}`);
    };

    /**
     * Alt kategoriyi aç/kapa
     */
    const handleToggleSubCategories = (kategoriId) => {
        if (activeCategory === kategoriId) {
            setActiveCategory(null);
        } else {
            setActiveCategory(kategoriId);
        }
    };

    /**
     * Ürün Sıralama
     */
    const handleSortChange = (e) => {
        const option = e.target.value;
        setSortOption(option);

        let sorted = [...urunler];
        switch (option) {
            case "azdan-coğa":
                sorted.sort((a, b) => a.fiyat - b.fiyat);
                break;
            case "çoktan-aza":
                sorted.sort((a, b) => b.fiyat - a.fiyat);
                break;
            case "a-z":
                sorted.sort((a, b) => a.baslik.localeCompare(b.baslik));
                break;
            case "z-a":
                sorted.sort((a, b) => b.baslik.localeCompare(a.baslik));
                break;
            default:
                // Hiçbir sıralama seçili değilse orijinal dizi
                sorted = [...urunler];
        }
        setSortedProducts(sorted);
    };

    // Ana kategoriler: alt_kategori_id === null
    const mainCategories = categories.filter(
        (cat) => cat.alt_kategori_id === null
    );

    // Seçili kategoriye dair bilgi: Başlık vb.
    const selectedItem = categories.find((cat) => cat.id === selectedCategory);

    return (
        <div>
            <Helmet>
                <title>
                    {selectedItem ? selectedItem.baslik : "Ürünler"}
                </title>
            </Helmet>
            <ScrollToTopOnMount />

            <Container>
                <Row>
                    {/* SOL TARAF: Kategori Listesi */}
                    <Col md={3} className="mt-md-4 mt-0">
                        <div className="d-flex justify-content-between align-items-center kategori-kutusu">
                            <h4 className="mb-0 mb-md-2 pt-md-4">Kategoriler</h4>

                            <FaBars
                                size={24}
                                color="#000"
                                className="mobile-category-toggle"
                                onClick={() => setShowMobileKategori(!showMobileKategori)}
                                style={{ cursor: "pointer" }}
                            />

                        </div>

                        <div className={`kategori-menu ${showMobileKategori ? "active" : ""}`}>
                            <ListGroup>
                                {mainCategories.map((kategori) => {
                                    // Bu ana kategorinin alt kategorileri
                                    const subCategories = categories.filter(
                                        (sub) => parseInt(sub.alt_kategori_id) === parseInt(kategori.id)
                                    );

                                    return (
                                        <ListGroup.Item
                                            key={kategori.id}
                                            className={`p-2 ${
                                                selectedCategory === kategori.id
                                                    ? "selected-category"
                                                    : ""
                                            }`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleCategoryClick(kategori);
                                            }}
                                        >
                                            <div className="kategori-header d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <span className="hovericin">{kategori.baslik}</span>
                                                </div>

                                                {/* Alt kategori varsa toggle göster */}
                                                {subCategories.length > 0 && (
                                                    <span
                                                        className="kategori-toggle"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleToggleSubCategories(kategori.id);
                                                        }}
                                                    >
                            {activeCategory === kategori.id ? "-" : "+"}
                          </span>
                                                )}
                                            </div>

                                            {/* Alt Kategoriler (Toggle Açık ise) */}
                                            {subCategories.length > 0 &&
                                                activeCategory === kategori.id && (
                                                    <ul className="alt-kategori-list mt-2">
                                                        {subCategories.map((altKategori) => (
                                                            <li
                                                                key={altKategori.id}
                                                                className="alt-kategori-item"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleCategoryClick(altKategori);
                                                                }}
                                                            >
                                                                {altKategori.baslik}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                        </ListGroup.Item>
                                    );
                                })}
                            </ListGroup>
                        </div>
                    </Col>

                    {/* SAĞ TARAF: Ürün Listesi */}
                    <Col md={9} className="mt-md-0 mt-3">
                        <div
                            className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mt-1 mt-md-4 mb-2 border p-2 bg-white"
                            style={{borderRadius: "10px"}}
                        >
                            <h4 className="mb-0 p-2">
                                {selectedItem ? selectedItem.baslik : "Popüler Ürünler"} -{" "}
                                {sortedProducts.length} ürün
                            </h4>

                            <Form.Select
                                value={sortOption}
                                onChange={handleSortChange}
                                className="mt-2 mt-md-0"
                                style={{width: "200px"}}
                            >
                                <option value="">Sırala</option>
                                <option value="a-z">A'dan Z'ye</option>
                                <option value="z-a">Z'den A'ya</option>
                                <option value="azdan-coğa">Fiyata Göre Artan</option>
                                <option value="çoktan-aza">Fiyata Göre Azalan</option>
                            </Form.Select>
                        </div>

                        {/* Ürün Listesi */}
                        {loadingProducts ? (
                            <div className="text-center my-5">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Yükleniyor...</span>
                                </Spinner>
                            </div>
                        ) : sortedProducts.length > 0 ? (
                            <Row className="g-3">
                                {sortedProducts.map((product) => (
                                    <Col xs={6} sm={6} md={3} key={product.id}>
                                        <Card
                                            style={{cursor: "pointer"}}
                                            onClick={() => navigate(`/urun/${product.id}`)}
                                            className="text-center shadow-sm p-1"
                                        >
                                            <LazyLoadImage
                                                src={`https://panel.pahaliyaalma.com/${product.resimyol}`}
                                                alt={product.baslik}
                                                effect="blur"
                                                style={{
                                                    width: "100%",
                                                    height: "170px",
                                                    objectFit: "scale-down",
                                                    marginBottom: "10px",
                                                    borderRadius: "5px",
                                                }}
                                            />
                                            <Card.Body>
                                                <Card.Title
                                                    style={{
                                                        fontSize: "0.95rem",
                                                        fontWeight: "500",
                                                        textAlign: "left",
                                                        lineHeight: "1.2",
                                                        height: "4.8em", // 4 satır alan
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        display: "-webkit-box",
                                                        WebkitLineClamp: 4,
                                                        WebkitBoxOrient: "vertical",
                                                    }}
                                                >
                                                    {product.baslik}
                                                </Card.Title>

                                                {product.eski_fiyat && product.fiyat && (
                                                    <div className="discount-badge">
                                                        - %
                                                        {Math.round(
                                                            ((product.eski_fiyat - product.fiyat) /
                                                                product.eski_fiyat) *
                                                            100
                                                        )}
                                                    </div>
                                                )}

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    {product.eski_fiyat && (
                                                        <Card.Text
                                                            style={{
                                                                fontSize: "18px",
                                                                fontWeight: "500",
                                                                color: "rgb(32,29,29)",
                                                                textDecoration: "line-through",
                                                            }}
                                                        >
                                                            {product.eski_fiyat}₺
                                                        </Card.Text>
                                                    )}

                                                    <Card.Text
                                                        style={{
                                                            fontSize: "18px",
                                                            fontWeight: "500",
                                                            color: "rgb(32,29,29)",
                                                        }}
                                                    >
                                                        {product.fiyat}₺
                                                    </Card.Text>
                                                </div>

                                                <Button
                                                    className="sepete-ekle-button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onAddToCart(product, 1);
                                                    }}
                                                >
                                                    Sepete Ekle
                                                </Button>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <div className="text-center my-5">
                                <h4>Ürün bulunamadı</h4>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default UrunlerList;
