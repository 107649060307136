import React from "react";
import { Container } from "react-bootstrap";

const Banner = ({ title }) => {
    return (
        <div
            style={{
                background: "linear-gradient(90deg, #F5F5F5, #F5F5F5)", // Gradient renk
                color: "#00000",
                padding: "40px 0",
                textAlign: "center",
                borderBottom: "1px solid #dee2e6",
                boxShadow: "0px 2px 21px rgba(0, 0, 0, 0.1)",
            }}
        >
            <Container>
                <h2 style={{ fontSize: "2.5rem", fontWeight: "bold", marginBottom: "10px" }}>
                    {title}
                </h2>
                <p style={{ fontSize: "1.2rem", margin: "0" }}>
                    Hızlı ve Güvenilir Hizmetler İçin Bizimle İletişime Geçin
                </p>
            </Container>
        </div>
    );
};

export default Banner;
